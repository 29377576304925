import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(private _snackBar: MatSnackBar) { }

   openSnackBar(message: string, status: string) {
    let config = new MatSnackBarConfig();
    config.panelClass = status+'-bar';
    config.duration = 3000;
    config.horizontalPosition = 'end';
    config.verticalPosition = 'top'
    this._snackBar.open(message , '', config);
  }

}
