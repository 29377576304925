/* eslint-disable dot-notation */
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CommunicationService } from '@atmeservices/communication.service';
import { SkillService } from '@atmeservices/skill.service';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from 'src/app/helpers/snackbar.service';


const resizeWidth = 425;
const columnNumber1 = 1;
const columnNumber2 = 2;

@Component({
  selector: 'app-skill-management-dialoge',
  templateUrl: './skill-management-dialoge.component.html',
  styleUrls: ['./skill-management-dialoge.component.scss']
})
export class SkillManagementDialogeComponent implements OnInit {

  breakpoint: number;

  regForm: FormGroup;

  SkillId: string;

  isEdit = false;


  constructor(
    public dialogRef: MatDialogRef<SkillManagementDialogeComponent>,
    @Inject(MAT_DIALOG_DATA) public skillData: any,
    private route: ActivatedRoute,
    private readonly router: Router,
    private readonly skillService: SkillService,
    private translate: TranslateService,
    private communicationService: CommunicationService,
    private formBuilder: FormBuilder,
    private _snackBar: SnackbarService


  ) {

    if (skillData) {


      this.SkillId = skillData;
      this.fetchSkillForEdit(skillData);

    }

  }

  ngOnInit(): void {

    this.breakpoint = innerWidth <= resizeWidth ? columnNumber1 : columnNumber2;

    this.createForm();

    this.communicationService.getLanguage().subscribe((value) => {
      this.onLanguageChange(value)
    })
  }

  onLanguageChange(lang: string) {
    this.langC = lang;
    this.translate.use(lang);
  }

  langC: string = "";
  createTitle: string = "skillDialoge.createTitle";
  updateTitle: string = "skillDialoge.updateTitle";
  labe1: string = "skillDialoge.labe1";
  labe1PlaceHolder: string = "skillDialoge.labe1PlaceHolder";
  labe1Error: string = "skillDialoge.labe1Error";
  cancelBtn: string = "cancelBtnTitle";
  changeBtn: string = "imageUploadBtn";
  saveBtn: string = "saveBtnTitle";
  updateBtn: string = "updateBtnTitle";
  createSucessMsg: string = "skillDialoge.createSucessMsg";
  updateSucessMsg: string = "skillDialoge.updateSucessMsg";
  errorMsg1: string = "skillDialoge.errorMsg1";
  errorMsg2: string = "skillDialoge.errorMsg2";

  // Window resize for login divisions
  onResize(event: { target: { innerWidth: number; }; }): void {

    this.breakpoint = event.target.innerWidth <= resizeWidth ? columnNumber1 : columnNumber2;

  }

  createForm(): void {

    this.regForm = this.formBuilder.group({
      name: ['', [Validators.required]],
    });

  }

  createSkill(): void {

    if (this.regForm.valid) {

      this.skillService.createSkill(this.regForm.value).subscribe((message) => {

        if (message.message === 'success') {

          this._snackBar.openSnackBar(this.translate.instant(this.createSucessMsg), 'success');
          this.dialogRef.close(true);

        } else if (message['message'] === 'exist') {

          this._snackBar.openSnackBar(this.translate.instant(this.errorMsg1), 'danger');

        } else {

          this._snackBar.openSnackBar(this.translate.instant(this.errorMsg2), 'danger');

        }

      });

    }

  }

  fetchSkillForEdit(skillDataId: string): void {

    const tileData = { SkillId: skillDataId };
    this.skillService.fetchSkillById(tileData).subscribe((message) => {

      message._id ? this.regForm.patchValue(message) : this.SkillId = null;
      this.isEdit = true;

    });

  }

  updateSkill(): void {

    this.skillService.updateSkill(this.regForm.value, this.SkillId).subscribe((message) => {

      if (message.message === 'success') {

        this._snackBar.openSnackBar(this.translate.instant(this.updateSucessMsg), 'success');
        this.dialogRef.close(true);

      } else if (message['message'] === 'exist') {

        this._snackBar.openSnackBar(this.translate.instant(this.errorMsg1), 'danger');

      } else {

        this._snackBar.openSnackBar(this.translate.instant(this.errorMsg2), 'danger');

      }

    });

  }

}
