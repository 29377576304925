import autoTable from 'jspdf-autotable';
import jsPDF from 'jspdf';
import { saveAs } from "file-saver";

import {
    AlignmentType, Document, Packer, Paragraph, TextRun, Table, TableRow, TableCell, WidthType, ImageRun,
    HorizontalPositionRelativeFrom, VerticalPositionRelativeFrom, HorizontalPositionAlign, VerticalAlign,
    BorderStyle, ShadingType, convertInchesToTwip, LineRuleType
} from 'docx';

export class exportJdAs {


    async exportAsPDF(jdData, favicon, revenueResponsibility, budgetResponsibility, skillData) {

        const doc = new jsPDF('p', 'mm', 'a4')
        var img = new Image()
        img.src = '../../../assets/images/p-101-logo.jpeg'
        doc.addImage(img, 'png', 10, 10, 26, 14)
        var img = new Image()
        img.src = favicon ? favicon : '../../../assets/images/team-logo.jpg'
        doc.addImage(img, 'png', 187, 10, 12, 12)

        doc.setFontSize(9);

        let titleReporting = [['JOB TITLE ', [`${jdData.jobTitle}`]], [['REPORTING TO '], [`${jdData.reportingTo}`]]]

        titleReporting.forEach((title, index) => {

            autoTable(doc, {

                margin: { left: 10, right: 10 },
                styles: { lineWidth: 0.5 },
                columnStyles: {
                    0: { fillColor: [70, 155, 235], textColor: 255, halign: 'left', fontStyle: 'bold', fontSize: 9.4, cellWidth: 70 },
                    1: { cellWidth: 115 }
                },
                body: [title],
                bodyStyles: { fontSize: 9, },
                startY: index === 0 ? 30 : (doc as any).lastAutoTable.finalY,
                theme: 'plain',
                showHead: 'firstPage',
                pageBreak: 'auto'
            });

        })


        let startOfY = (doc as any).lastAutoTable.finalY + 7

        /* Purpose of the Role */

        const purposeTitle = [['Purpose of the Role']]
        const purposeData = [[jdData.purpose_role]]

        autoTable(doc, {

            margin: { left: 10 },
            headStyles: {
                lineWidth: 0, halign: 'justify', textColor: [255, 255, 255], fontStyle: 'bold', cellWidth: 185, fillColor: [70, 155, 235],
                // cellPadding: { left: 5 }
            },
            head: purposeTitle,
            body: purposeData,
            bodyStyles: { lineWidth: 0, halign: 'justify', fillColor: null, cellPadding: { right: 3, top: 4 } },
            startY: startOfY,
            theme: 'grid',
            showHead: 'firstPage',
            pageBreak: 'auto',
        });

        /* Functional Context */

        const functionalContextTitle = [['Functional Context']]
        const functionalContextData = [[jdData.functional_content]]

        autoTable(doc, {

            margin: { left: 10, right: 10 },
            headStyles: {
                lineWidth: 0, halign: 'justify', lineColor: 50, cellWidth: 185, textColor: [255, 255, 255], fontStyle: 'bold', fillColor: [70, 155, 235],
                // cellPadding: { left: 5 }
            },
            head: functionalContextTitle,
            body: functionalContextData,
            bodyStyles: { lineWidth: 0, halign: 'justify', fillColor: null, cellPadding: { right: 3, top: 4 } },
            startY: (doc as any).lastAutoTable.finalY + 7,
            theme: 'grid',
            showHead: 'firstPage',
            pageBreak: 'auto'
        });

        /* Roles And Responsibilities */

        const rolesTitle = [['Roles And Responsibilities']]
        const rolesData = []
        jdData.roles_responsibilities.forEach((element, index) => {
            rolesData.push([element])
        })

        autoTable(doc, {

            margin: { left: 10, right: 10 },
            headStyles: {
                lineWidth: 0, halign: 'justify', lineColor: 50, cellWidth: 185, textColor: [255, 255, 255], fontStyle: 'bold', fillColor: [70, 155, 235],
                // cellPadding: { left: 5 }
            },
            head: rolesTitle,
            body: rolesData,
            bodyStyles: { lineWidth: 0, halign: 'justify', fillColor: null, cellPadding: { right: 3, top: 4 } },
            startY: (doc as any).lastAutoTable.finalY + 7,
            theme: 'grid',
            showHead: 'firstPage',
            pageBreak: 'auto',
            didParseCell(data) {
                if (data.cell.section === "body") {
                    data.cell.text[0] = '• ' + data.cell.text[0];
                }
            },
        });

        /* Key Performance Indicators */

        const keyPerformanceTitle = [['Key Performance Indicators']]
        const keyPerformanceData = []
        jdData.key_performance.forEach((element, index) => {
            keyPerformanceData.push([element])
        })

        autoTable(doc, {

            margin: { left: 10, right: 10 },
            headStyles: {
                lineWidth: 0, halign: 'justify', lineColor: 50, cellWidth: 185, textColor: [255, 255, 255], fontStyle: 'bold', fillColor: [70, 155, 235],
                // cellPadding: { left: 5 }
            },
            head: keyPerformanceTitle,
            body: keyPerformanceData,
            bodyStyles: { lineWidth: 0, cellWidth: 185, halign: 'justify', fillColor: null, cellPadding: { top: 4 } },
            startY: (doc as any).lastAutoTable.finalY + 7,
            theme: 'grid',
            showHead: 'firstPage',
            pageBreak: 'auto',
            didParseCell(data) {
                if (data.cell.section === "body") {
                    data.cell.text[0] = '• ' + data.cell.text[0];
                }
            },

        });

        /* Minimum Experience , Minimum Qualification */

        let titleExperience = [
            [[`Minimum Experience `], [`${jdData.experienceRange.minimumExperience} Years`]],
            [[`Minimum Qualification  `], [`${jdData.minimumQualification}`]]
        ]

        if (revenueResponsibility) {
            titleExperience.push([['Revenue Responsibility [USD] :'], [`${jdData.revenueResponsibility}`]])
        }

        if (budgetResponsibility) {
            titleExperience.push([['Budget Responsibility [USD] :'], [jdData.budgetResponsibility]])
        }

        titleExperience.push([['Required Skill'], [skillData]])

        titleExperience.forEach((data, index) => {
            // doc.setFontSize(11);
            // fillColor: [41, 128, 185]
            autoTable(doc, {

                margin: { left: 10, right: 10 },
                styles: { lineWidth: 0.5 },
                columnStyles: {
                    0: { fillColor: [70, 155, 235], textColor: 255, halign: 'left', fontStyle: 'bold', fontSize: 9.4, cellWidth: 70 },
                    1: { cellWidth: 115 }
                },
                body: [data],
                bodyStyles: { fontSize: 9, },
                startY: index === 0 ? (doc as any).lastAutoTable.finalY + 7 : (doc as any).lastAutoTable.finalY,
                theme: 'plain',
                showHead: 'firstPage',
                pageBreak: 'auto'
            });

        })

        const additionalFieldsTitle = []
        const additionalFieldsData = []
        await jdData.additionalFields?.forEach(field => {
            additionalFieldsTitle.push([field?.heading])
            additionalFieldsData.push([field?.value])
        })

        /* Additional fields */
        const pageHeight = doc.internal.pageSize.getHeight();


        additionalFieldsTitle.forEach((title, index) => {
            autoTable(doc, {

                margin: { left: 10, right: 10 },
                headStyles: {
                    lineWidth: 0, halign: 'justify', lineColor: 50, cellWidth: 185, textColor: [255, 255, 255], fontStyle: 'bold', fillColor: [70, 155, 235],
                    // cellPadding: { left: 5 }
                },
                head: [title],
                body: [additionalFieldsData[index]],
                bodyStyles: { lineWidth: 0, halign: 'justify', fillColor: null, cellPadding: { right: 3, top: 4 } },
                startY: (doc as any).lastAutoTable.finalY + 7,
                theme: 'grid',
                showHead: 'firstPage',
                pageBreak: 'auto'
            });
        })
        const pdfName = jdData.code ? `${jdData.code}.pdf` : 'Job Description.pdf'
        doc.save(`${pdfName}`);
    }


    exportAsDocx(jdData, jdLogo, organisationLogo, favicon, revenueResponsibility, budgetResponsibility, skillData) {

        const base64dataJD: any = jdLogo;
        const base64dataOrganisation: any = organisationLogo;

        let borderStyle = {
            top: { style: BorderStyle.NIL }, bottom: { style: BorderStyle.NIL }, left: { style: BorderStyle.NIL },
            right: { style: BorderStyle.NIL },
        }
        let cellMargin = {
            top: convertInchesToTwip(0.15),
            bottom: convertInchesToTwip(0.15),
        }

        let reportRoleArray = [];

        jdData.roles_responsibilities.forEach((element, index) => {
            reportRoleArray.push(element)
        })
        let keyParameterArray = [];

        jdData.key_performance.forEach((element, index) => {
            keyParameterArray.push(element)
        })

        let reportBodyArray = [];

        reportBodyArray.push(
            new Paragraph({
                children: [
                    new ImageRun({
                        data: base64dataJD,
                        transformation: {
                            width: 110,
                            height: 50
                        },
                        floating: {
                            horizontalPosition: {
                                relative: HorizontalPositionRelativeFrom.MARGIN,
                                align: HorizontalPositionAlign.LEFT,
                            },
                            verticalPosition: {
                                relative: VerticalPositionRelativeFrom.TOP_MARGIN,
                                offset: 414400,
                            },
                        },

                    }),
                    new ImageRun({
                        data: favicon ? favicon : base64dataOrganisation,
                        transformation: {
                            width: 60,
                            height: 40
                        },
                        floating: {
                            horizontalPosition: {
                                relative: HorizontalPositionRelativeFrom.MARGIN,
                                align: HorizontalPositionAlign.RIGHT,
                            },
                            verticalPosition: {
                                relative: VerticalPositionRelativeFrom.TOP_MARGIN,
                                offset: 414400,
                            },
                        },

                    }),
                ],
            }),
        )






        let titleName = [`Job Title`, `Reporting To`]
        let titleData = [`${jdData.jobTitle}`, `${jdData.reportingTo}`]


        const tempTitleArray = [];

        titleName.forEach((key, index) => {

            tempTitleArray.push(new TableRow({
                children: [

                    new TableCell({
                        width: {
                            size: 40,
                            type: WidthType.PERCENTAGE,
                        },
                        margins: {
                            marginUnitType: WidthType.DXA,
                            top: 80,
                            bottom: 80,
                            left: 70,
                        },
                        shading: {
                            color: '#459ceb',
                            // fill: "D9E4EC",
                            type: ShadingType.SOLID
                        },
                        verticalAlign: VerticalAlign.CENTER,
                        children: [new Paragraph({
                            children: [new TextRun({
                                text: `${key}`,
                                color: '#FFFFFF',
                                bold: true,
                                size: 22,
                                characterSpacing: 4,
                                font: 'Times New Roman'
                            })],
                        })
                        ],

                    }),
                    new TableCell({
                        children: [new Paragraph({
                            children: [new TextRun({
                                text: `${titleData[index]}`,
                                size: 22,
                                characterSpacing: 4,
                                font: 'Times New Roman'

                            })],

                        })
                        ],
                        verticalAlign: VerticalAlign.CENTER,
                        margins: {
                            marginUnitType: WidthType.DXA,
                            top: 80,
                            bottom: 80,
                            left: 70,
                        },
                        width: {
                            size: 60,
                            type: WidthType.PERCENTAGE,
                        }
                    }),
                ],
            }))

        })



        reportBodyArray.push(new Table({
            width: {
                size: 100,
                type: WidthType.PERCENTAGE,
            },
            rows: tempTitleArray,

            borders: {
                top: {
                    style: BorderStyle.THREE_D_ENGRAVE,
                    size: 4,
                    color: '#c7c7c7',
                },
                bottom: {
                    style: BorderStyle.THREE_D_ENGRAVE,
                    size: 4,
                    color: '#c7c7c7',
                },
                left: {
                    style: BorderStyle.THREE_D_ENGRAVE,
                    size: 4,
                    color: '#c7c7c7',
                },
                right: {
                    style: BorderStyle.THREE_D_ENGRAVE,
                    size: 4,
                    color: '#c7c7c7',
                },
                insideHorizontal: {
                    style: BorderStyle.THREE_D_ENGRAVE,
                    size: 4,
                    color: '#c7c7c7',
                },
                insideVertical: {
                    style: BorderStyle.THREE_D_ENGRAVE,
                    size: 4,
                    color: '#c7c7c7',
                },
            }

        }))

        reportBodyArray.push(
            new Paragraph({
                children: [
                    new TextRun({
                        text: " Purpose of the Role",
                        color: '#ffffff',
                        size: 25,
                        bold: true,
                        font: 'Times New Roman'
                    })
                ],
                shading: {
                    color: '#459ceb',
                    type: ShadingType.SOLID
                },
                border: {
                    top: { style: BorderStyle.NONE, space: 4, size: 2 },
                    bottom: { style: BorderStyle.NONE, space: 4, size: 2 },
                },
                spacing: {
                    before: 400,
                    lineRule: LineRuleType.AUTO,
                },

            }),
            new Paragraph({
                children: [
                    new TextRun({
                        text: `${jdData.purpose_role}`,
                        color: '#000000',
                        size: 22,
                        font: 'Times New Roman',
                        bold: false,
                    })
                ],
                indent: {
                    left: 10
                },
                alignment: AlignmentType.JUSTIFIED,
                spacing: {
                    before: 200,
                    lineRule: LineRuleType.AUTO,
                },

            }),
            new Paragraph({
                children: [
                    new TextRun({
                        text: " Functional Context",
                        color: '#ffffff',
                        size: 25,
                        bold: true,
                        font: 'Times New Roman'
                    })
                ],
                shading: {
                    color: '#459ceb',
                    type: ShadingType.SOLID
                },
                border: {
                    top: { style: BorderStyle.NONE, space: 4, size: 2 },
                    bottom: { style: BorderStyle.NONE, space: 4, size: 2 },
                },
                spacing: {
                    before: 400,
                    lineRule: LineRuleType.AUTO,
                },

            }),
            new Paragraph({
                children: [
                    new TextRun({
                        text: `${jdData.functional_content}`,
                        color: '#000000',
                        size: 22,
                        bold: false,
                        font: 'Times New Roman'
                    })
                ],
                alignment: AlignmentType.JUSTIFIED,
                spacing: {
                    before: 200,
                    lineRule: LineRuleType.AUTO,
                },

            }),
        )

        reportBodyArray.push(
            new Paragraph({
                children: [
                    new TextRun({
                        text: " Roles And Responsibilities",
                        color: '#ffffff',
                        size: 25,
                        bold: true,
                        font: 'Times New Roman'
                    })
                ],
                shading: {
                    color: '#459ceb',
                    type: ShadingType.SOLID
                },
                border: {
                    top: { style: BorderStyle.NONE, space: 4, size: 2 },
                    bottom: { style: BorderStyle.NONE, space: 4, size: 2 },
                },
                spacing: {
                    before: 400,
                    lineRule: LineRuleType.AUTO,
                },

            }),
        )

        for (let role of reportRoleArray) {
            reportBodyArray.push(
                new Paragraph({
                    children: [
                        new TextRun({
                            text: `${role}`,
                            color: '#000000',
                            size: 22,
                            bold: false,
                            font: 'Times New Roman'
                        })
                    ],
                    alignment: AlignmentType.JUSTIFIED,
                    bullet: { level: 0 },
                    spacing: {
                        before: 200,
                        lineRule: LineRuleType.AUTO,
                    },

                }),
            )
        }

        reportBodyArray.push(
            new Paragraph({
                children: [
                    new TextRun({
                        text: " Key Performance Indicators",
                        color: '#ffffff',
                        size: 25,
                        bold: true,
                        font: 'Times New Roman'
                    })
                ],
                shading: {
                    color: '#459ceb',
                    type: ShadingType.SOLID
                },
                border: {
                    top: { style: BorderStyle.NONE, space: 4, size: 2 },
                    bottom: { style: BorderStyle.NONE, space: 4, size: 2 },
                },
                spacing: {
                    before: 400,
                    lineRule: LineRuleType.AUTO,
                },

            }),
        )

        for (let key of keyParameterArray) {

            reportBodyArray.push(
                new Paragraph({
                    children: [
                        new TextRun({
                            text: `${key}`,
                            color: '#000000',
                            size: 22,
                            bold: false,
                            font: 'Times New Roman'
                        })
                    ],
                    alignment: AlignmentType.JUSTIFIED,
                    bullet: { level: 0 },
                    spacing: {
                        before: 200,
                        lineRule: LineRuleType.AUTO,
                    },

                }),
            )

        }

        reportBodyArray.push(
            new Paragraph({
                children: [new TextRun({
                    text: ``,
                    color: '#FFFFFF',
                    size: 22,
                    characterSpacing: 4,
                    font: 'Times New Roman'
                })],
                spacing: {
                    before: 200,
                    after: 200
                },
            }),
        )

        let tempRowArray = [];

        let titleExperience = [`Minimum Experience `, `Minimum Qualification  `]
        let titleExperienceData = [`${jdData.experienceRange.minimumExperience} Years`, `${jdData.minimumQualification}`]

        if (revenueResponsibility) {
            titleExperience.push('Revenue Responsibility [USD] ')
            titleExperienceData.push(`${jdData.revenueResponsibility}`)
        }

        if (budgetResponsibility) {
            titleExperience.push('Budget Responsibility [USD] ')
            titleExperienceData.push(jdData.budgetResponsibility)
        }

        titleExperience.push('Required Skill')
        titleExperienceData.push(skillData)


        titleExperience.forEach((key, index) => {

            tempRowArray.push(new TableRow({
                children: [

                    new TableCell({
                        width: {
                            size: 40,
                            type: WidthType.PERCENTAGE,
                        },
                        margins: {
                            marginUnitType: WidthType.DXA,
                            top: 80,
                            bottom: 80,
                            left: 70,
                        },
                        shading: {
                            color: '#459ceb',
                            // fill: "D9E4EC",
                            type: ShadingType.SOLID
                        },
                        verticalAlign: VerticalAlign.CENTER,
                        children: [new Paragraph({
                            children: [new TextRun({
                                text: `${key}`,
                                color: '#FFFFFF',
                                bold: true,
                                size: 22,
                                characterSpacing: 4,
                                font: 'Times New Roman'
                            })],
                        })
                        ],

                    }),
                    new TableCell({
                        children: [new Paragraph({
                            children: [new TextRun({
                                text: `${titleExperienceData[index]}`,
                                size: 22,
                                characterSpacing: 4,
                                font: 'Times New Roman'

                            })],

                        })
                        ],
                        verticalAlign: VerticalAlign.CENTER,
                        margins: {
                            marginUnitType: WidthType.DXA,
                            top: 80,
                            bottom: 80,
                            left: 70,
                        },
                        width: {
                            size: 60,
                            type: WidthType.PERCENTAGE,
                        }
                    }),
                ],
            }))

        })


        const tableSummary =
            new Table({
                width: {
                    size: 100,
                    type: WidthType.PERCENTAGE,
                },
                rows: tempRowArray,

                borders: {
                    top: {
                        style: BorderStyle.THREE_D_ENGRAVE,
                        size: 4,
                        color: '#c7c7c7',
                    },
                    bottom: {
                        style: BorderStyle.THREE_D_ENGRAVE,
                        size: 4,
                        color: '#c7c7c7',
                    },
                    left: {
                        style: BorderStyle.THREE_D_ENGRAVE,
                        size: 4,
                        color: '#c7c7c7',
                    },
                    right: {
                        style: BorderStyle.THREE_D_ENGRAVE,
                        size: 4,
                        color: '#c7c7c7',
                    },
                    insideHorizontal: {
                        style: BorderStyle.THREE_D_ENGRAVE,
                        size: 4,
                        color: '#c7c7c7',
                    },
                    insideVertical: {
                        style: BorderStyle.THREE_D_ENGRAVE,
                        size: 4,
                        color: '#c7c7c7',
                    },
                }

            })

        reportBodyArray.push(tableSummary)

        const additionalFieldsTitle = []
        const additionalFieldsData = []

        jdData.additionalFields?.forEach(field => {
            additionalFieldsTitle.push(field?.heading)
            additionalFieldsData.push(field?.value)
        })

        additionalFieldsTitle.forEach((field, index) => {

            reportBodyArray.push(
                new Paragraph({
                    children: [
                        new TextRun({
                            text: `${field}`,
                            color: '#ffffff',
                            size: 25,
                            bold: true,
                            font: 'Times New Roman'
                        })
                    ],
                    shading: {
                        color: '#459ceb',
                        type: ShadingType.SOLID
                    },
                    border: {
                        top: { style: BorderStyle.NONE, space: 4, size: 2 },
                        bottom: { style: BorderStyle.NONE, space: 4, size: 2 },
                    },
                    spacing: {
                        before: 400,
                        lineRule: LineRuleType.AUTO,
                    },

                }),
                new Paragraph({
                    children: [
                        new TextRun({
                            text: `${additionalFieldsData[index]}`,
                            color: '#000000',
                            size: 22,
                            bold: false,
                            font: 'Times New Roman'
                        })
                    ],
                    indent: {
                        left: 10
                    },
                    alignment: AlignmentType.JUSTIFIED,
                    spacing: {
                        before: 200,
                        lineRule: LineRuleType.AUTO,
                    },

                }))

        })


        const doc = new Document({
            sections: [{
                properties: {},
                children: reportBodyArray
            }],
        });
        const pdfName = jdData.code ? `${jdData.code}.docx` : 'Job Description.docx'
        // Used to export the file into a .docx file
        Packer.toBlob(doc).then(blob => {
            saveAs(blob, `${pdfName}`);
            // console.log('Document created successfully');
        });

    }

}
export const exportJobDiscriptionAs = new exportJdAs();
