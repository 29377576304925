<div [class.overlay]="loading" [class.spinner]="loading" (click)="$event.stopPropagation();"></div>
<!-- <div class="header-container">
    <h2 class="header">
        {{title | translate}}
    </h2>
    
</div> -->
<div class="main-wrap">

    <div class="table-wrapper">
        <div class="sub-header-div">
            <form class="search-form">
                <mat-form-field class="search-full-width" appearance="none">
                    <mat-icon matSuffix>search</mat-icon>
                    <div class="search-box">
                        <input type="text" matInput placeholder="{{'Search' | translate}}" (keyup)="applyFilter($event)" #input>
                    </div>
                </mat-form-field>
            </form>

        
            <button (click)="createJD()" mat-flat-button color="primary" class="add-btn2"><img src="../../../assets/images/add-icon.png"><span class="add-jd">Add JD</span></button>
        
        </div>

        <mat-table [dataSource]="dataSource" >

            <!-- Position Column -->
            <ng-container matColumnDef="index">
                <th class="table-index" mat-header-cell *matHeaderCellDef>{{table1 | translate}} </th>
                <td class="table-index" mat-cell *matCellDef="let element let i = index;"> {{i+1}} </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th class="table-name" mat-header-cell *matHeaderCellDef> {{table2 | translate}} </th>
                <td class="table-name" mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="code">
                <th class="table-name" mat-header-cell *matHeaderCellDef> {{table3 | translate}} </th>
                <td class="table-name" mat-cell *matCellDef="let element"> {{element?.code}} </td>
            </ng-container>
            <ng-container matColumnDef="flow">
                <th class="table-name" mat-header-cell *matHeaderCellDef> {{table4 | translate}}</th>
                <td class="table-name" mat-cell *matCellDef="let element">
                    <div class="workFlow-wrapper">
                        {{element?.workFlowName}}
                        <mat-icon [matMenuTriggerFor]="workFlowInfo" (mouseover)="workFlowInfoTrigger.openMenu()"
                            #workFlowInfoTrigger="matMenuTrigger" *ngIf="element?.workFlowName">info</mat-icon>
                    </div>
                    <mat-menu #workFlowInfo="matMenu">
                        <ng-template matMenuContent>
                            <div style="padding: 15px 10px 0px 11px;">
                                <div>
                                    <p>{{table4FlowDetail1 | translate}}</p>
                                </div>
                                <div *ngFor="let item of element?.ParticipantData ">

                                    <div *ngIf="item.type === 'reviewer'">
                                        <p style="padding-left: 12%;"> {{ returnUserName(item,element?.userData)||'' }}
                                        </p>
                                    </div>

                                </div>
                                <div>
                                    <p>{{table4FlowDetail2 | translate}}</p>
                                </div>
                                <div *ngFor="let item of element?.ParticipantData">

                                    <div *ngIf="item.type === 'approver'" class="workFlowApprover">
                                        <p>{{ returnUserName(item,element?.userData) || '' }}</p>
                                        <p>{{ returnUserLevel(item,element?.userData) || ''}}</p>
                                    </div>

                                </div>
                            </div>
                        </ng-template>
                    </mat-menu>
                </td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="position">
                <th class="table-position" mat-header-cell *matHeaderCellDef> {{table5 | translate}} </th>
                <td class="table-position" mat-cell *matCellDef="let element">
                    <div>{{element.jobTitle}}</div>
                </td>
            </ng-container>
            <ng-container matColumnDef="action" >
                <th class="table-action" mat-header-cell *matHeaderCellDef class="action"> {{table6 | translate}} </th>

                <td class="table-action" mat-cell *matCellDef="let element">
                    <button mat-icon-button *ngIf="!element?.currentApprover" (click)="viewJd(element)"
                        matTooltip="{{viewToolTip | translate}}">
                        <mat-icon class="mat-action-icon">visibility</mat-icon>
                    </button>

                    <button mat-icon-button *ngIf="element?.currentApprover" (click)="verifyJd(element)"
                        matTooltip="{{table6ApproveTooltip | translate}}">
                        <mat-icon class="mat-action-icon">verified</mat-icon>
                    </button>

                    <button mat-icon-button (click)="editJd(element)" matTooltip="{{editToolTip | translate}}">
                        <mat-icon class="mat-action-icon">edit</mat-icon>
                    </button>

                    <button [disabled]="!element?.workFlowApproved" (click)="shareJd(element?.uniqueCode)"
                        matTooltip="{{table6ShareTooltip | translate}}" mat-icon-button>
                        <mat-icon [style.color]="!element?.workFlowApproved?'#c3baba':'#9354f9'"
                            class="mat-action-icon">
                            share</mat-icon>
                    </button>

                    <button mat-icon-button (click)="jdIdForWorkFlowManagement(element)" *ngIf="element?.isCreated"
                        matTooltip="{{table6WorkflowTooltip | translate}}">
                        <mat-icon class="mat-action-icon">assignment</mat-icon>
                    </button>

                    <button mat-icon-button (click)="deleteJd(element)" matTooltip="{{deleteToolTip | translate}}">
                        <mat-icon id="deletebtn" class="mat-action-icon">delete_outline</mat-icon>
                    </button>

                </td>
            </ng-container>

            <!-- (optionSelected)="workFlowOnSelect($event.option.value)" -->



            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell no-data-error" colspan="6">{{tableNullError | translate}} "{{input.value}}"</td>
            </tr>
        </mat-table>
        <mat-paginator style="margin-top: 20px;" [length]="100" [pageSize]="5" [pageSizeOptions]="[5, 10, 25, 100]">
        </mat-paginator>

        <!-- <mat-menu #memberMenu="matMenu">
            <div style="padding: 15px 10px 0px 11px;">
                <div>
                    <p>Reviewers</p>
                </div>
                <div *ngFor="let item of workFlowViewData?.participateData, i as index">

                    <div *ngIf="item.type === 'reviewer'">
                        <p style="padding-left: 12%;"> {{
                            returnUserName(item,workFlowViewData?.userData)||''}}
                        </p>
                    </div>

                </div>
                <div>
                    <p>Approvers</p>
                </div>
                <div *ngFor="let item of workFlowViewData?.participateData">

                    <div *ngIf="item.type === 'approver'" class="workFlowApprover">
                        <p>{{ returnUserName(item,workFlowViewData?.userData) || '' }}</p>
                        <p>{{ returnUserLevel(item,workFlowViewData?.userData) || ''}}</p>
                    </div>

                </div>
            </div>
        </mat-menu> -->

    </div>


    <!-- https://icons.iconarchive.com/icons/papirus-team/papirus-mimetypes/512/x-office-document-icon.png -->

</div>