<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content class="dialog-content-div">
  {{data.message}}
</div>
<div class="dialog-action-div">
  <div>
    <button mat-button class="action-confirm" (click)="confirm()">{{confirmBtn | translate}}</button>
  </div>
  <div>
    <button mat-button class="action-cancel" (click)="cancel()">{{cancelBtnTitle | translate}}</button>
  </div>

</div>