/* eslint-disable dot-notation */
/* eslint-disable id-blacklist */
/* eslint-disable no-extra-parens */
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SnackbarService } from 'src/app/helpers/snackbar.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { JobTitleService } from '../../services/job-title.service';
import { JobTitleManagementDialogeComponent } from './job-title-management-dialoge/job-title-management-dialoge.component';
import { CustomDialogComponent } from '../../components/custom-dialog/custom-dialog.component';
import { DialogBoxComponent } from '../../components/dialog-box/dialog-box.component';
import { JobTitleS } from '@atme/entity/jobTitle';
import { TranslateService } from '@ngx-translate/core';
import { CommunicationService } from '@atmeservices/communication.service';

@Component({
  selector: 'app-job-title-management',
  templateUrl: './job-title-management.component.html',
  styleUrls: ['./job-title-management.component.scss']
})
export class JobTitleManagementComponent implements OnInit {

  displayedColumns: string[] = ['name', 'action'];

  dataSource = new MatTableDataSource<JobTitleS>();

  // successMessage = 'Job Title deleted successfully...';

  // errorMessage = 'Error!';

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;


  @ViewChild(MatSort) sort!: MatSort;

  loading = false;

  jobTitleData = [];

  constructor(
    public dialog: MatDialog,
    private _snackBar: SnackbarService,
    private readonly jobTitleService: JobTitleService,
    private translate: TranslateService,
    private communicationService: CommunicationService,
    private router: Router) { }

  ngOnInit(): void {

    this.fetchJobTitleList();
    this.communicationService.getLanguage().subscribe((value) => {
      this.onLanguageChange(value)
    })

  }
  onLanguageChange(lang: string) {
    this.langC = lang;
    this.translate.use(lang);
  }

  langC: string = "";
  title: string = "jobTitle.title";
  addBtn: string = "jobTitle.addBtn";
  addBtnToolTip: string = "addBtnToolTip";
  search: string = "tableSearchPlaceHolder";
  label1: string = "jobTitle.labe1";
  label2: string = "jobTitle.labe2";
  tableNullData: string = "nullData-table-error";
  viewToolTip: string = "viewToolTip";
  editToolTip: string = "editToolTip";
  deleteToolTip: string = "deleteToolTip";
  deleteDialogTitle: string = "jobTitle.deleteTitle";
  deleteDialogMsg1: string = "jobTitle.deleteMsg1";
  deleteDialogMsg2: string = "jobTitle.deleteMsg2";
  deleteSuccess: string = "jobTitle.successMsg";
  deleteError: string = "jobTitle.errorMsg";
  paginatorLabel: string = "paginatorLabel";

  successMessage = this.translate.instant(this.deleteSuccess);

  errorMessage = this.translate.instant(this.deleteError);

  // Search Job Title
  applyFilter(event: Event): void {

    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {

      this.dataSource.paginator.firstPage();

    }

  }

  // Fetch all Job Title records
  fetchJobTitleList(): void {

    this.loading = true;

    this.jobTitleService.fetchJobTitleList().subscribe((message) => {

      this.loading = false;
      this.jobTitleData = message;

      this.dataSource = new MatTableDataSource(this.jobTitleData);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

      // Update data in data source when available

      // This.streamOfDataUpdates.subscribe(newData => this.myDataSource.data = newData);

    }, (error) => {

      this.loading = false;
      console.error(error);

    });

  }

  // View Job Title
  viewJobTitle(Titledata: { name: string; image: string; }): void {

    const jobTitleData = {
      'Job Title Name': Titledata?.name,
    };
    const jobTitleDataObj = {
      'data': jobTitleData,
      'title': 'Job Title Details',
      'image': Titledata?.image,
    };
    // eslint-disable-next-line id-blacklist
    this.dialog.open(CustomDialogComponent, { data: jobTitleDataObj });

  }

  // Edit Job Title
  editJobTitle(titleId: string): void {

    const dialogRef = this.dialog.open(JobTitleManagementDialogeComponent, { data: titleId });
    dialogRef.afterClosed().subscribe((result) => {

      if (result) {

        this.fetchJobTitleList();

      }

    });

  }

  // Delete Job Title
  deleteJobTitle(titleData: { name: string; }): void {

    const confirmDialog = this.dialog.open(DialogBoxComponent, {
      data: {
        title: this.translate.instant(this.deleteDialogTitle),
        message: `${this.translate.instant(this.deleteDialogMsg1)} "${titleData?.name}" ${this.translate.instant(this.deleteDialogMsg2)}`
      }
    });
    confirmDialog.afterClosed().subscribe((result) => {

      if (result === true) {

        this.jobTitleService.deleteJobTitleById(titleData).subscribe((message) => {

          if (message['message'] === 'success') {

            this._snackBar.openSnackBar(this.successMessage, 'success');
            this.fetchJobTitleList();

          } else {

            this._snackBar.openSnackBar(this.errorMessage, 'danger');

          }
          this.loading = false;

        }, (error) => {

          console.error(error);
          this._snackBar.openSnackBar(this.errorMessage, 'danger');
          this.loading = false;

        });


      }

    });

  }

  createJobTitle(): void {

    const dialogRef = this.dialog.open(JobTitleManagementDialogeComponent);
    // Create subscription
    dialogRef.afterClosed().subscribe((result) => {

      if (result) {

        this.fetchJobTitleList();

      }

    });

  }

}
