/* eslint-disable dot-notation */
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { BASE_URI, JOB_DESCRIPTION_API, ORGANISATION_API, WORK_FLOW_API } from '@atme/serverAPI';
import { WorkFlowS } from '@atme/entity/work-flow';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class WorkFlowService {

  constructor(
    private readonly http: HttpClient
  ) { }

  createWorkFlow(flow: WorkFlowS): Observable<any> {

    return this.http.post<WorkFlowS>(`${BASE_URI}${WORK_FLOW_API}`, flow).pipe(
      catchError((err) => throwError(err))
    );

  }

  updateWorkFlow(flowData: WorkFlowS): Observable<any> {

    return this.http.put<WorkFlowS>(`${BASE_URI}${WORK_FLOW_API}`, { params: flowData, }).pipe(
      catchError((err) => throwError(err))
    );

  }

  deleteFlowById(flowId: any): Observable<unknown> {

    return this.http.delete(`${BASE_URI}${WORK_FLOW_API}`, { params: flowId }).pipe(
      catchError((err) => throwError(err))
    );

  }

  fetchWorkflow(): Observable<any> {

    return this.http.get(`${BASE_URI}${WORK_FLOW_API}`).pipe(
      catchError((err) => throwError(err))
    );

  }
  fetchWorkflowNames(): Observable<any> {

    return this.http.get(`${BASE_URI}${WORK_FLOW_API}/names`).pipe(
      catchError((err) => throwError(err))
    );

  }

  fetchWorkFlowById(flowData: any): Observable<any> {

    return this.http.get(`${BASE_URI}${WORK_FLOW_API}/edit`, { params: flowData }).pipe(
      catchError((err) => throwError(err))
    );

  }
  updateWorkFlowStatusApprove(flowData: any): Observable<any> {

    return this.http.put(`${BASE_URI}${JOB_DESCRIPTION_API}/approve`, { params: flowData }).pipe(
      catchError((err) => throwError(err))
    );

  }
  updateWorkFlowStatusReject(flowData: any): Observable<any> {

    return this.http.put(`${BASE_URI}${JOB_DESCRIPTION_API}/reject`, { params: flowData }).pipe(
      catchError((err) => throwError(err))
    );

  }
  isLoginedUserIsAdmin(): Observable<any> {

    return this.http.get(`${BASE_URI}${ORGANISATION_API}/isAdmin`).pipe(
      catchError((err) => throwError(err))
    );

  }

}
