/* eslint-disable dot-notation */
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { BASE_URI, QUALIFICATION_API } from '@atme/serverAPI';
import { QualificationS } from '@atme/entity/qualification';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class QualificationService {

  constructor(
    private readonly http: HttpClient
  ) { }

  createQualification(qualification: QualificationS): Observable<any> {

    return this.http.post<QualificationS>(`${BASE_URI}${QUALIFICATION_API}`, qualification).pipe(
      catchError((err) => throwError(err))
    );

  }

  updateQualification(qualification: QualificationS, qualificationId: string): Observable<any> {

    return this.http.put<QualificationS>(`${BASE_URI}${QUALIFICATION_API}`, { params: qualification,
      qualificationId }).pipe(
      catchError((err) => throwError(err))
    );

  }

  deleteQualificationById(qualificationId: any): Observable<unknown> {

    return this.http.delete(`${BASE_URI}${QUALIFICATION_API}`, { params: qualificationId }).pipe(
      catchError((err) => throwError(err))
    );

  }

  fetchQualificationList(): Observable<any> {

    return this.http.get(`${BASE_URI}${QUALIFICATION_API}`).pipe(
      catchError((err) => throwError(err))
    );

  }

  fetchQualificationById(qualificationData: any): Observable<any> {

    return this.http.get(`${BASE_URI}${QUALIFICATION_API}/edit`, { params: qualificationData }).pipe(
      catchError((err) => throwError(err))
    );

  }

}
