import { Component, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { CommunicationService } from '@atmeservices/communication.service';
import { JobDescriptionService } from '@atmeservices/job-description.service';
import { OrganisationManagementService } from '@atmeservices/organisation-management.service';
import { UserService } from '@atmeservices/user.service';
import { WorkFlowService } from '@atmeservices/work-flow.service';
import { TranslateService } from '@ngx-translate/core';
import { DialogBoxComponent } from 'src/app/components/dialog-box/dialog-box.component';
import { InviteDialogeComponent } from 'src/app/components/invite-dialoge/invite-dialoge.component';
import { SnackbarService } from 'src/app/helpers/snackbar.service';
import { AccountService } from '../../services/account.service';
import { DashboardDialogueComponent } from './dashboard-dialogue/dashboard-dialogue.component';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})


export class DashboardComponent {

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  jdList: any = [];
  loading: boolean;
  flowDataArray: any = [];
  filteredArray: any = [];
  jdWorkFlowId
  jdName: any;
  regForm: FormGroup;
  loginUserId: string;
  currentApprover: Boolean;
  isOpen = false;
  workFlowViewData;

  constructor(
    private readonly router: Router,
    private readonly accountService: AccountService,
    private workFlowService: WorkFlowService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog,
    private translate: TranslateService,
    private communicationService: CommunicationService,
    private userService: UserService,
    private _snackBar: SnackbarService,
    private readonly organisationService: OrganisationManagementService,
    private jobDescriptionService: JobDescriptionService
  ) { this.fetchLoginuser(); }

  // eslint-disable-next-line class-methods-use-this

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  editProfile(): void {

    this.router.navigate(['/home/edit-profile']);

  }

  changePassword(): void {

    this.router.navigate(['/change-password']);

  }
  createJD() {

    this.router.navigate(['/home/create-jd']);


  }
  fetchJDList() {

    this.loading = true;
    this.jobDescriptionService.fetchListOfJobDescription().subscribe(data => {
      this.loading = false;
      this.jdList = data.Result;
      this.dataSource = new MatTableDataSource(this.jdList);
      this.dataSource.paginator = this.paginator;
      this.loading = false;
      // this.determinApprovalOftheJds()
      console.log(data);
      // this.currentApprover = data.Result.currentApprover
    })


  }

  fetchLoginuser() {

    this.userService.fetchLoginuser().subscribe((message) => {

      this.loginUserId = message._id;

    });

  }


  displayFn(user): string {
    return user && user.name ? user.name : '';
  }

  fetchWorkFlows() {

    this.workFlowService.fetchWorkflowNames().subscribe(flow => {
      console.log(flow, 'flow');

      this.flowDataArray = flow.Result;
      this.filteredArray = flow.Result;
    })
  }
  handleWorkFlowSelect = (event) => {

    const KEY_CODE_DOWN = 38;
    const KEY_CODE_UP = 40;
    if (!event || event.keyCode === KEY_CODE_DOWN || event.keyCode === KEY_CODE_UP) {

      return;

    }
    const itmQ = event.target.value;

    if (typeof itmQ !== 'string') {

      return;

    }

    this.filteredArray = this.flowDataArray.filter(option => option.name.toLowerCase().includes(itmQ));

  };

  logOut(): void {

    this.accountService.logout();
    // LocalStorage.clear();

    //   This.userSubject.next(null);

    //   This.router.navigate([ '' ]);

  }



  langC: string = "";
  title: string = "jD.jD-Title";
  createBtn: string = "jD.jD-createBtn";
  search: string = "jD.jD-search";
  searchplaceHolder: string = "tableSearchPlaceHolder";
  table1: string = "jD.jD-table1";
  table2: string = "jD.jD-table2";
  table3: string = "jD.jD-table3";
  table4: string = "jD.jD-table4";
  table4FlowDetail1: string = "jD.jD-table4-flowDetail1";
  table4FlowDetail2: string = "jD.jD-table4-flowDetail2";
  table5: string = "jD.jD-table5";
  table6: string = "jD.jD-table6";
  table6ApproveTooltip: string = "jD.jD-table6-approveTooltip";
  table6ShareTooltip: string = "jD.jD-table6-shareTooltip";
  table6WorkflowTooltip: string = "jD.jD-table6-WorkflowTooltip";
  editToolTip: string = "editToolTip";
  deleteToolTip: string = "deleteToolTip";
  viewToolTip: string = "viewToolTip";
  tableNullError: string = "nullData-table-error";
  jdDeleteTitle: string = "jD.jD-deletePopUptitle";
  jdDeletemsg1: string = "jD.jD-deletePopUpmsg1";
  jdDeletemsg2: string = "jD.jD-deletePopUpmsg2";
  deletesuccessMsg: string = "jD.jD-deletesuccessMsg";
  deleteErrorMsg: string = "jD.jD-deleteErrorMsg";
  paginatorLabel: string = "paginatorLabel";


  ngOnInit() {
    this.fetchJDList();
    this.fetchWorkFlows();
    this.communicationService.getLanguage().subscribe((value) => {
      this.onLanguageChange(value)
    })
  }

  onLanguageChange(lang: string) {
    this.langC = lang;
    this.translate.use(lang);
  }

  displayedColumns: string[] = ['index', 'name', 'code', 'flow', 'position', 'action'];
  dataSource = new MatTableDataSource<any>();

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  shareJd(uniqueCode) {

    const link = location.href.replace(this.router.url, '')
    let component = { name: 'linkshare', link: `${link}/${uniqueCode}` }
    this.dialog.open(InviteDialogeComponent, { data: component });

  }
  viewJd(element) {
    this.loading = true;
    this.router.navigate(['/home/create-jd'], { queryParams: { jdData: element?.uniqueCode, jdList: true, approve: element?.isApprover } });
    return

  }
  verifyJd(element) {
    this.loading = true;
    this.router.navigate(['/home/create-jd'], { queryParams: { jdData: element?.uniqueCode, jdList: true, approve: true, currentApprover: true } });
    return

  }
  editJd(element) {
    this.loading = true;
    this.router.navigate(['/home/create-jd'],
      { queryParams: { jdData: element?.uniqueCode, jdList: true, jdListEdit: true } });
    return
  }

  deleteJd(element) {

    const confirmDialog = this.dialog.open(DialogBoxComponent, {
      data: {
        title: this.translate.instant(this.jdDeleteTitle),
        message: `${this.translate.instant(this.jdDeletemsg1)}  "${element.name}" ${this.translate.instant(this.jdDeletemsg2)}`
      }
    });
    confirmDialog.afterClosed().subscribe((result) => {
      
      if (result === true) {

        this.jobDescriptionService.deleteJobDescription(element).subscribe(result => {
          console.log(result);
          if (result.message === 'success') {
            this._snackBar.openSnackBar(this.translate.instant(this.deletesuccessMsg), 'success');
            this.fetchJDList()
          } else {
            this._snackBar.openSnackBar(this.translate.instant(this.deleteErrorMsg), 'danger');
          }
        })

      }
    })



  }

  workFlowOnSelect(value) {


    this.dialog.open(DashboardDialogueComponent, { data: value });
    // this.trigger.closeMenu();
    // value['jdId'] = this.jdWorkFlowId;
    // this.jobDescriptionService.workFlowManagement(value).subscribe(data => {

    //   if (data.message === "success") {
    //     this._snackBar.openSnackBar(`Sucessfully Assigned ${value.name} Work Flow to the ${this.jdName} Job Description`, 'success');
    //     this.fetchJDList()
    //   }
    //   else {
    //     this._snackBar.openSnackBar('Error occured during work flow assignment', 'danger');
    //   }

    // })
  }

  jdIdForWorkFlowManagement(jd) {
    const jdObj = { id: jd?._id, name: jd?.name }

    const dialogRef = this.dialog.open(DashboardDialogueComponent, { data: jdObj });
    dialogRef.afterClosed().subscribe((result) => {

      if (result) {

        this.fetchJDList();

      }
      this.jdWorkFlowId = jd?._id;
      this.jdName = jd?.name;
    })
  }
  returnUserData(item, userData) {


    for (let user of userData) {
      if (item?.user.toString() === user?._id) {

        return { name: `${user?.name}`, level: `${item?.level}` }
      }
    }




  }

  returnUserName(item, userData) {
    const retundata: any = this.returnUserData(item, userData)
    return `${retundata?.name}`
  }

  returnUserLevel(item, userData) {
    const retundata: any = this.returnUserData(item, userData)
    return `Level - ${retundata?.level}`
  }
  flowDetails(option, trigger) {
    this.workFlowViewData = option;
    trigger.openMenu();

  }
  flowDetailsFalse(option) {
    this.workFlowViewData = null

  }

}
