<div [class.overlay]="loading" [class.spinner]="loading" (click)="$event.stopPropagation();"></div>

<div class="header-container">
    <h2 class="header">
        {{title1 | translate}}
    </h2>
</div>
<div class="main-wrapper">
    <div class="button-container">
        <button mat-raised-button color="primary" (click)="viewOrganization()">{{button1 | translate}}</button>
        <button mat-raised-button color="primary" (click)="updateOrganization()">{{button2 | translate}}</button>
    </div>
</div>
<mat-card>
    <!-- <div class="editBtnDiv">
        <mat-icon (click)="closeEditPage()">close</mat-icon>
    </div> -->
    <mat-card-content>

        <div class="memeber-table-container">
            <h1>{{title1A | translate}}</h1>
            <hr>

            <div class="sub-header-div">
                <form class="search-form">
                    <mat-form-field class="search-full-width" appearance="none">
                        <mat-icon matPrefix>search</mat-icon>
                        <input type="text" matInput placeholder="{{search | translate}}" (keyup)="applyFilterMember($event)" #input>
                    </mat-form-field>
                </form>
            </div>

            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 demo-table">
                <!-- Position Column -->
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> {{title1Label1 | translate}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="email">
                    <th class="table-email" mat-header-cell *matHeaderCellDef> {{title1Label2 | translate}} </th>
                    <td class="table-email" mat-cell *matCellDef="let element"> {{element.email}} </td>
                </ng-container>
                <ng-container matColumnDef="jobTitle">
                    <th mat-header-cell *matHeaderCellDef> {{title1Label3 | translate}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.jobTitle}} </td>
                </ng-container>
                <ng-container matColumnDef="mobile">
                    <th mat-header-cell *matHeaderCellDef> {{title1Label4 | translate}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.mobile}} </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <tr class="mat-row noDataError" *matNoDataRow>
                    <td class="mat-cell noDataError" colspan="4">{{tableNullData | translate}}</td>
                </tr>
            </table>
            <mat-paginator [length]="100" [pageSize]="5" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

        </div>

    </mat-card-content>
</mat-card>

<div style="margin: 15px;">

</div>

<mat-card>
    <mat-card-content>

        <div class="invitation-table-container">
            <h1>{{title2 | translate}}</h1>
            <hr>

            <div class="sub-header-div">
                <form class="search-form">
                    <mat-form-field class="search-full-width" appearance="none">
                        <mat-icon matPrefix>search</mat-icon>
                        <input type="text" matInput placeholder="{{search | translate}}" (keyup)="applyFilterInvite($event)" #input>
                    </mat-form-field>
                </form>
            </div>

            <table mat-table [dataSource]="dataSourceInvitation" class="mat-elevation-z8 demo-table">
                <!-- Position Column -->
                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef> {{title2Label1 | translate}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.invitedUserMailId}} </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="invited-time">
                    <th mat-header-cell *matHeaderCellDef> {{title2Label2 | translate}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.sendTimeStamp| date:"dd/MM/yyyy hh:mm:ss
                        a":"+0000"}} </td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>{{title2Label3 | translate}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.inviteStatus}} </td>
                </ng-container>
                <ng-container matColumnDef="accepted-time">
                    <th mat-header-cell *matHeaderCellDef> {{title2Label4 | translate}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.acceptedTimeStamp| date:"dd/MM/yyyy hh:mm:ss
                        a":"+0000"}} </td>
                </ng-container>
                <ng-container matColumnDef="action" >
                    <th mat-header-cell *matHeaderCellDef class="action"> {{title2Label5 | translate}} </th>
                    <td mat-cell *matCellDef="let element">
                        <mat-icon class="invitation-action" (click)="revokeInvitation(element)"
                            matTooltip="{{title2ToolTip | translate}}">delete</mat-icon>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsInvitation"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsInvitation;"></tr>
                <tr class="mat-row noDataError" *matNoDataRow>
                    <td class="mat-cell noDataError" colspan="5">{{tableNullData | translate}}</td>
                </tr>
            </table>
            <mat-paginator #invitePaginator [length]="100" [pageSize]="5" [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>


        </div>

    </mat-card-content>
</mat-card>