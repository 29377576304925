import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute  } from '@angular/router';
import { AccountService } from '@atmeservices/account.service';
import { OrganisationManagementService } from '@atmeservices/organisation-management.service';
import { CommunicationService } from '@atmeservices/communication.service';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { InviteDialogeComponent } from '../../components/invite-dialoge/invite-dialoge.component';
import { OrganisationManagementDialogComponent } from '../organisation-management/organisation-management-dialog/organisation-management-dialog.component';
import { UserService } from '@atmeservices/user.service';
import { UsersessionService } from '@atmeservices/usersession.service';
import { ROLE_SUPERADMIN } from '@atme/Constants';
import { WorkFlowService } from '@atmeservices/work-flow.service';
import { TranslateService } from "@ngx-translate/core";



interface Country {
  name: string,
  flag: string,
  language: string,
  code: string
}


@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss']
})



export class SidemenuComponent implements OnInit {
  shouldShow = true;
  
  loading = false;

  userName: string;

  organisationName: string;

  organisationFavicon: string;

  organisationID: string;

  title: any;

  url: any;

  countryArray: Array<Country> = [
    {
      name: 'Britain',
      flag: "/assets/images/country-flags/united-kingdom-flag.png",
      language: 'English',
      code: 'en',
    },
    {
      name: 'France',
      flag: "/assets/images/country-flags/france-flag.png",
      language: 'French',
      code: 'fr',
    },
    {
      name: 'Germany',
      flag: "/assets/images/country-flags/germany-flag.png",
      language: 'German',
      code: 'de',
    }
  ]


  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches), shareReplay()
    );
  picurl: any;

  selectedFlag = '/assets/images/country-flags/united-kingdom-flag.png'

  isSuperAdmin: boolean;

  isOrganisationAdmin = false

  constructor(private breakpointObserver: BreakpointObserver, private router: Router,
    private readonly accountService: AccountService,
    private communicationService: CommunicationService,
    private userService: UserService,
    private translate: TranslateService,
    private workFlowService: WorkFlowService,
    private userSessionService: UsersessionService,
    private readonly organisationService: OrganisationManagementService,
    public dialog: MatDialog,
    private route: ActivatedRoute
  ) { 

    this.route.paramMap.subscribe(val => {
                  // put the code from `ngOnInit` here
                  console.log("132413255255252525")
                });
  }

  ngOnInit(): void {
    this.hideNotify()
    this.fetchLoginuserRole()
    this.setprofilepic()
    this.userName = localStorage.getItem('name');
    this.fetchOrganisationName();
    this.checkIsLoginUserIsAdmin()
    this.communicationService.getValueForOrganisation().subscribe((value) => {
      this.fetchOrganisationName()
    })
    this.communicationService.getValueForProfile().subscribe((value) => {
      this.setprofilepic()
    })
    this.communicationService.getLanguage().subscribe((value) => {
      this.onLanguageChange(value)
    })
    const localLang = localStorage.getItem('language')
    if (localLang) {
      this.selectedFlag = localStorage.getItem('flag')
      this.onLanguageChange(localLang)
    }

    this.url = this.router.url;
    if(((this.url).split('/').pop()) == 'dashboard' ){
      this.title = 'Job Description';
    }else{
      this.title = (this.url).split('/').pop();
    }
    
    console.log(this.title)
  }

  ngAfterViewInit() {
    console.log(this.router)
  }


  onLanguageChange(lang: string) {
    this.langC = lang;
    this.translate.use(lang);
  }

  langC: string = "en";
  sideMenu1: string = "sideMenu.sideMenu-menu1";
  sideMenu2: string = "sideMenu.sideMenu-menu2";
  sideMenu3: string = "sideMenu.sideMenu-menu3";
  sideMenu4: string = "sideMenu.sideMenu-menu4";
  sideMenu5: string = "sideMenu.sideMenu-menu5";
  sideMenu6: string = "sideMenu.sideMenu-menu6";
  sideMenu7: string = "sideMenu.sideMenu-menu7";
  sideMenu8: string = "sideMenu.sideMenu-menu8";
  sideMenu9: string = "sideMenu.sideMenu-menu9";
  sideMenu10: string = "sideMenu.sideMenu-menu10";
  sideMenu10A: string = "sideMenu.sideMenu-menu10-A";

  navBar1: string = "navBar.navBar-menu1";
  navBar2: string = "navBar.navBar-menu2";
  navBar3: string = "navBar.navBar-menu3";
  navBar4: string = "navBar.navBar-menu4";
  navBar5: string = "navBar.navBar-menu5";
  navBar6: string = "navBar.navBar-menu6";


  sidemenuToggle(event) {

    if (event.target.parentNode.children[1]) {
      if (event.target.parentNode.children[1].style.display == 'block') {
        event.target.parentNode.children[1].style.display = "none";
      } else {
        event.target.parentNode.children[1].style.display = "block";
      }
    }

  }
  countryFlagSelect(value: Country) {
    this.selectedFlag = value.flag;
    this.communicationService.setLanguage(value.code);
    localStorage.setItem('language', value.code);
    localStorage.setItem('flag', value.flag);
  }

  changePassword(): void {

    this.router.navigate(['/home/change-password']);

  }

  setprofilepic() {
    this.userService.fetchLoginuser().subscribe((message) => {
      this.picurl = message['photoUrl'];
      this.userName = message['name'];
    });
  }

  logOut(): void {

    this.userSessionService.updateUserSession().subscribe((message) => {
      if (message.message === 'success') {

        this.accountService.logout();
        localStorage.clear();
        this.router.navigate(['']);
        this.communicationService.setLanguage('en')

      }
    })


  }

  invite(): void {

    let inviteData = { name: 'invite' }
    const inviteDialog = this.dialog.open(InviteDialogeComponent, { data: inviteData });
    inviteDialog.afterClosed().subscribe((result) => {
      if (result) {
        this.communicationService.setValueForInvitation(true)
      }
    })


  }

  editProfile(): void {

    this.router.navigate(['/home/edit-profile'], { queryParams: { id: this.organisationID } });

  }

  fetchLoginuserRole() {

    this.userService.fetchLoginuserRole().subscribe(userRole => {
      if (userRole?.code == ROLE_SUPERADMIN) {
        this.isSuperAdmin = true;
      }
    })

  }

  checkIsLoginUserIsAdmin() {
    this.workFlowService.isLoginedUserIsAdmin().subscribe((message) => {

      console.log(message);
      this.isOrganisationAdmin = message.Result


    })
  }

  createOrganisation() {

    const dialogRef = this.dialog.open(OrganisationManagementDialogComponent, { data: { component: 'create' } });
    // Create subscription
    dialogRef.afterClosed().subscribe((result) => {

      if (result) {
        this.fetchOrganisationName()
      }

    });

  }

  fetchOrganisationName() {

    this.organisationService.fetchOrganisationName().subscribe((message) => {
      this.organisationName = message?.Result?.name
      this.organisationFavicon = message?.Result?.favicon;
      this.organisationID = message?.Result?._id;
    })
  }

  navigateOrganisationDetails() {
    this.router.navigate(['/home/organisation']);
  }
  page(name){
    this.title = name;
    console.log(this.title)
  }

  hideNotify(){
    console.log(this.shouldShow)

  var x = document.getElementById("notification");
  if (this.shouldShow == false) {
    x.style.display = "block";
  } else {
    x.style.display = "none";
  }
  }

  // editProfilebutton(){
  //   // $(".profilename").addClass("intro")
  //   var element = document.getElementById("myDIV");
    
  //   if (element.classList.contains("intro")) {
  //     element.classList.remove("intro");
  // } else {
  //     element.classList.add("intro");;
  // }

  // }


}

