<mat-dialog-content class="mat-typography">

  <div>
    <table mat-table [dataSource]="dataSource" class="name-table">

      <!-- Position Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef class="name"> {{label1 | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="noJD">
        <th mat-header-cell *matHeaderCellDef class="noJd"> {{label2 | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element?.number}} </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

  </div>

</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-button mat-dialog-close class="button-close" data-backdrop="static" data-keyboard="false">Close</button>
</mat-dialog-actions>