<div [class.overlay]="loading" [class.spinner]="loading" (click)="$event.stopPropagation();"></div>
<!-- <div class="login-wrapper"> -->
<div class="changepassword-wrapper">
    <mat-card>

        <!-- <mat-card-title>{{title | translate}}</mat-card-title> -->
        <mat-card-content>
            <div class="form-group-wrapper">
                <form [formGroup]="changePasswordForm" class="login-form" (ngSubmit)="changePassword()">

                    <br>
                    <div class="input_field_wrapper" *ngIf="hideCurrentPsw">
                        <span>{{label1 | translate}}</span>
                        <mat-form-field floatLabel="never" appearance="none">
                            <input [type]="showCurrentonPsw ? 'text' : 'password'" matInput
                                placeholder="{{label1Placeholder | translate}}" formControlName="oldPassword" required
                                (focus)="onFocusold()">
                            <mat-icon class="pass_hide_icon" (click)="currentPassHideUnhide()">{{visibility}}</mat-icon>
                            <mat-error *ngIf="changePasswordForm.get('oldPassword').hasError('required')">{{label1Error1
                                | translate}}</mat-error>
                        </mat-form-field>
                        <p class="error" *ngIf="oldPassError">{{label1Error2 | translate}}</p>
                    </div>
                    <div class="input_field_wrapper">
                        <span>{{label2 | translate}}</span>
                        <mat-form-field floatLabel="never" appearance="none" class="new_pass">
                            <input [type]="showNewPsw ? 'text' : 'password'" matInput
                                placeholder="{{label2Placeholder | translate}}" formControlName="newPassword" required
                                (focus)="onFocusnew()" (keyup)="errorCheck()">
                            <mat-icon class="pass_hide_icon" (click)="passHideUnhide()">{{visibility}}</mat-icon>

                            <!-- <mat-error *ngIf="changePasswordForm.get('newPassword').hasError('required')">Please Enter
                                Your New Password</mat-error> -->
                            <!-- <mat-error *ngIf="changePasswordForm.get('newPassword').hasError('pattern')">Password Must
                                Have 1 UpperCase, 1 LowerCase,1 Number And Minimum 8 Digit </mat-error> -->
                        </mat-form-field>
                        <div class="password-rules">
                            <span>
                                <mat-icon [class]="errorClass4">{{errorIcon4}}</mat-icon>
                                <p class="err_chk_msg">{{label2Error1 | translate}}</p>
                            </span>
                            <br>
                            <span>
                                <!-- <mat-icon [class]="errorClass">{{errorIcon_2}}</mat_icon> -->
                                <mat-icon [class]="errorClass3">{{errorIcon3}}</mat-icon>
                                <p class="err_chk_msg">{{label2Error2 | translate}}</p>
                            </span>
                            <!-- [style.color]= "errorClass3 == 'err_chk_icon_green'?green:red" -->
                            <br>
                            <span>
                                <mat-icon [class]="errorClass2">{{errorIcon2}}</mat-icon>
                                <p class="err_chk_msg">{{label2Error3 | translate}}</p>
                            </span>
                            <br>
                            <span>
                                <mat-icon [class]="errorClass1">{{errorIcon1}}</mat-icon>
                                <p class="err_chk_msg">{{label2Error4 | translate}}</p>
                            </span>

                        </div>
                        <p class="error" *ngIf="newPassError">{{label2Error5 | translate}}</p>

                    </div>
                    <div class="input_field_wrapper confirmPassword">
                        <span>{{label3 | translate}}</span>
                        <mat-form-field floatLabel="never" appearance="none" class="new_pass_confirm">
                            <input [type]="showConPsw ? 'text' : 'password'" matInput
                                placeholder="{{label3Placeholder | translate}}" formControlName="confirmPassword"
                                required>
                            <mat-icon class="pass_hide_icon" (click)="cPassHideUnhide()">{{cvisibility}}</mat-icon>
                            <mat-error *ngIf="changePasswordForm.get('confirmPassword').hasError('required')">
                                {{label3Error1 | translate}}
                            </mat-error>
                            <mat-error *ngIf="changePasswordForm.get('confirmPassword').errors?.mustMatch">
                                {{label3Error2 | translate}}</mat-error>
                            <!-- <mat-error>{{errorPassword}}</mat-error> -->
                        </mat-form-field>
                    </div>
                    
                </form>
            </div>
        </mat-card-content>

    </mat-card>

</div>

<div class="pswd-buttons">
                        <button type="button" (click)='cancel()' class="cancel_button" mat-button>Back</button>
                        <button type="submit" class="change_pass_button" mat-button>Save Changes</button>
</div>