
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UsersessionService } from '../../services/usersession.service';
import { MatTableModule } from '@angular/material/table'
import { TranslateService } from '@ngx-translate/core';
import { CommunicationService } from '@atmeservices/communication.service';
// import { CommunicationService } from '@atmeservices';

@Component({
  selector: 'app-user-session',
  templateUrl: './user-session.component.html',
  styleUrls: ['./user-session.component.scss']
})
export class UserSessionComponent implements OnInit {

  displayedColumns: string[] = ['full_name', 'start_time', 'end_Time', 'agent', 'login_agent', 'ip_address', 'location'];
  dataSource = new MatTableDataSource<any>();


  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  loading = false;
  userData: any = []
  constructor(
    private readonly userSessionsession: UsersessionService,
    private translate: TranslateService,
    private communicationService: CommunicationService,
    // private _userPermission : CommunicationService
  ) { }

  ngOnInit(): void {
    this.fetchData()
    this.communicationService.getLanguage().subscribe((value) => {
      this.onLanguageChange(value)
    })

  }
  onLanguageChange(lang: string) {
    this.langC = lang;
    this.translate.use(lang);
  }

  langC: string = "";
  title: string = "userSession.title";
  search: string = "tableSearchPlaceHolder";
  label1: string = "userSession.labe1";
  label2: string = "userSession.labe2";
  label3: string = "userSession.labe3";
  label4: string = "userSession.labe4";
  label5: string = "userSession.labe5";
  label6: string = "userSession.labe6";
  label7: string = "userSession.labe7";
  tableNullData: string = "nullData-table-error";
  paginatorLabel: string = "paginatorLabel"





  // Search user
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }

  }


  fetchData() {
    this.loading = true;
    this.userSessionsession.getusersession().subscribe((message) => {
      console.log(message, 'session');
      this.userData = message;
      this.loading = false;
      this.dataSource = new MatTableDataSource(this.userData);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

    })
  }



}
