/* eslint-disable dot-notation */
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { BASE_URI, JOBLEVELS_API } from '@atme/serverAPI';
import { JobLevelS } from '@atme/entity/jobLevels';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class JobLevelsService {

  constructor(
    private readonly http: HttpClient
  ) { }

  createJobLevel(jobLevel: JobLevelS): Observable<any> {

    return this.http.post<JobLevelS>(`${BASE_URI}${JOBLEVELS_API}`, jobLevel).pipe(
      catchError((err) => throwError(err))
    );

  }

  updateJobLevel(jobLevel: JobLevelS, jobLevelId: string): Observable<any> {

    return this.http.put<JobLevelS>(`${BASE_URI}${JOBLEVELS_API}`, { params: jobLevel,
      jobLevelId }).pipe(
      catchError((err) => throwError(err))
    );

  }

  deleteJobLevelById(jobId: any): Observable<unknown> {

    return this.http.delete(`${BASE_URI}${JOBLEVELS_API}`, { params: jobId }).pipe(
      catchError((err) => throwError(err))
    );

  }

  fetchJobLevelList(): Observable<any> {

    return this.http.get(`${BASE_URI}${JOBLEVELS_API}`).pipe(
      catchError((err) => throwError(err))
    );

  }

  fetchJobLevelById(jobData: any): Observable<any> {

    return this.http.get(`${BASE_URI}${JOBLEVELS_API}/edit`, { params: jobData }).pipe(
      catchError((err) => throwError(err))
    );

  }

}
