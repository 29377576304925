/* eslint-disable dot-notation */
import { Component, ElementRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from '../../services/account.service';
import { UserService } from '@atmeservices/user.service';
import { SnackbarService } from 'src/app/helpers/snackbar.service';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';
import { Subscription } from 'rxjs';
import { LINKEDIN_API } from '../../../../../atme-shared/dist/serverAPI';
import { ROLE_SUPERADMIN } from '@atme/Constants';
import { TranslateService } from '@ngx-translate/core';
import { CommunicationService } from '@atmeservices/communication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})

export class LoginComponent {

  // successMessage = 'Login successfully...';

  error: string | null;

  loading = false;

  loggedIn: boolean;

  linkedInCode;

  linkedInHref = '';

  loginSubscription: Subscription

  form: FormGroup = new FormGroup({
    email: new FormControl(''),
    password: new FormControl(''),
  });

  constructor(private readonly userService: UserService,
    private readonly accountService: AccountService,
    private elementRef: ElementRef,
    private route: ActivatedRoute,
    private _snackBar: SnackbarService,
    private readonly router: Router,
    private translate: TranslateService,
    private communicationService: CommunicationService,
    private authService: SocialAuthService) { }

  /*
   * NgAfterViewInit() {
   *   this.elementRef.nativeElement.ownerDocument
   *     .body.style.backgroundColor = '#9354f9';
   * }
   */

  submit(): void {

    if (!this.form.value.email) {

      this.error = this.translate.instant(this.emailError);
      return;

    }
    if (!this.form.value.password) {

      this.error = this.translate.instant(this.passwordError);
      return;

    }

    this.loading = true;
    this.error = null;
    this.userService.login(this.form.value).subscribe((message: any) => {

      if (message) {

        this.accountService.login(message['jwttoken'], message['userName']);
        if (message?.message === 'success') {

          if (message?.role?.code === ROLE_SUPERADMIN) {
            this._snackBar.openSnackBar(this.translate.instant(this.successMsg), 'success');
            this.router.navigateByUrl('/home/overview');
          } else {
            this._snackBar.openSnackBar(this.translate.instant(this.successMsg), 'success');
            this.router.navigateByUrl('/home/dashboard');
          }

        }
      }
      this.loading = false;

    }, (error) => {

      this.loading = false;
      console.error(error);
      this.error = this.translate.instant(this.credentialError);

    });


  }

  ngOnInit(): void {

    this.linkedInCode = this.route.snapshot.queryParamMap.get('code');

    if (this.linkedInCode !== null) {

      this.loading = true;
      this.accountService.verifyLinkedInUser(this.linkedInCode).subscribe((message) => {

        if (message) {

          this.linkedInCode = '';
          this.accountService.login(message['jwttoken'], message['userName']);

          if (message['message'] === 'success') {

            this.router.navigateByUrl('/home/dashboard');
            this.loading = false;
            this._snackBar.openSnackBar(this.translate.instant(this.successMsg), 'success');
            this.loginSubscription?.unsubscribe();

          } else if (message['message'] === 'Email id already exists.') {

            this.linkedInCode = '';
            this.router.navigateByUrl('/');
            this._snackBar.openSnackBar('User Is Already Existed', 'danger');
            this.loading = false;

          }

        }

      });

    }

    this.linkedInHref = location.href.split('?')[0].concat(`api${LINKEDIN_API}/`).replace('https://localhost:4200', 'http://localhost:9000');


    this.loginSubscription = this.authService.authState.subscribe((user) => {

      this.loggedIn = user !== null && (user.name !== '' || user.name !== null);

      if (this.loggedIn) {

        this.accountService.providerValidation(user).subscribe((message) => {

          if (message) {

            this.accountService.login(message['jwttoken'], message['userName']);
            if (message['message'] === 'success') {

              this.router.navigateByUrl('/home/dashboard');
              this.loading = false;
              this._snackBar.openSnackBar(this.translate.instant(this.successMsg), 'success');
              this.loginSubscription?.unsubscribe();

            }

            /*
             * // this.resetFields();
             * // localStorage.setItem('loginMode', 'provider');
             * // this.onLogin(this.mode);
             */

          }

        }, (error): void => {

          console.error(error);
          localStorage.removeItem('loginMode');

        });

      }


    });
    this.communicationService.getLanguage().subscribe((value) => {
      this.onLanguageChange(value)
    })

  }
  onLanguageChange(lang: string) {
    this.langC = lang;
    this.translate.use(lang);
  }

  langC: string = "";
  title: string = "signin.title";
  placeHolder1: string = "signin.placeHolder1";
  placeHolder2: string = "signin.placeHolder2";
  signinBtn: string = "signin.signinBtn";
  label1: string = "signin.label1";
  label1A: string = "signin.label1A";
  label2: string = "signin.label2";
  successMsg: string = "signin.successMsg";
  emailError: string = "signin.emailError";
  passwordError: string = "signin.passwordError";
  credentialError: string = "signin.credentialError";


  ngOnDestroy(): void {

    this.loginSubscription?.unsubscribe();

  }

  signInWithGoogle(): void {

    this.loading = true;
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID)
    ['catch']((error: unknown) => {

      this.loading = false;
      console.error(error);


    });

  }

  signInWithFB(): void {

    this.loading = true;
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID)
    ['catch']((error: unknown) => {

      this.loading = false;
      console.error(error);

    });

  }

  signInWithIN(): void {

    this.loading = true;
    this.accountService.loginWithLinkedIN().subscribe((message) => {

      console.error(message);

    });

  }

}
