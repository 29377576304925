import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { SignupComponent } from './pages/signup/signup.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { EditProfileComponent } from './pages/edit-profile/edit-profile.component';
import { ChangePasswordComponent } from './pages/sidemenu/change-password/change-password.component';
import { SidemenuComponent } from './pages/sidemenu/sidemenu.component';
import { ForgotPasswordComponent } from './pages/signup/forgot-password/forgot-password.component';
import { ViewJdComponent } from './pages/view-Public-Jd/view-Public-Jd.component';
import { OrganisationManagementComponent } from './pages/organisation-management/organisation-management.component';
import { IndustryManagementComponent } from './pages/industry-management/industry-management.component';
import { QualificationManagementComponent } from './pages/qualification-management/qualification-management.component';
import { JobFamilyManagementComponent } from './pages/job-family-management/job-family-management.component';
import { JobLevelManagementComponent } from './pages/job-level-management/job-level-management.component';
import { JobTitleManagementComponent } from './pages/job-title-management/job-title-management.component';
import { SkillManagementComponent } from './pages/skill-management/skill-management.component';
import { CreateJdComponent } from './pages/create-jd/create-jd.component';
import { OverviewComponent } from './pages/overview/overview.component';
import { WorkFlowManagementComponent } from './pages/work-flow-management/work-flow-management.component';
import { UserSessionComponent } from './pages/user-session/user-session.component';
import { DetailComponentComponent } from './pages/detail-component/detail-component.component';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { BlogComponent } from './blog/blog.component';
import { ContactUsComponent } from './contact-us/contact-us.component';


const routes: Routes = [
  {
    path: 'homepage',
    component: HomepageComponent,
  },
  {
    path: '',
    component: AboutusComponent,
  },
  {
    path: 'blog',
    component: BlogComponent,
  },
  {
    path: 'contact',
    component: ContactUsComponent,
  },

  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'signup',
    component: SignupComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: ':linkName',
    component: ViewJdComponent,
  },
  {
    path: 'homepage',
    component: HomepageComponent,
  },
  {
    path: 'home',
    component: SidemenuComponent,
    children: [
      {
        path: '',
        component: DashboardComponent
      },
      {
        path: 'overview',
        component: OverviewComponent
      },
      {
        path: 'workflow',
        component: WorkFlowManagementComponent
      },
      {
        path: 'dashboard',
        component: DashboardComponent
      },
      {
        path: 'create-jd',
        component: CreateJdComponent
      },
      {
        path: 'organisation',
        component: OrganisationManagementComponent
      },
      {
        path: 'edit-profile',
        component: EditProfileComponent
      },
      {
        path: 'change-password',
        component: ChangePasswordComponent
      },
      {
        path: 'industry',
        component: IndustryManagementComponent
      },
      {
        path: 'qualification',
        component: QualificationManagementComponent
      },
      {
        path: 'jobFamily',
        component: JobFamilyManagementComponent
      },
      {
        path: 'joblevel',
        component: JobLevelManagementComponent
      },
      {
        path: 'jobTitle',
        component: JobTitleManagementComponent
      },
      {
        path: 'skills',
        component: SkillManagementComponent
      },
      {
        path: 'session',
        component: UserSessionComponent
      },
      {
        path: 'detail',
        component: DetailComponentComponent
      },
    
     
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
