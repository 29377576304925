<div [class.overlay]="loading" [class.spinner]="loading" (click)="$event.stopPropagation();"></div>

<mat-sidenav-container class="sidenav-container">
    <mat-sidenav #drawer class="sidenav" fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
        [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="(isHandset$ | async) === false" [ngClass]="{ 'menuwrap': !shouldShow}">

        <div class="logo-sec">
            <!-- <p><strong> UG Service</strong></p> -->
            <div class="logo">
                <!-- <img src="assets/images/logo/Ug-tech.png" alt=""> -->
              <img src="../../../assets/images/JDWIZ.png">
            </div>

        </div>

        <div class="nav-card">
        <ul class="nav-list">

            <li *ngIf="isSuperAdmin">
                <a routerLink="overview" routerLinkActive="active-link">
                    <mat-icon class="list-item"><img src="../../../assets/images/menu-icon1.png"></mat-icon>
                    <!-- <span class="list-name">Overview</span> -->
                    <span class="list-name">{{sideMenu1 | translate}}</span>
                </a>
            </li>
            <li>
                <a routerLink="dashboard" routerLinkActive="active-link" (click)="page('Job Description')" >
                    <div class="card">
                    <mat-icon class="list-item"><img src="../../../assets/images/menu-icon3.png"></mat-icon>
                    <span class="list-name">{{sideMenu2 | translate}}</span>
                    </div>
                </a>
            </li>
            <li *ngIf="isOrganisationAdmin">
                <a routerLink="workflow" routerLinkActive="active-link" (click)="page('Work Flow')">
                    <div class="card">
                    <mat-icon class="list-item"><img src="../../../assets/images/menu-icon2.png"></mat-icon>
                    <span class="list-name">{{sideMenu3 | translate}} </span>
                    </div>
                </a>
            </li>
            <li>
                <a routerLink="industry" routerLinkActive="active-link" (click)="page('Industry')">
                    <div class="card">
                    <mat-icon class="list-item"><img src="../../../assets/images/menu-icon4.png"></mat-icon>
                    <span class="list-name">{{sideMenu4 | translate}}</span>
                    </div>
                </a>
            </li>
            <li>
                <a routerLink="qualification" routerLinkActive="active-link" (click)="page('Qualifications')">
                    <div class="card">
                    <mat-icon class="list-item"><img src="../../../assets/images/menu-icon5.png"></mat-icon>
                    <span class="list-name">{{sideMenu5 | translate}}</span>
                    </div>
                </a>
            </li>
            <li>
                <a routerLink="jobFamily" routerLinkActive="active-link" (click)="page('Job Family')">
                    <div class="card">
                    <mat-icon class="list-item"><img src="../../../assets/images/menu-icon6.png"></mat-icon>
                    <span class="list-name">{{sideMenu6 | translate}}</span>
                    </div>
                </a>
            </li>
            <li>
                <a routerLink="joblevel" routerLinkActive="active-link" (click)="page('Job Level')">
                    <div class="card">
                    <mat-icon class="list-item"><img src="../../../assets/images/menu-icon7.png"></mat-icon>
                    <span class="list-name">{{sideMenu7 | translate}}</span>
                    </div>
                </a>
            </li>
            <li>
                <a routerLink="jobTitle" routerLinkActive="active-link" (click)="page('Job Title')">
                    <div class="card">
                    <mat-icon class="list-item"><img src="../../../assets/images/menu-icon8.png"></mat-icon>
                    <span class="list-name">{{sideMenu8 | translate}}</span>
                    </div>
                </a>
            </li>
            <li>
                <a routerLink="skills" routerLinkActive="active-link" (click)="page('Skills')">
                    <div class="card">
                    <mat-icon class="list-item"><img src="../../../assets/images/menu-icon9.png"></mat-icon>
                    <span class="list-name">{{sideMenu9 | translate}}</span>
                    </div>
                </a>
            </li> 
            <li>
                <a routerLink="detail" routerLinkActive="active-link" (click)="page('Details')">
                    <div class="card">
                    <mat-icon class="list-item"><img src="../../../assets/images/menu-icon3.png"></mat-icon>
                    <span class="list-name">{{'Details' | translate}}</span>
                    </div>
                </a>
            </li>
            <li *ngIf="isSuperAdmin" class='sub-menu'><a (click)="sidemenuToggle($event)">
                    <mat-icon>timeline</mat-icon>{{sideMenu10 | translate}}
                </a>
                <ul>
                    <li>
                        <a routerLink="session" routerLinkActive="active-link">
                            <mat-icon><img src="../../../assets/images/menu-icon3.png"></mat-icon>
                            <span class="list-name">{{sideMenu10A | translate}}</span>
                        </a>
                    </li>
                </ul>
            </li>

        </ul>
        </div>
    </mat-sidenav>

    <mat-sidenav-content [ngClass]="{ 'flexwrap': !shouldShow }">
        <!-- Nav bar section here -->
        <mat-toolbar>

            <mat-button-toggle type="button" mat-button  (click)="shouldShow = !shouldShow" (click)="hideNotify()">
                <mat-icon class="menu-icon"><img src="../../../assets/images/menu-ico.png"></mat-icon>
            </mat-button-toggle>
            <div class="header-container">
                <h2 class="header">
                   {{ title}} 
                </h2>
                
            </div>

            <!-- <span><strong>Home</strong></span> -->
            <button mat-flat-button color="primary" class="navbar-invite-btn add-btn" (click)="invite()">
                <mat-icon><img src="../../../assets/images/invite-icon.png"></mat-icon>
                {{'Invite New' | translate}}
            </button>
            <mat-form-field appearance="none" class="select-btn">
                <mat-label>Individual Account</mat-label>
                <mat-select>
                  <mat-option>User Account</mat-option>
                  <mat-option>Primary</mat-option>
                  <mat-option>Secondary</mat-option>
                </mat-select>
              </mat-form-field>

            <span class="example-spacer"></span>

            <div class="notification" id="notification">
                <mat-icon matBadge="15" matBadgeColor="warn">notifications_none</mat-icon>
           
            </div>

            <!-- <div class="country-flag-wrapper">
                <mat-icon>flag</mat-icon>

                <div class="country-flag-dropdown">
                    <mat-select (selectionChange)="countryFlagSelect($event.value)">
                        <mat-option *ngFor="let country of countryArray" [value]="country">

                            <div class="dropdown-option">
                                <img width="25" height="25" [src]="country.flag">
                                
                            </div>
                        </mat-option>
                    </mat-select>
                </div>
            </div> --> <!-- {{country.language}} -->

         
            <!-- <span class="account-type" #teamDrawerBtn (click)="teamdrawer.toggle()">Individual Account <mat-icon>
                    expand_more
                </mat-icon></span> -->

            <!-- <span #organisationDrawerBtn (click)="organisationdrawer.toggle()" *ngIf="false"></span>
            <div class="account-type">
                <span *ngIf="!organisationName">{{navBar2 | translate}}</span>
                <span *ngIf="organisationName">{{organisationName}}</span>
                <mat-icon [matMenuTriggerFor]="menu" class="account-type-icon"> expand_more</mat-icon>
            </div> -->  <!-- was here -->

            <!-- <div *ngIf="teamName" class="account-type teamName">

            </div> -->


            <button class="profile" [matMenuTriggerFor]="profile" >
                <div class="profilename" id="myDIV">
                <mat-icon>arrow_drop_down</mat-icon>
                <p class="username">{{userName}}</p>
                </div>
                <div class="userProfile-wrapper">
                    <div *ngIf="!picurl" class="profileLetterDiv">
                        <!-- <span>{{userName.charAt(0)}}</span> -->
                        <img src="../../../assets/images/dp.png">

                    </div>
                    <img *ngIf="picurl" [src]="picurl" alt="" width="50">
                </div>
                

            </button>
            
            <mat-menu #profile="matMenu" class="profile-menu">
                <!-- <div class="profile-details">
                    <h2>{{userName}}</h2>
                </div> -->

                <button mat-menu-item (click)="editProfile()">
                    <mat-icon>edit</mat-icon> <span>{{navBar3 | translate}}</span>
                </button>
                <button mat-menu-item (click)="changePassword()">
                    <mat-icon>settings</mat-icon> <span>{{navBar4 | translate}}</span>
                </button>

                <button mat-menu-item (click)="editProfile()">
                    <mat-icon>person_outline</mat-icon> <span>{{'Refer a Friend' | translate}}</span>
                </button>

                <button mat-menu-item (click)="changePassword()">
                    <mat-icon>security</mat-icon> <span>{{'Privacy Policy' | translate}}</span>
                </button>
                <button mat-menu-item (click)="logOut()">
                    <mat-icon>help_outline</mat-icon> <span>{{'Help' | translate}}</span>
                </button>

                <button mat-menu-item (click)="logOut()">
                    <mat-icon>exit_to_app</mat-icon> <span>{{navBar5 | translate}}</span>
                </button>
            </mat-menu>
            <!-- </div> -->


            <!-- </div> -->
        </mat-toolbar>

        <!-- Add Content Here -->
        <div class="contents">

            <router-outlet>

                <mat-menu class="filter-drawer" #menu="matMenu" xPosition="before">
                    <div class="organisationdrawer-container">
                        <button *ngIf="!organisationName" (click)="createOrganisation()" mat-raised-button
                            color="primary">{{navBar6 | translate}}</button>
                        <div class="organisationProfile" *ngIf="organisationName">
                            <img *ngIf="organisationFavicon" [src]="organisationFavicon" alt="" width="50">
                            <div *ngIf="!organisationFavicon" class="example-header-image">
                                <span>{{organisationName.charAt(0)}}</span>
                            </div>
                            <button (click)="navigateOrganisationDetails()" class="organisationNameBtn">
                                <span class="organisationName">{{organisationName}}</span>
                            </button>
                        </div>

                    </div>
                </mat-menu>
                <!-- <mat-drawer-container class="example-container" hasBackdrop="true">

                    <mat-drawer mode="true" class="filter-drawer" #teamdrawer mode="over" position="end">
                        <div class="filter-close">
                            <mat-icon class="filter-close-icon" (click)="!teamdrawer.toggle()">close</mat-icon>
                        </div>
                        <div class="teamdrawer-container">
                            <button (click)="createTeam()" mat-raised-button color="primary">Create Team</button>
                        </div>
                    </mat-drawer>

                </mat-drawer-container> -->

            </router-outlet>
        </div>

    </mat-sidenav-content>

</mat-sidenav-container>