<div class="aboutPage">
    <nav class="topHeader">
       
            <div>
                <img src="../../../assets/images/logo.png" class="headerLogo">
            </div>
            <div>
                <ul class="topMenu">
                    <li><a>Home</a></li>
                    <li><a [routerLink]="['/']">About</a></li>
                    <li><a>Create Jd</a></li>
                    <li [routerLink]="['/blog']"><a>Blog</a></li>
                    <li [routerLink]="['/contact']"><a>Contact</a></li>
                    <li [routerLink]="['/signup']"><a class="signupBtn">Signup</a></li>
                </ul>
            </div>
   
    </nav>
    

<div class="pageTitles">
    <img src="../../../assets/images/vdots.png" class="v-dot">
    
    <div class="grid">
        <div class="grid__item grid__item--md-span-5">
        <h1>About<br>Us</h1>
    </div>
    <div class="grid__item grid__item--md-span-7">
         <p>Lorem ipsum dolor sit, Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ad, sequi. Exercitationem quod 
             assumenda sunt quaerat quasi, Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ad,
              sequi. Exercitationem quod assumenda sunt quaerat quasi,<br><br>amet consectetur adipisicing elit. Ad,
               sequi. Exercitationem quod assumenda sunt quaerat quasi, </p>
    </div>
</div>
</div>


<div class="sectionBanner">
    <img src="../../../assets/images/color-circle1.png" class="clr-circle clr-w1 clr-pos1">
    <img src="../../../assets/images/color-circle1.png" class="clr-circle clr-w2 clr-pos2">
    <img src="../../../assets/images/color-circle1.png" class="clr-circle clr-w3 clr-pos3">
    <img src="../../../assets/images/color-circle1.png" class="clr-circle clr-w4 clr-pos4">

     <img src="../../../assets/images/hdots.png" class="h-dot">
     <img src="../../../assets/images/banner-pic.jpg" class="bannerPic">
</div>
<div class="path-wrapper">
<div class="sectionList">

    <img src="../../../assets/images/color-circle1.png" class="clr-circle clr-w5 clr-pos5">

    <div class="grid">
        <div class="grid__item grid__item--md-span-6 padd-rt1">
            <h2>Lorem ipsum dolor sit, Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ad, sequi
            </h2>
            <ul>
                <li>Lorem ipsum dolor sit, Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ad, sequi</li>
                <li>Lorem ipsum dolor sit, Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ad, sequi</li>
                <li>Lorem ipsum dolor sit, Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ad, sequi</li>
                <li>Lorem ipsum dolor sit, Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ad, sequi</li>
                <li>Lorem ipsum dolor sit, Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ad, sequi</li>
                <li>Lorem ipsum dolor sit, Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ad, sequi</li>
                
            </ul>
     
    </div>
    <div class="grid__item grid__item--md-span-6">
        <img src="../../../assets/images/vector-pic1.png" class="vector-img">
       
    </div>
</div>
</div>
<div class="sectionFaq">

    <img src="../../../assets/images/color-circle1.png" class="clr-circle clr-w6 clr-pos6">
    <img src="../../../assets/images/color-circle1.png" class="clr-circle clr-w5 clr-pos7">
    <img src="../../../assets/images/color-circle1.png" class="clr-circle clr-w2 clr-pos8">


    <div class="sectionTitles">
        <h1>Frequently Asked Questions</h1>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore </p>
    </div>

    <div class="grid">
        <div class="grid__item grid__item--md-span-4">
            <div class="grid_cards">
                <img src="../../../assets/images/faq-icons.png">
                <div class="card-space">
                <h6>What is job description?</h6>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, 
                        sed do eiusmod tempor incididunt ut labore et dolore magna
                        sed do eiusmod tempor i ncididunt ut labore et dolore magna</p>
                </div>
                    <a href="" class="learn-btn">LEARN MORE</a>

            </div>
        </div>
        <div class="grid__item grid__item--md-span-4">
            <div class="grid_cards">
                <img src="../../../assets/images/faq-icons.png">
                <div class="card-space">
                    <h6>What is job description?</h6>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, 
                        sed do eiusmod tempor incididunt ut Lorem ipsum dolor sit amet, consectetur adipisicing elit, 
                        sed do eiusmod tempor incididunt ut  labore et dolore magna</p>
                    </div>
                    <a href="" class="learn-btn">LEARN MORE</a>

            </div>
        </div>
        <div class="grid__item grid__item--md-span-4">
            <div class="grid_cards">
                <img src="../../../assets/images/faq-icons.png">
                <div class="card-space">
                    <h6>What is job description?</h6>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, 
                        sed do eiusmod tempor incididunt ut labore et dolore magna
                        sed do eiusmod tempor incidid unt ut labore et dolore magna</p>
                    </div>
                    <a href="" class="learn-btn">LEARN MORE</a>

            </div>
        </div>
        <div class="grid__item grid__item--md-span-12 text-center">
               <button href="" class="learn-btn2">LEARN MORE</button>
            </div>
    </div>

</div>
</div>
<div class="path-wrapper2">
<div class="sectionCompany">
    <img src="../../../assets/images/color-circle1.png" class="clr-circle clr-w5 clr-pos9">
    <div class="grid">
        <div class="grid__item grid__item--md-span-5">
            <img src="../../../assets/images/vector-pic2.png" class="vector-img">
        </div>
        <div class="grid__item grid__item--md-span-7">
            <div class="grid gridgap1">
                <div class="grid__item grid__item--xs-span-4"> 
                    <div class="countBox">
                        <h2>500+</h2>
                         <p>Companies</p>
                   </div>
                </div>
                <div class="grid__item grid__item--xs-span-4">
                     <div class="countBox">
                        <h2>500+</h2>
                        <p>Companies</p>
                   </div>   
                </div>
                <div class="grid__item grid__item--xs-span-4">
                    <div class="countBox">
                       <h2>500+</h2>
                       <p>Companies</p>
                  </div>   
               </div>
                    <div class="grid__item grid__item--xs-span-4">
                        <div class="countBox">
                        <h2>500+</h2>
                        <p>Companies</p>
                    </div>   
                </div>
                <div class="grid__item grid__item--xs-span-4">
                    <div class="countBox">
                    <h2>500+</h2>
                    <p>Companies</p>
                </div>   
              </div>
                <div class="grid__item grid__item--xs-span-4">
                    <div class="countBox">
                    <h2>500+</h2>
                    <p>Companies</p>
                </div>   
            </div>
            </div>
        </div>
    </div>
</div>

<div class="sectionClients">
    <div class="flex-fill"><img src="../../../assets/images/client-logo1.png"></div>
    <div class="flex-fill"><img src="../../../assets/images/client-logo2.png"></div>
    <div class="flex-fill"><img src="../../../assets/images/client-logo3.png"></div>
    <div class="flex-fill"><img src="../../../assets/images/client-logo4.png"></div>
    <div class="flex-fill"><img src="../../../assets/images/client-logo1.png"></div>
</div>

<div class="sectionConnect">

    <img src="../../../assets/images/color-circle1.png" class="clr-circle clr-w5 clr-pos10">
    <img src="../../../assets/images/color-circle1.png" class="clr-circle clr-w4 clr-pos11">

    <div class="cardBox">
        <div>
            <h1>Let’s get connected and<br>
                start creating <br>
                your job description.</h1>
        </div>
        <div class="d-center">
            <a href="" class="getStartBtn">Get started for free</a>
        </div>

    </div>
</div>

</div>
<footer class="footer-block">
    <div class="grid">
        <div class="grid__item grid__item--md-span-7"> 
            <div class="grid">
                <div class="grid__item grid__item--md-span-6"> 
            <img src="../../../assets/images/logo.png">
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, 
                sed do eiusmod tempor incididunt ut labore et dolore magna</p>
            </div>
        </div>
        </div>

        <div class="grid__item grid__item--md-span-5"> 
            <div class="grid">
                <div class="grid__item grid__item--sm-span-6">
                    <h5>Useful Links</h5> 
                    <ul class="footerMenu">
                        <li><a>Home</a></li>
                        <li><a>About</a></li>
                        <li><a>Create Jd</a></li>
                        <li><a>Blog</a></li>
                       
                    </ul>
                
                </div>
                <div class="grid__item grid__item--sm-span-6">
                    <h5>Follow Us</h5> 
                    <ul class="footerMenu">
                        <li><a>Facebook</a></li>
                        <li><a>Twitter</a></li>
                        <li><a>Instagram</a></li>
                        <li><a>Youtube</a></li>
                       
                    </ul>
                
                </div>
            </div>
        </div>
    </div>
    <div class="grid footer-bottom">
        <div class="grid__item grid__item--md-span-6"> 
            <p>Copyright2022.JD 101.All right reserved.</p>  </div>
        <div class="grid__item grid__item--md-span-6 text-right"> 
            <p>Created by Saihasoftware Technologiies Pvt Ltd.</p>  </div>
    </div>
    
</footer>
</div>