"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.USER_SESSION_API = exports.WORK_FLOW_API = exports.ROLE_API = exports.USER_INVITATION_API = exports.JOB_DESCRIPTION_REQUEST_API = exports.JOB_DESCRIPTION_API = exports.SKILL_API = exports.JOBTITLE_API = exports.JOBLEVELS_API = exports.JOBFAMILY_API = exports.QUALIFICATION_API = exports.INDUSTRY_API = exports.ORGANISATION_API = exports.LINKEDIN_API = exports.SIGNUP_API = exports.LOGIN_API = exports.USER_API = exports.BASE_URI = void 0;
exports.BASE_URI = '/api';
exports.USER_API = '/users';
exports.LOGIN_API = '/login';
exports.SIGNUP_API = '/signup';
exports.LINKEDIN_API = '/linkedin';
exports.ORGANISATION_API = '/organisation';
exports.INDUSTRY_API = '/industry';
exports.QUALIFICATION_API = '/qualification';
exports.JOBFAMILY_API = '/family';
exports.JOBLEVELS_API = '/jobLevels';
exports.JOBTITLE_API = '/jobTitle';
exports.SKILL_API = '/skill';
exports.JOB_DESCRIPTION_API = '/jd';
exports.JOB_DESCRIPTION_REQUEST_API = '/jd-request';
exports.USER_INVITATION_API = '/user-invitation';
exports.ROLE_API = '/role';
exports.WORK_FLOW_API = '/workFlow';
exports.USER_SESSION_API = '/session';
