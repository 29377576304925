/* eslint-disable id-blacklist */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable dot-notation */
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { BASE_URI, INDUSTRY_API } from '@atme/serverAPI';
import { IndustryS } from '@atme/entity/industry';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class IndustryService {

  constructor(
    private readonly http: HttpClient
  ) { }

  createIndustry(industry: IndustryS): Observable<any> {

    return this.http.post<IndustryS>(`${BASE_URI}${INDUSTRY_API}`, industry).pipe(
      catchError((err) => throwError(err))
    );

  }

  updateIndustry(industry: IndustryS, industryId: string): Observable<any> {

    return this.http.put<IndustryS>(`${BASE_URI}${INDUSTRY_API}`, { params: industry,
      industryId }).pipe(
      catchError((err) => throwError(err))
    );

  }

  deleteIndustryById(industryId: any): Observable<unknown> {

    return this.http.delete(`${BASE_URI}${INDUSTRY_API}`, { params: industryId }).pipe(
      catchError((err) => throwError(err))
    );

  }

  fetchIndustryList(): Observable<any> {

    return this.http.get(`${BASE_URI}${INDUSTRY_API}`).pipe(
      catchError((err) => throwError(err))
    );

  }

  fetchIndustryById(data: any): Observable<any> {

    return this.http.get(`${BASE_URI}${INDUSTRY_API}/edit`, { params: data }).pipe(
      catchError((err) => throwError(err))
    );

  }

}
