/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-lone-blocks */
/* eslint-disable dot-notation */
/* eslint-disable prefer-named-capture-group */
/* eslint-disable require-unicode-regexp */
/* eslint-disable id-blacklist */
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarService } from 'src/app/helpers/snackbar.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { UserService } from '@atmeservices/user.service';
import { InvitationManagementService } from '@atmeservices/invitation-management.service';
import { TranslateService } from "@ngx-translate/core";
import { CommunicationService } from '@atmeservices/communication.service';

const resizeWidth = 425;
const columnNumber2 = 2;
const columnNumber1 = 1;

@Component({
  selector: 'app-invite-dialoge',
  templateUrl: './invite-dialoge.component.html',
  styleUrls: ['./invite-dialoge.component.scss']
})
export class InviteDialogeComponent implements OnInit {

  breakpoint: any;

  regForm: FormGroup;

  email: any = [];

  validEmail: boolean;

  emailError;

  emailExistError: boolean;

  inputFieldNullError = false;

  // Mat-chips
  visible = true;

  selectable = true;

  removable = true;

  addOnBlur = true;

  loading = false;

  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  component: any;

  isInvite = false;

  isLinkShare = false;

  linkValue: '';

  constructor(
    public dialogRef: MatDialogRef<InviteDialogeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private _snackBar: SnackbarService,
    private cdref: ChangeDetectorRef,
    private communicationService: CommunicationService,
    private readonly userService: UserService,
    private readonly invitationService: InvitationManagementService,
  ) {
    this.component = data;

    if (data.name == 'invite') {
      this.isInvite = true;
    }

  }

  ngAfterContentChecked() {

    this.cdref.detectChanges();

  }

  langC: string = "";
  inviteTitle: string = "invite.inviteComp-title";
  invitelabel: string = "invite.inviteComp-label";
  inviteplaceHolder: string = "invite.inviteComp-placeHolder";
  inviteinvalidError: string = "invite.inviteComp-invalidError";
  inviteuserExistError: string = "invite.inviteComp-userExistError";
  inviteNullError: string = "invite.inviteComp-NullError";
  inviteBtnText: string = "invite.inviteComp-BtnText";
  invitesuccessMsg: string = "invite.inviteComp-successMsg";
  inviteerrorMsg: string = "invite.inviteComp-errorMsg";
  shareJdTitle: string = "shareJd.shareJd-title"
  shareToolTip: string = "shareJd.shareJd-copyToolTip"


  ngOnInit(): void {
    this.shareLink()
    this.breakpoint = innerWidth <= resizeWidth ? columnNumber1 : columnNumber2;
    this.createForm();
    this.communicationService.getLanguage().subscribe((value) => {
      this.onLanguageChange(value)
    })
  }

  onLanguageChange(lang: string) {
    this.langC = lang;
    this.translate.use(lang);
  }

  shareLink() {
    if (this.component.name == 'linkshare') {
      this.isLinkShare = true;
      this.linkValue = this.component.link;
    }

  }

  /* To copy Text from Textbox */
  copyInputMessage(inputElement) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
  }

  // Window resize for login divisions
  onResize(event): void {

    this.breakpoint = event.target.innerWidth <= resizeWidth ? columnNumber1 : columnNumber2;

  }

  createForm(): void {

    this.regForm = this.formBuilder.group({
      email: new FormArray([]),
    });

  }

  // Remove Email
  removeChips(name): void {

    const emailArr = this.regForm.get('email') as FormArray;
    emailArr.removeAt(this.regForm.get('email').value.findIndex((email) => email === name));
    const index = this.email.indexOf(name);
    if (index >= 0) {

      this.email.splice(index, 1);

    }

  }

  // Add New Email
  addChips(event: MatChipInputEvent): void {

    const value = (event.value || '').trim();
    if (value) {

      const validateEmail = this.validateEmail(value);

      this.validEmail = validateEmail ? false : true;
      this.emailExistError = validateEmail ? false : true;
      if (validateEmail) {

        this.userService.isUserExist(value).subscribe((message) => {

          if (message['message'] === 'no_user') {

            {

              this.email.push(value);
              this.emailExistError = false;

            }
            const control = <FormArray>this.regForm.get('email');
            control.push(this.formBuilder.control(event.value));

          } else if (message['message'] === 'exist') {

            this.emailError = 'User Already Exist';
            this.emailExistError = true;

          }


        });

        // eslint-disable-next-line no-lone-blocks

      }


    }
    if (event.input) {

      event.input.value = '';

    }

  }


  // eslint-disable-next-line class-methods-use-this
  validateEmail(email: string): boolean {

    const newLocal = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const regularExpression = newLocal;
    return regularExpression.test(String(email).toLowerCase());

  }

  sendInvitation(): void {

    if (this.regForm.valid) {

      this.loading = true;
      this.inputFieldNullError = false;
      const signupLink = location.href.split('home')[0].concat('signup');

      const invitationData = {

        emailArray: this.regForm.value.email,
        link: signupLink

      };

      // this.translate.

      this.invitationService.sendInvitation(invitationData).subscribe((message) => {

        this.loading = false;
        if (message['message'] === 'success') {
          this._snackBar.openSnackBar(this.translate.instant(this.invitesuccessMsg), 'success');
          this.dialogRef.close(true);

        } else {

          this.loading = false;
          this._snackBar.openSnackBar(this.translate.instant(this.inviteerrorMsg), 'danger');

        }

      });

    } else {

      this.inputFieldNullError = true;

    }


  }





}
