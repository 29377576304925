/* eslint-disable id-blacklist */
/* eslint-disable no-extra-parens */
/* eslint-disable dot-notation */
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SnackbarService } from 'src/app/helpers/snackbar.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { IndustryService } from '../../services/industry.service';
import { IndustryManagementDialogeComponent } from './industry-management-dialoge/industry-management-dialoge.component';
import { CustomDialogComponent } from '../../components/custom-dialog/custom-dialog.component';
import { DialogBoxComponent } from '../../components/dialog-box/dialog-box.component';
import { TranslateService } from '@ngx-translate/core';
import { CommunicationService } from '@atmeservices/communication.service';

@Component({
  selector: 'app-industry-management',
  templateUrl: './industry-management.component.html',
  styleUrls: ['./industry-management.component.scss']
})
export class IndustryManagementComponent implements OnInit {

  displayedColumns: string[] = ['name','status' , 'action'];

  dataSource = new MatTableDataSource<any>();



  @ViewChild(MatPaginator) paginator!: MatPaginator;

  @ViewChild(MatSort) sort!: MatSort;

  loading = false;

  industryData: any = [];

  constructor(
    public dialog: MatDialog,
    private _snackBar: SnackbarService,
    private translate: TranslateService,
    private communicationService: CommunicationService,
    private readonly industryService: IndustryService,
    private router: Router) { }

  ngOnInit(): void {

    this.fetchIndustryList();
    this.communicationService.getLanguage().subscribe((value) => {
      this.onLanguageChange(value)
    })

  }
  onLanguageChange(lang: string) {
    this.langC = lang;
    this.translate.use(lang);
  }

  langC: string = "";
  title: string = "industry.title";
  addBtn: string = "industry.addBtn";
  addBtnToolTip: string = "addBtnToolTip";
  search: string = "tableSearchPlaceHolder";
  label1: string = "industry.labe1";
  label2: string = "industry.labe2";
  tableNullData: string = "nullData-table-error";
  viewToolTip: string = "viewToolTip";
  editToolTip: string = "editToolTip";
  deleteToolTip: string = "deleteToolTip";
  deleteDialogTitle: string = "industry.deleteTitle";
  deleteDialogMsg1: string = "industry.deleteMsg1";
  deleteDialogMsg2: string = "industry.deleteMsg2";
  deleteSuccess: string = "industry.successMsg";
  deleteError: string = "industry.errorMsg";
  paginatorLabel: string = "paginatorLabel";
  successMessage = this.translate.instant(this.deleteSuccess);

  errorMessage = this.translate.instant(this.deleteError);


  // Search Industry
  applyFilter(event: Event): void {

    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {

      this.dataSource.paginator.firstPage();

    }

  }

  // Fetch all Industry records
  fetchIndustryList(): void {

    this.loading = true;

    this.industryService.fetchIndustryList().subscribe((message) => {

      this.loading = false;
      this.industryData = message;

      this.dataSource = new MatTableDataSource(this.industryData);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

    }, (error) => {

      this.loading = false;
      console.error(error);

    });

  }

  // View Industry
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  viewIndustry(data): void {

    const industryData = {
      'Industry Name': data?.name,
    };
    const industryDataObj = {
      'data': industryData,
      'title': 'Industry Details',
      'image': data?.image,
    };
    this.dialog.open(CustomDialogComponent, { data: industryDataObj });

  }

  // Edit Industry
  editIndustry(industryId: string): void {

    const dialogRef = this.dialog.open(IndustryManagementDialogeComponent, { data: industryId });
    dialogRef.afterClosed().subscribe((result) => {

      if (result) {

        this.fetchIndustryList();

      }

    });

  }

  // Delete Industry
  deleteIndustry(data): void {

    const confirmDialog = this.dialog.open(DialogBoxComponent, {
      data: {
        title: this.translate.instant(this.deleteDialogTitle),
        message: `${this.translate.instant(this.deleteDialogMsg1)} "${data?.name}" ${this.translate.instant(this.deleteDialogMsg2)}`
      }
    });
    confirmDialog.afterClosed().subscribe((result) => {

      if (result === true) {

        this.industryService.deleteIndustryById(data).subscribe((message) => {

          if (message['message'] === 'success') {

            this._snackBar.openSnackBar(this.successMessage, 'success');
            this.fetchIndustryList();

          } else {

            this._snackBar.openSnackBar(this.errorMessage, 'danger');

          }
          this.loading = false;

        }, (error) => {

          console.error(error);
          this._snackBar.openSnackBar(this.errorMessage, 'danger');
          this.loading = false;

        });


      }

    });

  }

  createIndustry(): void {

    const dialogRef = this.dialog.open(IndustryManagementDialogeComponent);
    // Create subscription
    dialogRef.afterClosed().subscribe((result) => {

      if (result) {

        this.fetchIndustryList();

      }

    });

  }

}
