
<div class="contactPage">
    <nav class="topHeader">
       
            <div>
                <img src="../../../assets/images/logo.png" class="headerLogo">
            </div>
            <div>
                <ul class="topMenu">
                    <li><a>Home</a></li>
                    <li><a [routerLink]="['/']">About</a></li>
                    <li><a>Create Jd</a></li>
                    <li [routerLink]="['/blog']"><a>Blog</a></li>
                    <li [routerLink]="['/contact']"><a>Contact</a></li>
                    <li [routerLink]="['/signup']"><a class="signupBtn">Signup</a></li>
                </ul>
            </div>
      
    </nav>
    
<div class="path-wrapper3">

    <img src="../../../assets/images/color-circle1.png" class="clr-circle clr-w2 clr-pos12">
    <img src="../../../assets/images/color-circle1.png" class="clr-circle clr-w3 clr-pos13">

<div class="pageTitles2"> 
    <div class="grid">
        <div class="grid__item grid__item--xs-span-12">
        <h1>Get in touch with us</h1>
    </div>
</div>

</div>

<div class="contactCard">
    <img src="../../../assets/images/color-circle1.png" class="clr-circle clr-w7 clr-pos14">
    <div class="grid">
        <div class="grid__item grid__item--md-span-7">
            <div class="grid contactForm">
                <h3>Drop us a line</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, 
                    sed do eiusmod tempor incididunt</p>

                <div class="grid__item grid__item--xs-span-6">
                    <mat-form-field appearance="fill" class="pr-15">
                        <mat-label>Name</mat-label>
                        <input matInput>
                      </mat-form-field>
                </div>
                <div class="grid__item grid__item--xs-span-6">
                    <mat-form-field appearance="fill" class="pl-15">
                        <mat-label>Email</mat-label>
                        <input matInput>
                      </mat-form-field>
                </div>
                <div class="grid__item grid__item--xs-span-12">
                    <mat-form-field appearance="fill">
                        <mat-label>Message</mat-label>
                        <textarea matInput></textarea>
                      </mat-form-field>
                </div>
                <button mat-flat-button color="primary" class="submit-btn">Send</button>

            </div>
        </div>
        <div class="grid__item grid__item--md-span-5">
            <div class="contactInfo">
                  <h3>Contact</h3>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, 
                    sed do eiusmod tempor incididunt</p>

                    <h3><mat-icon> phone</mat-icon> Call Us</h3>
                    <p>+014599 848 548</p>

                    <h3><mat-icon>mail</mat-icon> Email Us</h3>
                    <p>jobdescription@gmail.com</p>
            </div>
        </div>
    </div>

</div>

<div class="pageTitles3 text-center"> 
    <img src="../../../assets/images/vdots.png" class="v-dot">
      <h1>Locate us</h1>
</div>
<img src="../../../assets/images/color-circle1.png" class="clr-circle clr-w7 clr-pos15">
</div>
<div class="mapArea">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3929.037099531065!2d76.32841241461557!3d10.01379429284173!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b080cf735b55abf%3A0xd2222dec71491f35!2sHeavenly%20Plaza%2C%20Civil%20Line%20Rd%2C%20Chembumukku%2C%20Edappally%2C%20Kakkanad%2C%20Kerala%20682021!5e0!3m2!1sen!2sin!4v1648394096538!5m2!1sen!2sin" width="100%" height="400" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
</div>

<footer class="footer-block">
    <div class="grid">
        <div class="grid__item grid__item--md-span-7"> 
            <div class="grid">
                <div class="grid__item grid__item--md-span-6"> 
            <img src="../../../assets/images/logo.png">
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, 
                sed do eiusmod tempor incididunt ut labore et dolore magna</p>
            </div>
        </div>
        </div>

        <div class="grid__item grid__item--md-span-5"> 
            <div class="grid">
                <div class="grid__item grid__item--sm-span-6">
                    <h5>Useful Links</h5> 
                    <ul class="footerMenu">
                        <li><a>Home</a></li>
                        <li><a>About</a></li>
                        <li><a>Create Jd</a></li>
                        <li><a>Blog</a></li>
                       
                    </ul>
                
                </div>
                <div class="grid__item grid__item--sm-span-6">
                    <h5>Follow Us</h5> 
                    <ul class="footerMenu">
                        <li><a>Facebook</a></li>
                        <li><a>Twitter</a></li>
                        <li><a>Instagram</a></li>
                        <li><a>Youtube</a></li>
                       
                    </ul>
                
                </div>
            </div>
        </div>
    </div>
    <div class="grid footer-bottom">
        <div class="grid__item grid__item--md-span-6"> 
            <p>Copyright2022.JD 101.All right reserved.</p>  </div>
        <div class="grid__item grid__item--md-span-6 text-right"> 
            <p>Created by Saihasoftware Technologiies Pvt Ltd.</p>  </div>
    </div>
    
</footer>
</div>