import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
// import { UsersessionService } from '@atmeservices/usersession.service';
import { AccountService } from '../services/account.service';
import { catchError } from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private accountService: AccountService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add auth header with jwt if user is logged in and request is to the api url
        const token = this.accountService.userValue;

        if (token ) {
            request = request.clone({
                setHeaders: {
                    Authorization: `${token}`
                    // Authorization: `Bearer ${token}`
                }
            });
        }
        // return next.handle(request);
        return next.handle(request).pipe(catchError(err => {
            if ([401, 403, 500].includes(err.status) && this.accountService.userValue) {
                // auto logout if 401 or 403 response returned from api
                this.accountService.logout();
            
                 
            }

            const error = err.error?.message || err.statusText;
            console.error(err);
            return throwError(error);
        }))
    }
}