import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class CommunicationService {

  private invitationValue = new BehaviorSubject<boolean>(false);
  private organisationValue = new BehaviorSubject<boolean>(false);
  private profileValue = new BehaviorSubject<boolean>(false);
  private languageSelect = new BehaviorSubject<string>('en');

  constructor() { }


  setValueForInvitation(value: boolean): void {

    this.invitationValue.next(value);

  }

  getValueForInvitation(): Observable<boolean> {

    return this.invitationValue.asObservable();

  }

  setValueForOrganisation(value: boolean): void {

    this.organisationValue.next(value);

  }

  getValueForOrganisation(): Observable<boolean> {

    return this.organisationValue.asObservable();

  }

  setValueForProfile(value: boolean): void {

    this.profileValue.next(value);

  }

  getValueForProfile(): Observable<boolean> {

    return this.profileValue.asObservable();

  }
  setLanguage(value: string): void {

    this.languageSelect.next(value);

  }

  getLanguage(): Observable<string> {
    const localLang = localStorage.getItem('language')
    localLang ? this.languageSelect.next(localLang) : ''
    return this.languageSelect.asObservable();

  }


}
