/* eslint-disable id-blacklist */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable dot-notation */
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { BASE_URI, JOBFAMILY_API } from '@atme/serverAPI';
import { FamilyS } from '@atme/entity/jobFamily';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class JobFamilyService {

  constructor(
    private readonly http: HttpClient
  ) { }

  createJobFamily(jobFamily: FamilyS): Observable<any> {

    return this.http.post<FamilyS>(`${BASE_URI}${JOBFAMILY_API}`, jobFamily).pipe(
      catchError((err) => throwError(err))
    );

  }

  updateJobFamily(jobFamily: FamilyS, jobFamilyId: string): Observable<any> {

    return this.http.put<FamilyS>(`${BASE_URI}${JOBFAMILY_API}`, { params: jobFamily,
      jobFamilyId }).pipe(
      catchError((err) => throwError(err))
    );

  }

  deleteJobFamilyById(familyId: any): Observable<unknown> {

    return this.http.delete(`${BASE_URI}${JOBFAMILY_API}`, { params: familyId }).pipe(
      catchError((err) => throwError(err))
    );

  }

  fetchJobFamilyList(): Observable<any> {

    return this.http.get(`${BASE_URI}${JOBFAMILY_API}`).pipe(
      catchError((err) => throwError(err))
    );

  }

  fetchJobFamilyById(data: any): Observable<any> {

    return this.http.get(`${BASE_URI}${JOBFAMILY_API}/edit`, { params: data }).pipe(
      catchError((err) => throwError(err))
    );

  }

}
