

<div class="blogPage">
    <nav class="topHeader">
       
            <div>
                <img src="../../../assets/images/logo.png" class="headerLogo">
            </div>
            <div>
                <ul class="topMenu">
                   
                    <li><a>Home</a></li>
                    <li><a [routerLink]="['']">About</a></li>
                    <li><a>Create Jd</a></li>
                    <li [routerLink]="['/blog']"><a>Blog</a></li>
                    <li [routerLink]="['/contact']"><a>Contact</a></li>
                    <li [routerLink]="['/signup']"><a class="signupBtn">Signup</a></li>
                </ul>
            </div>
      
    </nav>
    

<div class="pageTitles4"> 

    <img src="../../../assets/images/vdots.png" class="v-dot">
    <h1>Blog & <br>News</h1>
</div>

<div class="blogCarousel">

    <mat-tab-group mat-align-tabs="end">
        <mat-tab label="Peoples"> 
            
         <div class="blogSliderItem">
            <div class="grid">
                <div class="grid__item grid__item--sm-span-7">
                    <img src="../../../assets/images/banner-pic.jpg" class="sliderPic">
                </div>
                <div class="grid__item grid__item--sm-span-5">
                    <div class="contentDivs">
                    <h1>Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis aliquid, 
                        a amet culpa expedita reiciendis, qui ullam quis in consequuntur eum 
                        incidunt vitae nemo obcaecati id quod optio beatae sed?</h1>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore rerum 
                            est commodi ea numquam mollitia? Et ducimus aspernatur aliquid eius nihil. 
                            Omnis illo esse minus repellendus cum doloremque soluta. Ex?</p>
                            </div>
                            <div class="authorInfo">
                                <img src="../../../assets/images/user-pic1.png">
                                <h4>Mikhael Richard</h4>
                                <p>Feb 2, 2022</p>
                            </div>
                </div>
           </div>



         </div>
        </mat-tab>
        <mat-tab label="Product"> Content 2 </mat-tab>
        <mat-tab label="Technologies"> Content 3 </mat-tab>
        <mat-tab label="Design"> Content 2 </mat-tab>
        <mat-tab label="Startup"> Content 3 </mat-tab>
      </mat-tab-group>
</div>
<div class="path-wrapper4">
    <img src="../../../assets/images/path-bg5.png" class="path-bg1">

<div class="blogGridColmns">

    <img src="../../../assets/images/color-circle1.png" class="clr-circle clr-w3 clr-pos16">
    <img src="../../../assets/images/color-circle1.png" class="clr-circle clr-w3 clr-pos17">
    
    <img src="../../../assets/images/color-circle1.png" class="clr-circle clr-w4 clr-pos18">
    <div class="grid">
        <div class="grid__item grid__item--sm-span-4">
            <img src="../../../assets/images/blog-pic1.jpg" class="blogPic">
            <div class="contentDivs2">
                <h3>Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis aliquid, 
                    a amet culpa  obcaecati id quod optio beatae sed?</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore rerum 
                        est commodi ea numquam mollitia? Et ducimus aspernatur aliquid eius nihil. 
                        Omnis illo esse minus repellendus cum doloremque soluta. Ex?</p>
                        </div>
                        <div class="authorInfo">
                            <img src="../../../assets/images/user-pic1.png">
                            <h4>Mikhael Richard</h4>
                            <p>Feb 2, 2022</p>
                 </div>
        </div>

        <div class="grid__item grid__item--sm-span-4">
            <img src="../../../assets/images/blog-pic2.jpg" class="blogPic">
            <div class="contentDivs2">
                <h3>Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis aliquid, 
                    a amet culpa  obcaecati id quod optio beatae sed?</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore rerum 
                        est commodi ea numquam mollitia? Et ducimus aspernatur aliquid eius nihil. 
                        Omnis illo esse minus repellendus cum doloremque soluta. Ex?</p>
                        </div>
                        <div class="authorInfo">
                            <img src="../../../assets/images/user-pic1.png">
                            <h4>Mikhael Richard</h4>
                            <p>Feb 2, 2022</p>
                 </div>
        </div>

        <div class="grid__item grid__item--sm-span-4">
            <img src="../../../assets/images/blog-pic3.jpg" class="blogPic">
            <div class="contentDivs2">
                <h3>Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis aliquid, 
                    a amet culpa  obcaecati id quod optio beatae sed?</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore rerum 
                        est commodi ea numquam mollitia? Et ducimus aspernatur aliquid eius nihil. 
                        Omnis illo esse minus repellendus cum doloremque soluta. Ex?</p>
                        </div>
                        <div class="authorInfo">
                            <img src="../../../assets/images/user-pic1.png">
                            <h4>Mikhael Richard</h4>
                            <p>Feb 2, 2022</p>
                 </div>

        </div>

    </div>
</div>
<div class="blogTutorialSlider">
    
<div class="pageTitles5 text-center"> 
      <h1>Guides & Tutorials</h1>
</div>

<div class="grid">
    <div class="grid__item grid__item--sm-span-7">
        <img src="../../../assets/images/blog-pic4.jpg" class="sliderPic">
    </div>
    <div class="grid__item grid__item--sm-span-5">
        <div class="contentDivs">
        <h1>Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis aliquid, 
            a incidunt vitae nemo obcaecati id quod optio beatae sed?</h1>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore rerum 
                est commodi ea numquam mollitia? Et ducimus aspernatur aliquid eius nihil. 
                Omnis illo esse minus repellendus cum doloremque solutast commodi ea numquam 
                mollitia? Et ducimus aspernatur aliquid eius nihil. Omnis illo esse minus 
                repellendus cum doloremque soluta.</p>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore rerum 
                    est commodi ea numquam mollitia? Et ducimus aspernatur aliquid eius nihil. 
                    Omnis illo esse minus repellendus cum doloremque solutast commodi ea numquam 
                    mollitia? Et ducimus</p>
         </div>
           
    </div>
</div>

</div>

<div class="blogTutorialSlider2">
    
    <div class="grid">
        <div class="grid__item grid__item--sm-span-5">
            <div class="contentDivs">
            <h1>Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis aliquid, 
                a incidunt vitae nemo obcaecati id quod optio beatae sed?</h1>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore rerum 
                    est commodi ea numquam mollitia? Et ducimus aspernatur aliquid eius nihil. 
                    Omnis illo esse minus repellendus cum doloremque solutast commodi ea numquam 
                    mollitia? Et ducimus aspernatur aliquid eius nihil. Omnis illo esse minus 
                    repellendus cum doloremque soluta.</p>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore rerum 
                        est commodi ea numquam mollitia? Et ducimus aspernatur aliquid eius nihil. 
                        Omnis illo esse minus repellendus cum doloremque solutast commodi ea numquam 
                        mollitia? Et ducimus</p>
             </div>
               
        </div>
        <div class="grid__item grid__item--sm-span-7">
            <img src="../../../assets/images/blog-pic5.jpg" class="sliderPic">
        </div>
       
    </div>
    
    </div>
</div>
<div class="path-wrapper5">
    <div class="blogVideoSection">
               
        <video width="100%" controls>
            <source src="https://www.youtube.com/embed/rrT6v5sOwJg" type="video/mp4">
            <source src="https://www.youtube.com/embed/rrT6v5sOwJg" type="video/ogg">
            </video>
            <button mat-flat-button color="primary" class="playBtn"><img src="../../../assets/images/play-icon.png"></button>
     </div>
  
     <div class="blogApiSection">

        <img src="../../../assets/images/color-circle1.png" class="clr-circle clr-w3 clr-pos19">
        <img src="../../../assets/images/color-circle1.png" class="clr-circle clr-w2 clr-pos20">
        <img src="../../../assets/images/color-circle1.png" class="clr-circle clr-w5 clr-pos21">
        <div class="pageTitles5 text-center"> 
            <h1>API'S</h1>
      </div>      
    
        <div class="grid">
            <div class="grid__item grid__item--sm-span-6">
                <img src="../../../assets/images/vector-pic3.png" class="sliderPic">
            </div>
            <div class="grid__item grid__item--sm-span-6">
                <div class="contentDivs">
                <h1>Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis aliquid, 
                    a incidunt vitae nemo obcaecati id quod optio beatae sed?</h1>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore rerum 
                        est commodi ea numquam mollitia? Et ducimus aspernatur aliquid eius nihil. 
                        Omnis illo esse minus repellendus cum doloremque solutast commodi ea numquam 
                        mollitia? Et ducimus aspernatur aliquid eius nihil. Omnis illo esse minus 
                        repellendus cum doloremque soluta.
                        commodi ea numquam mollitia? Et ducimus aspernatur aliquid eius nihil. 
                        Omnis illo esse minus repellendus cum doloremque solutast commodi ea numquam 
                        mollitia? Et ducimus aspernatur aliquid eius nihil. Omnis illo esse minus 
                        repellendus cum doloremque soluta.</p>
                 </div>
                   
            </div>

        </div> 
    </div>

    <div class="sectionConnect">

        <img src="../../../assets/images/color-circle1.png" class="clr-circle clr-w5 clr-pos10">
    
        <div class="cardBox">
            <div>
                <h1>Let’s get connected and<br>
                    start creating <br>
                    your job description.</h1>
            </div>
            <div class="d-center">
                <a href="" class="getStartBtn">Get started for free</a>
            </div>
    
        </div>
    </div>
</div>

<footer class="footer-block">
    <div class="grid">
        <div class="grid__item grid__item--md-span-7"> 
            <div class="grid">
                <div class="grid__item grid__item--md-span-6"> 
            <img src="../../../assets/images/logo.png">
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, 
                sed do eiusmod tempor incididunt ut labore et dolore magna</p>
            </div>
        </div>
        </div>

        <div class="grid__item grid__item--md-span-5"> 
            <div class="grid">
                <div class="grid__item grid__item--sm-span-6">
                    <h5>Useful Links</h5> 
                    <ul class="footerMenu">
                        <li><a>Home</a></li>
                        <li><a>About</a></li>
                        <li><a>Create Jd</a></li>
                        <li><a>Blog</a></li>
                       
                    </ul>
                
                </div>
                <div class="grid__item grid__item--sm-span-6">
                    <h5>Follow Us</h5> 
                    <ul class="footerMenu">
                        <li><a>Facebook</a></li>
                        <li><a>Twitter</a></li>
                        <li><a>Instagram</a></li>
                        <li><a>Youtube</a></li>
                       
                    </ul>
                
                </div>
            </div>
        </div>
    </div>
    <div class="grid footer-bottom">
        <div class="grid__item grid__item--md-span-6"> 
            <p>Copyright2022.JD 101.All right reserved.</p>  </div>
        <div class="grid__item grid__item--md-span-6 text-right"> 
            <p>Created by Saihasoftware Technologiies Pvt Ltd.</p>  </div>
    </div>
    
</footer>
</div>