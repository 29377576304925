<mat-icon class="model-close-icon" mat-dialog-close>close</mat-icon>
<div class="model-header">
    <h2 *ngIf="!detailToShow" class="mat-dialog-title"> {{data['title']}} Details</h2>
    <h2 *ngIf="detailToShow" class="mat-dialog-title"> {{data['title']}}</h2>
</div>

<mat-dialog-content class="mat-typography">

    <mat-grid-list [cols]="breakpoint" rowHeight="2:.3" (window:resize)="onResize($event)">


        <mat-card *ngIf="imageToShow" class="ship_image-wrapper">
            <img id="ship-image" mat-card-image [src]="picurl ? picurl : '../../../../assets/images/avathar.png'">
        </mat-card>

        <div *ngFor="let link of userData" class="model-item-div">
      
            <table>
                <tr class="tr">
                    <td class="key">{{ link.type }}</td>
                    <td class="colen">:</td>
                    <td class="value">{{ link.value }}</td>
                </tr>
            </table>

        </div>
    </mat-grid-list>


</mat-dialog-content>
<mat-dialog-actions align="center">
    <button mat-button mat-dialog-close class="button-close" data-backdrop="static" data-keyboard="false">Close</button>
</mat-dialog-actions>