/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UserS } from '@atme/entity/User';
import { BASE_URI, USER_API, SIGNUP_API, LOGIN_API } from '@atme/serverAPI';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private readonly http: HttpClient
  ) { }

  signUp(user: UserS): Observable<unknown> {

    return this.http.post<UserS>(`${BASE_URI}${USER_API}${SIGNUP_API}`, user).pipe(
      catchError((err) => throwError(err))
    );

  }

  login(user: { email: string, password: string }): Observable<unknown> {

    return this.http.post(`${BASE_URI}${USER_API}${LOGIN_API}`, user).pipe(
      catchError((err) => throwError(err))
    );

  }

  updateUserById(user: any): Observable<unknown> {

    return this.http.put(`${BASE_URI}${USER_API}`, { params: user }).pipe(
      catchError((err) => throwError(err))
    );

  }

  fetchLoginuser(): Observable<any> {

    return this.http.get(`${BASE_URI}${USER_API}/loginUser`).pipe(
      catchError((err) => throwError(err))
    );

  }
  fetchLoginuserRole(): Observable<any> {

    return this.http.get(`${BASE_URI}${USER_API}/loginUserRole`).pipe(
      catchError((err) => throwError(err))
    );

  }

  changePassword(userData: any): Observable<unknown> {

    return this.http.put(`${BASE_URI}${USER_API}/changepass`, userData).pipe(
      catchError((err) => throwError(err))
    );

  }

  isUserPasswordExistOrNot(): Observable<unknown> {

    return this.http.get(`${BASE_URI}${USER_API}/password-exist`).pipe(
      catchError((err) => throwError(err))
    );

  }

  forgotPassword(userData:any): Observable<unknown> {

    return this.http.put(`${BASE_URI}${USER_API}/forgotpass`, {params: userData}).pipe(
      catchError((err) => throwError(err))
    );

  }

  isUserExist(email: string): Observable<unknown> {

    return this.http.put(`${BASE_URI}${USER_API}/exist`, {params: email}).pipe(
      catchError((err) => throwError(err))
    );

  }

  sendInvitation(invitationData: any): Observable<unknown> {

    return this.http.post(`${BASE_URI}${USER_API}/invite`, {params: invitationData}).pipe(
      catchError((err) => throwError(err))
    );

  }

}
