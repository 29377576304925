<div [class.overlay]="loading" [class.spinner]="loading" (click)="$event.stopPropagation();"></div>

<div class="main-container">

    <div class="jdHeader">
        <div class="colLeft">

            <button class="backBtnNew"  mat-flat-button color="primary">
                <img src="../../../assets/images/arrow-left.png"></button>
            <ul class="breadcrumbs">
                <li>Create JD</li>
                <li><a href="">Industry</a></li>
            </ul>
            <h1>Choose your<br> industry.</h1>
        </div>
        <div class="colRight">
            <div class="cardProgress">
                <div><h3>You need to follow 5 steps <br>
                    to complete your JD</h3>
                   <h5>10% progress</h5>
              </div>
              <div>
                <div class="progress-circle p10">
                    <span>10%</span>
                    <div class="left-half-clipper">
                       <div class="first50-bar"></div>
                       <div class="value-bar"></div>
                    </div>
                 </div>
              </div>

            </div>
            <form class="search-box2">
                <mat-form-field class="search-full-width" appearance="none">
                    <mat-icon matSuffix>search</mat-icon>
                    <div class="search-box">
                        <input type="text" matInput placeholder="Search" #input>
                    </div>
                </mat-form-field>
            </form>
        </div>
    </div>

    <!-- Industry -->
    <div *ngIf="steps.industry" class="industry-container">

        <mat-card class="example-card">

            <div>
                <h2>{{"createJd.title1" | translate}}</h2>
            </div>
            <mat-card-content>

                <div class="industry-list-div">
                    <div [ngClass]="{'selectIndustry': selectedIndustry['_id'] == industry['_id']}"
                        (click)="selectIndustry(industry)" class="industry-list boxCard"
                        *ngFor="let  industry of industryDataToShow" matTooltip="{{industry.name}}">
                        <img class="boxIcon" id="display-image" [src]="industry.image ? industry.image : 
                        '../../../../assets/images/education-icon.png'">
                        <p>{{industry.name}}</p>
                        <mat-icon class="selectBxIcon">check_circle</mat-icon>
                    </div>
                    <div *ngIf="showLoadMoreBtn" class="showMoreBtn-wrapper">
                        <button (click)="LoadMoreIndustry()" mat-button color="accent"
                            value="bold"><mat-icon>arrow_downward</mat-icon> {{"createJd.loadMore" | translate}}</button>
                    </div>
                </div>

                <!-- <div class="btnWrapper">
                    <button type="submit" (click)="nextBtnJobFamily()" mat-raised-button color="primary">Next</button>
                </div> -->

            </mat-card-content>
        </mat-card>


    </div>

    <!-- Job Family -->
    <div *ngIf="steps.jobFamily" class="industry-container">


        <mat-card class="example-card">

            <div class="pageHead">
                <button class="back-btn" (click)="backJobFamily()" mat-flat-button color="primary">
                    <mat-icon>arrow_back_ios</mat-icon>
                    {{"createJd.backBtn" | translate}}
                </button>
           
            <div class="title-div">
                <h2>{{"createJd.title2" | translate}}</h2>
            </div>
        </div>
            <mat-card-content>

                <div class="industry-list-div">
                    <div [ngClass]="{'selectIndustry': selectedjobFamily['_id'] == jobFamily['_id']}"
                        (click)="selectjobFamily(jobFamily)" class="industry-list boxCard"
                        *ngFor="let  jobFamily of jobFamilyDataToShow" matTooltip="{{jobFamily.name}}">
                        <img class="boxIcon" id="display-image" [src]="jobFamily.image ? jobFamily.image : 
                        '../../../../assets/images/education-icon.png'">
                        <mat-icon class="selectBxIcon">check_circle</mat-icon>
                        <p>{{jobFamily.name}}</p>
                    </div>

                    <div *ngIf="showLoadMoreBtnJobFamily" class="showMoreBtn-wrapper">
                        <button (click)="LoadMoreJobFamily()" mat-button color="accent" value="bold">{{"createJd.loadMore" | translate}}</button>
                    </div>

                </div>

                <!-- <div class="btnWrapper">
                    <button (click)="nextBtnJobLevel()" mat-raised-button color="primary">Next</button>
                </div> -->

            </mat-card-content>
        </mat-card>

    </div>

    <!-- Job Level -->
    <div *ngIf="steps.jobLevel" class="industry-container">


        <mat-card class="example-card">

            <div class="pageHead">
                <button class="back-btn" (click)="backJobLevel()" mat-flat-button color="primary">
                    <mat-icon>arrow_back_ios</mat-icon>
                    {{"createJd.backBtn" | translate}}
                </button>
           
            <div class="title-div">
                <h2>{{"createJd.title3" | translate}}</h2>
            </div>
        </div>
            <mat-card-content>

                <div class="industry-list-div">
                    <div [ngClass]="{'selectIndustry': selectedjobLevelData['_id'] == jobLevel['_id']}"
                        (click)="selectjobLevel(jobLevel)" class="industry-list boxCard"
                        *ngFor="let  jobLevel of jobLevelDataToShow" matTooltip="{{jobLevel.name}}">
                        <img class="boxIcon" id="display-image" [src]="jobLevel.image ? jobLevel.image : 
                        '../../../../assets/images/education-icon.png'">
                        <p>{{jobLevel.name}}</p>
                        <mat-icon class="selectBxIcon">check_circle</mat-icon>
                    </div>
                    <div *ngIf="showLoadMoreBtnJobLvl" class="showMoreBtn-wrapper">
                        <button (click)="LoadMoreJobLvl()" mat-button color="accent" value="bold">{{"createJd.loadMore" | translate}}</button>
                    </div>
                </div>

                <!-- <div class="btnWrapper">
                    <button (click)="nextBtnExperienceRange()" mat-raised-button color="primary">Next</button>
                </div> -->

            </mat-card-content>

        </mat-card>
    </div>

    <!-- Experience Level   -->
    <div *ngIf="steps.experienceRange">


        <mat-card class="qualification-card">

            <div class="pageHead">
                <button class="back-btn" (click)="backExperience()" mat-flat-button color="primary">
                    <mat-icon>arrow_back_ios</mat-icon>
                    {{"createJd.backBtn" | translate}}
                </button>
            </div>

            <mat-card-content>

                <div class="qualificationForm-wrapper">

                    <div class="title-div qualificationForm-wrapper-heading">
                        <!-- <div class="qualificationForm-wrapper-heading"> -->
                        <h3>{{"createJd.title4" | translate}}</h3>
                        <h3>{{"createJd.title4A" | translate}}</h3>
                    </div>

                    <form [formGroup]="regForm">
                        <div class="outer-form-container">

                            <!-- <mat-card-subtitle>User Information</mat-card-subtitle>
                            <hr> -->



                            <!-- Experience Range -->
                            <div class="inner-form-container">
                                <div class="form-field-wrapper">
                                    <div class="form-record">
                                        <mat-label>{{"createJd.title4-label1" | translate}} </mat-label>
                                        <div class="experienceRange-wrapper">
                                            <ngx-slider [(value)]="value" [(highValue)]="highValue" [options]="options">
                                            </ngx-slider>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Minimum Qualification -->
                            <div class="inner-form-container">
                                <div class="form-field-wrapper">
                                    <div class="form-record">
                                        <mat-label>{{"createJd.title4-label2" | translate}}</mat-label>
                                        <mat-form-field class="example-full-width" appearance="fill">
                                            <input placeholder="{{'createJd.title4-label2PlaceHolder' | translate}}"
                                                type="text" matInput [formControl]="myControlQualification"
                                                [matAutocomplete]="auto">

                                            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnQua"
                                                (optionSelected)="qualificationSelect($event.option.value)">
                                                <mat-option *ngFor="let option of filteredOptionsQualification | async"
                                                    [value]="option">
                                                    {{option.name}}
                                                </mat-option>
                                            </mat-autocomplete>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>


                            <!--Job Title -->
                            <div class="inner-form-container">
                                <div class="form-field-wrapper">
                                    <div class="form-record">
                                        <mat-label>{{'createJd.title4-label3' | translate}}</mat-label>

                                        <mat-form-field class="example-full-width" appearance="fill">
                                            <input placeholder="{{'createJd.title4-label3PlaceHolder' | translate}}"
                                                type="text" matInput [formControl]="myControlTitle"
                                                [matAutocomplete]="autoT">
                                            <mat-autocomplete #autoT="matAutocomplete" [displayWith]="displayFnTitle"
                                                (optionSelected)="jobTitleSelect($event.option.value)">
                                                <mat-option *ngFor="let optionTitle of filteredOptionsTitle | async"
                                                    [value]="optionTitle">
                                                    {{optionTitle.name}}
                                                </mat-option>
                                            </mat-autocomplete>
                                        </mat-form-field>

                                    </div>
                                </div>
                            </div>

                            <!-- Additional Qualifications -->
                            <!-- <div class="form-record">
                                <mat-label>Additional Qualifications </mat-label>
                                <mat-form-field appearance="none">
                                    <input type="text" matInput placeholder="Enter Additional Qualifications "
                                        formControlName="additionalQualifications">
                                </mat-form-field>
                            </div> -->

                            <div class="form-record additionalQualifications">
                                <mat-label>{{'createJd.title4-label4' | translate}}</mat-label>
                                <mat-form-field appearance="fill" class="addQualBx">
                                    <mat-chip-list #chipList aria-label="Tags">
                                        <mat-chip *ngFor="let name of additionalQualifications"
                                            [selected]="additionalQualifications.indexOf(name) != -1?true:false"
                                            [selectable]="selectable" [color]="accent" [removable]="removable"
                                            (removed)="removeChips(name)" formArrayName="additionalQualifications">
                                            {{name}}
                                            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                        </mat-chip>
                                        <input placeholder="{{'createJd.title4-label4PlaceHolder' | translate}}"
                                            [matChipInputFor]="chipList"
                                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                            [matChipInputAddOnBlur]="addOnBlur"
                                            (matChipInputTokenEnd)="addChips($event)">
                                    </mat-chip-list>
                                </mat-form-field>
                            </div>

                            <!-- Skills -->
                            <div class="inner-form-container skills-div">
                                <div class="form-field-wrapper">
                                    <div class="form-record">
                                        <mat-label>{{'createJd.title4-label5' | translate}}</mat-label>
                                        <mat-chip-list #chipList [multiple]="true" [selectable]="true">
                                            <mat-chip *ngFor="let category of skillData" #chip="matChip"
                                                (click)="selectedSkillsArray.indexOf(category.name) != -1 ? chip.deselect() : chip.selectViaInteraction()"
                                                [selected]="selectedSkillsArray.indexOf(category.name) != -1?true:false"
                                                [selectable]="true" class="leadr-category-chip"
                                                (selectionChange)="changeSelected($event, category.name)">
                                                {{category.name}}
                                            </mat-chip>
                                        </mat-chip-list>
                                    </div>
                                </div>
                            </div>
                            <!-- [ngClass]="{'selectedSkills': selectedSkillsArray.indexOf(category.name) != -1}" -->
                            <!-- this.selectedSkillsArray.indexOf(skill) -->
                        </div>

                    </form>

                </div>

                <div class="btnWrapper" style="text-align: center;">
                    <button class="saveBtn" (click)="nextBtnReportingTo()" mat-flat-button color="primary">{{'createJd.nxtBtn' |
                        translate}}</button>
                </div>

            </mat-card-content>

        </mat-card>
    </div>

    <!-- Reporting To -->
    <div *ngIf="steps.reportingTo" class="reportingTo-container">


        <mat-card class="example-card">

            <div class="pageHead">
                <button class="back-btn" (click)="backReportTo()" mat-flat-button color="primary">
                    <mat-icon>arrow_back_ios</mat-icon>
                    {{'createJd.backBtn' | translate}}
                </button>
           
            <div class="title-div" style="text-align: center;">
                <h2>{{'createJd.title5' | translate}}</h2>
            </div>
        </div>
            <mat-card-content>
                <form [formGroup]="regForm" class="reportingToForm">
                    <div class="outer-form-container">
                    <div class="inner-form-container">
                        <div class="form-field-wrapper">
                            <div class="form-record form-record-reportingTo">
                                <mat-label>{{'createJd.title5-label1' | translate}}</mat-label>

                                <mat-form-field class="example-full-width" appearance="fill">
                                    <input placeholder="{{'createJd.title5-label1PlaceHolder' | translate}}" type="text"
                                        matInput [formControl]="myControlTitleReporting" [matAutocomplete]="autoR"
                                        required>
                                    <mat-autocomplete #autoR="matAutocomplete" [displayWith]="displayFnTitleReporting"
                                        (optionSelected)="reportingToSelect($event.option.value)">
                                        <mat-option *ngFor="let optionR of filteredOptionsTitleReporting | async"
                                            [value]="optionR">
                                            {{optionR.name}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>

                            </div>
                        </div>
                    </div>
            

                <!-- Salery Range -->
                <div class="inner-form-container">
                    <div class="form-field-wrapper">
                        <div class="form-record form-record-reportingTo">
                            <mat-label>{{'createJd.title5-label2' | translate}} </mat-label>
                            <div class="salery-slider-wrapper">
                                <ngx-slider [(value)]="Rvalue" [(highValue)]="RhighValue" [options]="Roptions">
                                </ngx-slider>
                            </div>
                        </div>
                    </div>
                </div>

                <!--Revenue Responsibility -->
                <div class="inner-form-container">
                    <div class="form-field-wrapper">
                        <div class="form-record form-record-reportingTo">
                            <mat-label>{{'createJd.title5-label3' | translate}} [USD]</mat-label>
                            <mat-slide-toggle [checked]="revenueResponsibility?true:false"
                                (change)="revenueToggle($event)" class="resposibility-slider-div">
                            </mat-slide-toggle>
                            <div class="responsibility-wrapper">
                                <ngx-slider *ngIf="revenueResponsibility" [(value)]="RevValue" [options]="RevOptions">
                                </ngx-slider>
                            </div>
                        </div>
                    </div>
                </div>

                <!--Budget Responsibility -->
                <div class="inner-form-container">
                    <div class="form-field-wrapper">
                        <div class="form-record form-record-reportingTo">
                            <mat-label>{{'createJd.title5-label4' | translate}} [USD]</mat-label>
                            <mat-slide-toggle [checked]="budgetResponsibility?true:false" (change)="budgeToggle($event)"
                                class="resposibility-slider-div">
                            </mat-slide-toggle>
                            <div class="responsibility-wrapper">
                                <ngx-slider *ngIf="budgetResponsibility" [(value)]="bValue" [options]="bOptions">
                                </ngx-slider>
                            </div>
                        </div>
                    </div>
                </div>
                <!--Work Model -->
                <div class="inner-form-container">
                    <div class="form-field-wrapper">
                        <div class="form-record form-record-reportingTo">
                            <mat-label>{{'createJd.title5-label5' | translate}}</mat-label>
                            <div class="responsibility-wrapper" style="width: 40%;">
                                <mat-button-toggle-group (change)="onChangeButtonToggle($event)"
                                    [value]="selectedWorkModel" name="workModel" aria-label="Font Style">
                                    <mat-button-toggle value="Full Time">{{'createJd.title5-label5Label1' | translate}}
                                    </mat-button-toggle>
                                    <mat-button-toggle value="Part Time">{{'createJd.title5-label5Label2' | translate}}
                                    </mat-button-toggle>
                                    <mat-button-toggle value="Flexi Time">{{'createJd.title5-label5Label3' | translate}}
                                    </mat-button-toggle>
                                    <mat-button-toggle value="Work From Home">{{'createJd.title5-label5Label4'
                                        |translate}}</mat-button-toggle>
                                </mat-button-toggle-group>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>

                <div class="btnWrapper" style="text-align: center;margin-top : 2%">
                    <button class="saveBtn" (click)="submitData()" mat-flat-button color="primary">{{'submitBtn' |
                        translate}}</button>
                </div>

            </mat-card-content>

        </mat-card>
    </div>

    <!-- JD -->
    <div *ngIf="steps.viewJd" class="createdJd">
        <div *ngIf="jdCreatedData" class="jd-form-wrapper">

            <div *ngIf="jdList">
                <!-- jdList -->
                <div class="pageHead">
                    <button class="back-btn" (click)="jdViewEditback()" mat-flat-button color="primary">
                        <mat-icon>arrow_back_ios</mat-icon>
                        {{'createJd.backBtn' | translate}}
                    </button>
                </div>
            </div>

            <div class="pdf-button-wrapper">
                <button class="btn-exprt"  mat-icon-button [matMenuTriggerFor]="menu" matTooltip="Menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu" class="profile-menu">
                    <button [disabled]="menuPermissions" mat-menu-item (click)="exportAsPDF()">
                        <mat-icon>picture_as_pdf</mat-icon>
                        <span>{{'createJd.savePdf' | translate}}</span>
                    </button>
                    <!-- [disabled]="!menuPermissions" -->
                    <button [disabled]="menuPermissions" (click)="exportAsDocx()" mat-menu-item>
                        <mat-icon>insert_drive_file</mat-icon>
                        <span>{{'createJd.saveDocs' | translate}}</span>
                    </button>
                    <button [disabled]="!isApproved" (click)="shareJDLink()" mat-menu-item>
                        <mat-icon>share</mat-icon>
                        <span>{{'createJd.shareLink' | translate}}</span>
                    </button>
                    <button *ngIf="editModeForJdView && !this.jdListEdit" (click)="editShareJD()" mat-menu-item>
                        <mat-icon>edit</mat-icon>
                        <span>{{'createJd.edit' | translate}}</span>
                    </button>
                </mat-menu>
            </div>

            <div class="logo-wrappper">

                <div class="company-logo">
                    <img class="logo-company-logo" src="../../../assets/images/p-101-logo.jpeg" alt="P-101 logo" />
                </div>
                <div class="organisation-logo">
                    <img class="logo-organisation-logo"
                        [src]="organisationFavicon?organisationFavicon:'../../../assets/images/team-logo.jpg'"
                        alt="Organisation logo" />
                </div>


            </div>


            <form [formGroup]="regForm">



                <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
                    <div class="example-box" cdkDrag *ngFor="let value of positionJsonArray,let i = index"
                        [cdkDragDisabled]="permissionForEditJd">


                        <div cdkDropList cdkDropListOrientation="horizontal" class="example-list1"
                            (cdkDropListDropped)="dropHorizontal($event,i)" [cdkDragDisabled]="permissionForEditJd">

                            <div class="example-box1" cdkDrag *ngFor="let data of value"
                                [cdkDragDisabled]="value.length==1 || permissionForEditJd"
                                [ngStyle]="(hover && permissionForEditJd) ? { 'box-shadow': 'none' } : ''"
                                (mouseover)="hover=true" (mouseleave)="hover=false">

                                <div *ngIf="data.type=='textField'" [class]=" data.class">
                                    <p>{{data.label}}</p>
                                    <mat-form-field [class.errorInputFields]="!regForm.get(data.formControlName).value"
                                        appearance="none" class="full-width">
                                        <input [readonly]="permissionForEditJd" type="text" matInput
                                            placeholder="{{data.placeHolder | translate}}"
                                            [formControlName]="data.formControlName" required>
                                    </mat-form-field>
                                    <div style="text-align: right;">
                                        <button *ngIf="value.length==1&&!permissionForEditJd" mat-mini-fab
                                            color="primary" (click)="addNewFieldsToArray(data)">
                                            <mat-icon>add</mat-icon>
                                        </button>
                                    </div>
                                </div>

                                <div *ngIf="data.type == 'textFieldExperience'" [class]="data.class"
                                    [formGroupName]="data?.formGroupName">
                                    <p>{{data.label}}</p>
                                    <mat-form-field
                                        [class.errorInputFields]="regForm.get('experienceRange').value.minimumExperience==0"
                                        appearance="none" class="full-width">
                                        <input [readonly]="permissionForEditJd" type="text" matInput
                                            placeholder="{{data.placeHolder | translate}}"
                                            [formControlName]="data.formControlName" required>
                                    </mat-form-field>
                                    <div style="text-align: right;">
                                        <button *ngIf="value.length==1&&!permissionForEditJd" mat-mini-fab
                                            color="primary" (click)="addNewFieldsToArray(data)">
                                            <mat-icon>add</mat-icon>
                                        </button>
                                    </div>
                                </div>

                                <div *ngIf="data.type == 'textArea'" [class]="data.class">
                                    <p>{{data.label}}</p>
                                    <textarea [class.errorInputFields]="!regForm.get(data.formControlName).value"
                                        [readonly]="permissionForEditJd" cdkTextareaAutosize class="text-feild textArea"
                                        [formControlName]="data.formControlName"
                                        [placeholder]="data.placeHolder | translate" required></textarea>
                                    <div style="text-align: right;">
                                        <button class="addDataBtn" *ngIf="value.length==1&&!permissionForEditJd" mat-mini-fab
                                            color="primary" (click)="addNewFieldsToArray(data)">
                                            <mat-icon>add</mat-icon>
                                        </button>
                                    </div>
                                </div>


                                <div *ngIf="data.type == 'custom'" class="div-wrapper margin-bottom-div">

                                    <p>{{data.label}}</p>
                                    <div [ngClass]="data.class1">
                                        <div style="margin-top: 2%;" *ngIf="data.ngIfCondition">
                                            <div [ngClass]="data.class2"
                                                *ngFor="let item of regForm.get(data.formControlName)['controls']; let i = index;"
                                                [formArrayName]="data.formControlName"
                                                [class.errorDynamic-keyParameters]="!regForm.get(data.formControlName)['controls'][i].value">
                                                <li>
                                                    <div>
                                                        <span style="padding-right: 5px;font-size: 24px;">•</span>
                                                    </div>
                                                    <textarea cdkTextareaAutosize [readonly]="permissionForEditJd"
                                                        type="text" [formControlName]="i" required></textarea>
                                                </li>
                                                <mat-icon
                                                    [class.removebtn-performance-error]="!regForm.get(data.formControlName)['controls'][i].value"
                                                    matTooltip="Remove" *ngIf="!permissionForEditJd"
                                                    class="removebtn-performance"
                                                    (click)="chooseFunction(data.function1,i)">remove
                                                </mat-icon>
                                            </div>
                                        </div>
                                        <div class="add-btn-performance" *ngIf="!permissionForEditJd"
                                            class="performance-indicators-editBtnDiv">
                                            <div [class.errorInputFields-list]="regForm.get('key_performance')['controls'].length === 0"
                                                style="text-align: left;">
                                                <mat-icon matTooltip="Add New" (click)="chooseFunction(data.function2)">
                                                    add
                                                </mat-icon>
                                            </div>
                                        </div>

                                    </div>
                                    <div style="text-align: right;">
                                        <button class="addDataBtn" *ngIf="value.length==1&&!permissionForEditJd" mat-mini-fab
                                            color="primary" (click)="addNewFieldsToArray(data)">
                                            <mat-icon>add</mat-icon>
                                        </button>
                                    </div>
                                </div>

                                <div class="div-wrapper" *ngIf="data.type == 'skill'">
                                    <p>{{data.label}}</p>
                                    <textarea [class.errorInputFields]="!skillDataToView"
                                        [readonly]="permissionForEditJd" cdkTextareaAutosize
                                        class="text-feild textAreaSkills" [(ngModel)]="skillDataToView"
                                        [ngModelOptions]="{standalone: true}" required></textarea>
                                    <div style="text-align: right;">
                                        <button class="addDataBtn" *ngIf="value.length==1&&!permissionForEditJd" mat-mini-fab
                                            color="primary" (click)="addNewFieldsToArray(data)">
                                            <mat-icon>add</mat-icon>
                                        </button>
                                    </div>
                                </div>

                                <ng-container *ngIf="data.type == 'additionalFields'">

                                    <div formArrayName="additionalFields">
                                        <div [formGroupName]="data.formGroupName" class="additional-columns">
                                            <mat-form-field
                                                [class.errorDynamic-addFields]="!regForm.get(data.formArrayName)['controls'][data.formGroupName].value.heading||
                                                !regForm.get(data.formArrayName)['controls'][data.formGroupName].value.value"
                                                appearance="none" class="full-width">
                                                <input [readonly]="permissionForEditJd" type="text" matInput
                                                    placeholder="{{data.placeHolder1 | translate}}"
                                                    [formControlName]="data.formControlName1" required>
                                            </mat-form-field>
                                            <textarea
                                                [class.errorDynamic-addFields]="!regForm.get(data.formArrayName)['controls'][data.formGroupName].value.value||
                                                !regForm.get(data.formArrayName)['controls'][data.formGroupName].value.heading"
                                                [formControlName]="data.formControlName2" cdkTextareaAutosize
                                                [readonly]="permissionForEditJd" class="text-feild textArea"
                                                placeholder="{{data.placeHolder2 | translate}}" required></textarea>
                                        </div>
                                        <div *ngIf="!permissionForEditJd" class="delete-column-btn-div">
                                            <mat-icon matTooltip="Delete Column"
                                                (click)="deleteAddedRow(data.formGroupName,data)">delete
                                            </mat-icon>
                                        </div>
                                        <hr>

                                        <div style="text-align: right;">
                                            <button class="addDataBtn" *ngIf="value.length==1&&!permissionForEditJd" mat-mini-fab
                                                color="primary" (click)="addNewFieldsToArray(data)">
                                                <mat-icon>add</mat-icon>
                                            </button>
                                        </div>
                                    </div>

                                </ng-container>

                            </div>

                        </div>

                    </div>
                </div>

                <div class="div-wrapper d-flex margin-bottom-div">
                    <!-- <div class="title-reportingTo-wrapper"> -->
                    <!-- <div class="half-width">
                        <p>{{'createJd.Name' | translate}} </p>
                        <mat-form-field [class.errorInputFields]="!regForm.get('name').value" appearance="none"
                            class="full-width">
                            <input [readonly]="permissionForEditJd" type="text" matInput
                                placeholder="{{'createJd.NamePlaceHolder' | translate}}" formControlName="name" required>
                        </mat-form-field>
                    </div>
                    <div class="half-width">
                        <p>{{'createJd.Code' | translate}}</p>
                        <mat-form-field [class.errorInputFields]="!regForm.get('code').value" appearance="none"
                            class="full-width">
                            <input [readonly]="permissionForEditJd" type="text" matInput placeholder="{{'createJd.CodePlaceHolder' | translate}}"
                                formControlName="code" required>
                        </mat-form-field>
                    </div> -->
                </div>
                <div class="div-wrapper d-flex margin-bottom-div">
                    <!-- <div class="title-reportingTo-wrapper"> -->
                    <!-- <div class="half-width">
                        <p>{{'createJd.title4-label3' | translate}}</p>
                        <mat-form-field [class.errorInputFields]="!regForm.get('jobTitle').value" appearance="none"
                            class="full-width">
                            <input [readonly]="permissionForEditJd" type="text" matInput placeholder="{{'createJd.title4-label3' | translate}}"
                                formControlName="jobTitle" required>
                        </mat-form-field>
                    </div>
                    <div class="half-width">
                        <p>{{'createJd.title5-label1' | translate}} </p>
                        <mat-form-field [class.errorInputFields]="!regForm.get('reportingTo').value" appearance="none"
                            class="full-width">
                            <input [readonly]="permissionForEditJd" type="text" matInput
                                placeholder="{{'createJd.title5-label1' | translate}}" formControlName="reportingTo" required>
                        </mat-form-field>
                    </div> -->
                </div>

                <div class="div-wrapper margin-bottom-div">
                    <!-- <div class="purpose-of-role"> -->
                    <!-- <p>{{'createJd.title6' | translate}}</p>
                    <textarea [class.errorInputFields]="!regForm.get('purpose_role').value"
                        [readonly]="permissionForEditJd" cdkTextareaAutosize class="text-feild textArea"
                        id="reportingTo" name="reportingTo" formControlName="purpose_role"
                        placeholder="{{'createJd.title6PlaceHolder' | translate}}" required></textarea> -->
                </div>
                <div class="div-wrapper margin-bottom-div">
                    <!-- <div class="functional-context"> -->
                    <!-- <p>{{'createJd.title7' | translate}}</p>
                    <textarea [class.errorInputFields]="!regForm.get('functional_content').value"
                        [readonly]="permissionForEditJd" cdkTextareaAutosize class="text-feild textArea"
                        id="functionalContext" name="functionalContext" formControlName="functional_content"
                        placeholder="{{'createJd.title7PlaceHolder' | translate}}" required></textarea> -->
                </div>

                <!-- <div class="div-wrapper margin-bottom-div">

                    <p>{{'createJd.title8' | translate}}</p>
                    <div class="roles-responsibilities-list">
                        <div style="margin-top: 2%;" *ngIf="roles_responsibilities.length">
                            <div class="ul-div"
                                *ngFor="let item of regForm.get('roles_responsibilities')['controls']; let i = index;"
                                formArrayName="roles_responsibilities"
                                [class.errorDynamic-keyParameters]="!regForm.get('roles_responsibilities')['controls'][i].value">
                                <li>
                                    <div>
                                        <span style="padding-right: 5px;font-size: 24px;">•</span>
                                    </div>
                                    <textarea cdkTextareaAutosize [readonly]="permissionForEditJd" type="text"
                                        [formControlName]="i" required></textarea>
                                </li>
                                <mat-icon
                                    [class.removebtn-roles-error]="!regForm.get('roles_responsibilities')['controls'][i].value"
                                    matTooltip="Remove" *ngIf="!permissionForEditJd" class="removebtn-roles"
                                    (click)="removeFromListRole(i)">remove
                                </mat-icon>
                            </div>
                        </div>
                        <div class="add-btn-performance" *ngIf="!permissionForEditJd"
                            class="performance-indicators-editBtnDiv">

                            <div [class.errorInputFields-list]="regForm.get('roles_responsibilities')['controls'].length === 0"
                                style="text-align: left;">
                                <mat-icon matTooltip="Add New" (click)="addNewColomnToListRole()">add
                                </mat-icon>
                            </div>
                        </div>
                        <p *ngIf="!key_performance.length" style="text-align:center;">{{'createJd.NoDataError' |
                            translate}}</p>
                    </div>
                </div> -->

                <!-- <div class="div-wrapper margin-bottom-div">
                    <p>{{'createJd.title9' | translate}}</p>
                    <div id="performance-indicators-div" class="performance-indicators-list">
                        <div style="margin-top: 2%;" *ngIf="key_performance.length">
                            <div class="ul-div"
                                *ngFor="let item of regForm.get('key_performance')['controls']; let i = index;"
                                formArrayName="key_performance"
                                [class.errorDynamic-keyParameters]="!regForm.get('key_performance')['controls'][i].value">
                                <li>
                                    <div>
                                        <span style="padding-right: 5px;font-size: 24px;">•</span>
                                    </div>
                                    <textarea cdkTextareaAutosize [readonly]="permissionForEditJd" type="text"
                                        [formControlName]="i" required></textarea>
                                </li>
                                <mat-icon
                                    [class.removebtn-performance-error]="!regForm.get('key_performance')['controls'][i].value"
                                    matTooltip="Remove" *ngIf="!permissionForEditJd" class="removebtn-performance"
                                    (click)="removeFromList(i)">remove
                                </mat-icon>
                            </div>
                        </div>
                        <div class="add-btn-performance" *ngIf="!permissionForEditJd"
                            class="performance-indicators-editBtnDiv">

                            <div [class.errorInputFields-list]="regForm.get('key_performance')['controls'].length === 0"
                                style="text-align: left;">
                                <mat-icon matTooltip="Add New" (click)="addNewColomnToList()">add
                                </mat-icon>
                            </div>
                        </div>
                    </div>
                </div> -->

                <!-- <div class="div-wrapper d-flex margin-bottom-div">
                    <div formGroupName="experienceRange" class="half-width">
                        <p>{{'createJd.minExperience' | translate}}</p>
                        <mat-form-field
                            [class.errorInputFields]="regForm.get('experienceRange').value.minimumExperience==0"
                            appearance="none" class="full-width">
                            <input [readonly]="permissionForEditJd" type="text" matInput
                                placeholder="{{'createJd.minExperience' | translate}}"
                                formControlName="minimumExperience" required>
                        </mat-form-field>
                    </div>
                    <div class="half-width">
                        <p>{{'createJd.title4-label2' | translate}} </p>
                        <mat-form-field [class.errorInputFields]="!regForm.get('minimumQualification').value"
                            appearance="none" class="full-width">
                            <input [readonly]="permissionForEditJd" type="text" matInput
                                placeholder="{{'createJd.title4-label2' | translate}}"
                                formControlName="minimumQualification" required>
                        </mat-form-field>
                    </div>
                </div> -->
                <!-- <div class="div-wrapper d-flex ">
                    <div class="half-width margin-bottom-div" *ngIf="revenueResponsibility"
                        [class.full-width]="!budgetResponsibility">
                        <p>{{'createJd.title5-label3' | translate}} [USD] </p>
                        <mat-form-field [class.errorInputFields]="!regForm.get('revenueResponsibility').value"
                            appearance="none" class="full-width">
                            <input [readonly]="permissionForEditJd" type="text" matInput
                                placeholder="{{'createJd.title5-label3' | translate}}"
                                formControlName="revenueResponsibility" required>
                        </mat-form-field>
                    </div>
                    <div class="half-width margin-bottom-div" *ngIf="budgetResponsibility"
                        [class.full-width]="!revenueResponsibility">
                        <p>{{'createJd.title5-label4' | translate}} [USD]</p>
                        <mat-form-field [class.errorInputFields]="!regForm.get('budgetResponsibility').value"
                            appearance="none" class="full-width">
                            <input [readonly]="permissionForEditJd" type="text" matInput
                                placeholder="{{'createJd.title5-label4' | translate}}"
                                formControlName="budgetResponsibility" required>
                        </mat-form-field>
                    </div>
                </div> -->

                <!-- <div class="div-wrapper">
                    <p>{{'createJd.title10' | translate}}</p>
                    <textarea [class.errorInputFields]="!skillDataToView" [readonly]="permissionForEditJd"
                        cdkTextareaAutosize class="text-feild textAreaSkills" [(ngModel)]="skillDataToView"
                        [ngModelOptions]="{standalone: true}"
                        placeholder="{{'createJd.title10PlaceHolder' | translate}}" required></textarea>
                </div> -->

                <!-- <div formArrayName="additionalFields"
                    *ngFor="let item of regForm.get('additionalFields')['controls']; let i = index;">
                    <div [formGroupName]="i" class="additional-columns">
                        <mat-form-field
                            [class.errorDynamic-addFields]="!regForm.get('additionalFields')['controls'][i].value.heading||!regForm.get('additionalFields')['controls'][i].value.value"
                            appearance="none" class="full-width">
                            <input [readonly]="permissionForEditJd" type="text" matInput
                                placeholder="{{'createJd.title11PlaceHolder1' | translate}}" formControlName="heading"
                                required>
                        </mat-form-field>
                        <textarea
                            [class.errorDynamic-addFields]="!regForm.get('additionalFields')['controls'][i].value.value||!regForm.get('additionalFields')['controls'][i].value.heading"
                            formControlName="value" [readonly]="permissionForEditJd" class="text-feild textArea"
                            placeholder="{{'createJd.title11PlaceHolder2' | translate}}" required></textarea>
                    </div>
                    <div *ngIf="!permissionForEditJd" class="delete-column-btn-div">
                        <mat-icon matTooltip="Delete Column" (click)="deleteAddedRow(i)">delete</mat-icon>
                    </div>
                    <hr>
                </div> -->

                <div class="add-new-row-btn">
                    <button  (click)="addNewColomn()" mat-flat-button color="primary">
                        <mat-icon *ngIf="!permissionForEditJd" matTooltip="Add New Column">add</mat-icon>
                    </button>
                </div>

                <div *ngIf="!permissionForEditJd" style="text-align:center">
                    <button class="saveBtn" *ngIf="!this.jdListEdit" matTooltip="{{'saveBtnTitle' | translate}}" (click)="saveJD()"
                        mat-flat-button color="primary">
                        <mat-icon>save</mat-icon>{{'saveBtnTitle' | translate}}
                    </button>
                    <button class="saveBtn" *ngIf="this.jdListEdit" matTooltip="{{'updateBtnTitle' | translate}}" (click)="editJD()"
                        mat-flat-button color="primary">
                        <mat-icon>update</mat-icon>{{'updateBtnTitle' | translate}}
                    </button>
                </div>
                <div *ngIf="isApprove" class="approver-btn-wrapper">
                    <button [disabled]="!currentApprover" matTooltip="{{'createJd.rejectPlaceHolder' | translate}}"
                        (click)="rejectJD()" mat-raised-button color="primary">
                        <mat-icon>thumb_down_alt</mat-icon>{{'createJd.reject' | translate}}
                    </button>
                    <button [disabled]="!currentApprover" matTooltip="{{'createJd.approvePlaceHolder' | translate}}"
                        (click)="approveJD()" mat-raised-button color="primary">
                        <mat-icon>thumb_up_off_alt</mat-icon>{{'createJd.approve' | translate}}
                    </button>
                </div>


            </form>

        </div>
        <div *ngIf="!jdCreatedData" class=jdNotFoundDiv>
            <h3>{{'createJd.noJdFound' | translate}}</h3>
        </div>
    </div>



</div>