<div>
    <mat-icon class="model-close-icon" mat-dialog-close>close</mat-icon>
</div>

<div class="page-heading">
    <h1 *ngIf="!isEdit" class="title">{{'Add Industry' | translate}}</h1>
    <h1 *ngIf="isEdit">{{updateTitle | translate}}</h1>
</div>


<!-- <div class="model-header">
    <h2 class="mat-dialog-title"> {{data['title']}}</h2>
</div> -->

<mat-dialog-content class="mat-typography">

    <mat-grid-list [cols]="breakpoint" rowHeight="2:.3" (window:resize)="onResize($event)">

        <div class="model-item-div">
            <div [formGroup]="regForm">

                <div class="popWrapper">

                    <mat-label class="popLabel">{{labe1 | translate}}</mat-label>
                
                    <mat-form-field floatLabel="never" appearance="fill">
                        <input type="text" matInput placeholder="{{labe1PlaceHolder | translate}}" formControlName="name" required>
                        <mat-error *ngIf="regForm.touched||regForm.dirty && regForm.get('name').hasError('required')">
                            {{labe1Error | translate}}</mat-error>
                        <!-- <mat-error>{{error}}</mat-error> -->
                    </mat-form-field>
             
                <div class="icon-section">
                    <span>Industry Icon</span>
                    
             
                        <div class="industry-icon-list ">
                        
                        <div class="item-row">
                            <div class="icon-item">

                                <input id="pic-uploader" type="file" (change)="selectFile($event);" accept="image/*">

                                <label class="uploadbtn" for="pic-uploader"><img src="../../../assets/images/upload2.png"></label>
                            </div> 

                            <div class="icon-item">
                                <mat-icon class="md-block" ><img src="../../../assets/images/industry-icon2.png"></mat-icon>
                            </div> 
                            <div class="icon-item">
                                <mat-icon class="md-block" ><img src="../../../assets/images/industry-icon1.png"></mat-icon>
                            </div> 
                            <div class="icon-item">
                                <mat-icon class="md-block" ><img src="../../../assets/images/industry-icon3.png"></mat-icon>
                            </div>
                        </div>

                        <div class="item-row">
                            <div class="icon-item">
                                <mat-icon class="md-block" ><img src="../../../assets/images/industry-icon2.png"></mat-icon>
                            </div> 
                            <div class="icon-item">
                                <mat-icon class="md-block" ><img src="../../../assets/images/industry-icon2.png"></mat-icon>
                            </div> 
                            <div class="icon-item">
                                <mat-icon class="md-block" ><img src="../../../assets/images/industry-icon1.png"></mat-icon>
                            </div> 
                            <div class="icon-item">
                                <mat-icon class="md-block" ><img src="../../../assets/images/industry-icon3.png"></mat-icon>
                            </div>
                        </div>
                  
                </div>
            </div>
        </div>


                
               <!--  <div class="profile-wrapper">
                    <img id="profile-pic" mat-card-image
                        [src]="picurl ? picurl : '../../../../assets/images/industry.png'" width="10%">
                    <input id="pic-uploader" type="file" (change)="selectFile($event);" accept="image/*">
                    <div class="uploadbtn-wrapper">
                    <label class="uploadbtn" for="pic-uploader">{{changeBtn | translate}}</label>
                    </div>
                </div> -->

                
            </div>

        </div>
    </mat-grid-list>


</mat-dialog-content>
<mat-dialog-actions align="center">
    <!-- <button mat-button mat-dialog-close class="button-close" data-backdrop="static"
        data-keyboard="false">{{cancelBtn | translate}}</button> -->
    <button *ngIf="!isEdit" mat-button class="button-close" (click)="createIndustry()">{{saveBtn | translate}}</button>
    <button *ngIf="isEdit" mat-button class="button-close" (click)="updateIndustry()">{{updateBtn | translate}}</button>
</mat-dialog-actions>