<div>
    <mat-icon class="model-close-icon" mat-dialog-close>close</mat-icon>
</div>
<mat-dialog-content class="mat-typography">

    <mat-grid-list [cols]="breakpoint" rowHeight="2:.3" (window:resize)="onResize($event)" class="grid-elements">
        <div>
            <form [formGroup]="regForm">
                <div class="work-flow-wrapper model-item-div">

                    <div class="flexRow">
                        <mat-label class="pt10">{{label1 | translate}}</mat-label>
                        <mat-form-field floatLabel="never" appearance="fill">
                            <input [readonly]="isEdit" type="text" matInput
                                placeholder="{{label1PlaceHolder | translate}}" formControlName="name" required>
                            <mat-error *ngIf="regForm.get('name').hasError('required')">
                                {{label1Error | translate}} </mat-error>
                            <!-- <mat-error>{{errorEmail}}</mat-error>  -->
                        </mat-form-field>
                    </div>
                    <input formControlName="_id" *ngIf="false" type="text">
                    <div style="position: relative;">
                        <div class="flexRow">
                            <mat-label class="pt10">{{label2 | translate}}</mat-label>
                            <mat-form-field floatLabel="never" appearance="fill" class="description">
                                <!-- regForm.touched||regForm.dirty && -->
                                <!-- && regForm.get('description').pristine -->
                                <textarea [readonly]="isEdit" cdkTextareaAutosize type="text" matInput required
                                    placeholder="{{label2PlaceHolder | translate}}"
                                    formControlName="description"></textarea>

                                <!-- <mat-error>{{errorEmail}}</mat-error>  -->
                                <p class="error-paragraph"
                                *ngIf="regForm.dirty && regForm.get('description').hasError('required') && !regForm.value.description">
                                {{label2Error | translate}} </p>
                            </mat-form-field>

                        </div>
                     
                    </div>

                    <div *ngIf="!isEdit" class="btn-wrapper" style="width: 100%;text-align: right;">
                        <button (click)="addNewColomn()" mat-mini-fab color="primary" class="add-btn2">
                            <mat-icon>add</mat-icon>Add New
                        </button>
                    </div>
                    <h2 *ngIf="isEdit" style="margin: 0px 0 6px;">{{label3 | translate}}</h2>

                    <div formArrayName="participents"
                        *ngFor="let item of regForm.get('participents')['controls']; let i = index;" class="xsScroll">

                        <div class="dynamic-field-wrapper rowBx" [formGroupName]="i">

                            <div class="form-record dynamic-fields user col-3">

                                <mat-label>{{label4 | translate}}</mat-label>
                                <mat-form-field class="example-full-width" appearance="fill">
                                    <input [readonly]="isEdit" type="text" matInput (keyup)="handleUserSelect($event)"
                                        [matAutocomplete]="auto" placeholder="{{label4PlaceHolder | translate}}"
                                        formControlName="user" required>
                                    <!-- <mat-error
                                        *ngIf="regForm.get('participents')['controls'][i].get('user')?.hasError('required')">
                                        Please Select User</mat-error> -->
                                </mat-form-field>
                                <p class="error-paragraph2" *ngIf="regForm.dirty && regForm.get('participents')['controls'][i].get('user')?.hasError('required') && 
                                !regForm.get('participents')['controls'][i].value.user">
                                    {{label4Error | translate}} </p>

                            </div>

                            <div class="form-record dynamic-fields type col-3">

                                <mat-label>{{label5 | translate}} </mat-label>
                                <mat-form-field appearance="fill">
                                    <mat-select [disabled]="isEdit" placeholder="{{label5PlaceHolder | translate}}"
                                        formControlName="type" (selectionChange)="selectType($event.value,i)">
                                        <mat-option value="reviewer">{{label5Value1 | translate}}</mat-option>
                                        <mat-option value='approver'>{{label5Value2 | translate}}</mat-option>
                                    </mat-select>
                                    <!-- <mat-error
                                        *ngIf="regForm.get('participents')['controls'][i].get('type')?.hasError('required')">
                                        Please select level
                                    </mat-error> -->
                                </mat-form-field>
                                <p class="error-paragraph2" *ngIf="regForm.dirty && regForm.get('participents')['controls'][i].get('type')?.hasError('required') && 
                                !regForm.get('participents')['controls'][i].value.type">
                                    {{label5Error | translate}} </p>
                            </div>
                            <div class="form-record dynamic-fields level col-3"
                                *ngIf="regForm.get('participents')['controls'][i].value.type == 'approver'">
                                <mat-label>{{label6 | translate}}</mat-label>
                                <mat-form-field appearance="fill">
                                    <mat-select [disabled]="isEdit" placeholder="{{label6PlaceHolder | translate}}"
                                        formControlName="level" required>
                                        <mat-option value=1>{{label6Value1 | translate}}</mat-option>
                                        <mat-option value=2>{{label6Value2 | translate}}</mat-option>
                                        <mat-option value=3>{{label6Value3 | translate}}</mat-option>
                                        <mat-option value=4>{{label6Value4 | translate}}</mat-option>
                                        <mat-option value=5>{{label6Value5 | translate}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <!-- <mat-error
                                    *ngIf="regForm.get('participents')['controls'][i].get('level')?.hasError('required')">
                                    Please select level
                                </mat-error> -->
                                <p class="error-paragraph2" *ngIf="regForm.dirty && regForm.get('participents')['controls'][i].get('level')?.hasError('required') && 
                                !regForm.get('participents')['controls'][i].value.level">
                                    {{label6Error | translate}} </p>
                            </div>
                            <div *ngIf="!isEdit" class="addFabBtn">
                                <button (click)="removeColomn(i)"
                                    *ngIf="regForm.get('participents')['controls'].length > 1" mat-mini-fab
                                    color="primary">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </div>
                        </div>
                        <mat-autocomplete (optionSelected)="userSelect($event.option.value,i)" #auto="matAutocomplete"
                            [displayWith]="displayFn">
                            <!-- (optionSelected)="userSelect($event.option.value,i)" -->
                            <mat-option *ngFor="let option of filteredArray" [value]="option">
                                {{option.name}}
                            </mat-option>
                        </mat-autocomplete>

                    </div>
                </div>

            </form>
        </div>


        <div *ngIf="isEdit" class="workFlow-View-JdTable">

            <h3 *ngIf="workFlowData?.jobDescriptionData.length"
                style="font-weight: 700;font-size: 20px;margin: 0px 0 6px;">{{label7 | translate}}</h3>
            <table *ngIf="workFlowData?.jobDescriptionData.length">
                <tr>
                    <th>{{label8 | translate}}</th>
                    <th>{{label9 | translate}}</th>
                    <th>{{label10 | translate}}</th>
                </tr>
                <tr *ngFor="let option of workFlowData?.jobDescriptionData">
                    <td>{{option?.name}}</td>
                    <td>{{option?.code}}</td>
                    <td *ngIf="!option?.workFlowApproved">{{label11 | translate}}{{ option?.workFlowStatus+1}}</td>
                    <td *ngIf="option?.workFlowApproved">{{label12 | translate}}</td>
                </tr>
            </table>
        </div>

    </mat-grid-list>
    <mat-error *ngIf="duplicateUserError">{{error1 | translate}}</mat-error>

</mat-dialog-content>
<mat-dialog-actions align="center">
    <button mat-button mat-dialog-close class="button-close" data-backdrop="static"
        data-keyboard="false">{{cancelBtnTitle | translate}}</button>
    <button *ngIf="!isEdit &&!isUpdate" mat-button (click)="saveWorkFlow()" class="saveBtn">
        {{saveBtnTitle|translate}}</button>
    <button *ngIf="isUpdate" mat-button (click)="updateWorkFlow()" class="saveBtn">
        {{updateBtnTitle |translate}}</button>
</mat-dialog-actions>