<div [class.overlay]="loading" [class.spinner]="loading" (click)="$event.stopPropagation();"></div>

<div *ngIf="editProfile">
    <mat-card>

        <mat-card-content>
            <form [formGroup]="regForm" (ngSubmit)="submit()">
                <div class="outer-form-container">

                    <mat-card-subtitle>{{editTitle | translate}}</mat-card-subtitle>
                    <hr>
                    <div class="inner-form-container">

                        <div class="form-field-wrapper">

                            <mat-card class="profile-wrapper">
                                <img id="profile-pic" [src]="picurl ? picurl : '../../../../assets/images/profile.png'"
                                    width="60%">
                                <!-- (load)="Imageproperty($event);" -->
                                <input id="pic-uploader" type="file" (change)="selectFile($event);" accept="image/*">
                                <div class="uploadbtn-wrapper"><label class="uploadbtn"
                                        for="pic-uploader">{{uploadBtn | translate}}</label>
                                </div>
                            </mat-card>

                            <div class="form-record">
                                <mat-label>{{text1 | translate}} </mat-label>
                                <mat-form-field appearance="none">
                                    <input type="text" matInput placeholder="{{text1Placeholder | translate}}" formControlName="name"
                                        required>
                                    <mat-error *ngIf="regForm.dirty && regForm.get('name').hasError('required')">
                                        {{text1Error | translate}}</mat-error>
                                </mat-form-field>
                            </div>

                            <div class="form-record">
                                <mat-label>{{text2 | translate}}</mat-label>
                                <mat-form-field appearance="none">
                                    <input type="text" matInput placeholder="{{text2Placeholder | translate}}"
                                        formControlName="companyName">
                                    <mat-error *ngIf="regForm.dirty && regForm.get('companyName').hasError('required')">
                                        {{text2Error | translate}}</mat-error>
                                </mat-form-field>
                            </div>

                            <div class="form-record">
                                <mat-label>{{text3 | translate}}</mat-label>
                                <mat-form-field appearance="none">
                                    <input type="text" matInput placeholder="{{text3Placeholder | translate}}"
                                        formControlName="jobTitle">
                                    <mat-error *ngIf="regForm.dirty && regForm.get('jobTitle').hasError('required')">
                                        {{text3Error | translate}}</mat-error>
                                </mat-form-field>
                            </div>

                            <div class="form-record">
                                <mat-label>{{text4 | translate}}</mat-label>
                                <mat-form-field appearance="none">
                                    <input type="text" matInput placeholder="{{text4Placeholder | translate}}"
                                        formControlName="mobile">
                                    <mat-error *ngIf="regForm.get('mobile').hasError('required')">
                                        {{text4Error1 | translate}}
                                    </mat-error>
                                    <mat-error *ngIf="regForm.get('mobile').hasError('pattern')"> {{text4Error2 | translate}}
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="form-record">
                                <mat-label>{{text5 | translate}}</mat-label>
                                <mat-form-field appearance="none">
                                    <input type="text" matInput placeholder="{{text5Placeholder | translate}}"
                                        formControlName="email">
                                    <mat-error *ngIf="regForm.dirty && regForm.get('email').hasError('required')">
                                        {{text5Error1 | translate}}</mat-error>
                                    <mat-error *ngIf="regForm.get('email').hasError('email')"> {{text5Error2 | translate}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <span *ngIf="isEmailExist" class="mail-exist-error"> {{text5Error3 | translate}} </span>
                        </div>

                    </div>
                </div>

                <div class="button-div">
                    <div class="button-cancel">
                        <button type="reset" mat-button (click)="updateProfileCancel()">{{cancelBtnTitle | translate}}</button>
                    </div>
                    <div class="button">
                        <button type="submit" mat-button>{{saveBtnTitle | translate}}</button>
                    </div>
                </div>


            </form>
        </mat-card-content>
    </mat-card>
</div>

<div *ngIf="viewProfile">

    <mat-card>

        <mat-card-content>
            <form [formGroup]="regForm" (ngSubmit)="submit()">
                <div class="outer-form-container">

                  
                    <div class="inner-form-container">
                        <div class="form-field-wrapper">
                            <mat-card-subtitle>{{viewTitle | translate}}</mat-card-subtitle>
                            <hr>

                            <mat-card class="profile-wrapper-view">
                                <img id="profile-pic" [src]="picurl ? picurl : '../../../../assets/images/profile.png'">
                                <!-- (load)="Imageproperty($event);" -->
                            </mat-card>

                            <div class="form-record">
                                <mat-label>{{text1 | translate}} </mat-label>
                                <mat-form-field appearance="none">
                                    <input type="text" [readonly]="true" matInput
                                        placeholder="{{text1Placeholder | translate}}" formControlName="name" required>
                                    <mat-error *ngIf="regForm.dirty && regForm.get('name').hasError('required')">
                                        {{text1Error | translate}}</mat-error>
                                </mat-form-field>
                            </div>

                            <div class="form-record">
                                <mat-label>{{text2 | translate}}</mat-label>
                                <mat-form-field appearance="none">
                                    <input type="text" [readonly]="true" matInput
                                        placeholder="{{text2Placeholder | translate}}" formControlName="companyName">
                                    <mat-error *ngIf="regForm.get('companyName').hasError('pattern')">{{text2Error |translate}}</mat-error>
                                </mat-form-field>
                            </div>

                            <div class="form-record">
                                <mat-label>{{text3 | translate}}</mat-label>
                                <mat-form-field appearance="none">
                                    <input type="text" [readonly]="true" matInput placeholder="{{text3Placeholder | translate}}"
                                        formControlName="jobTitle">
                                    <mat-error *ngIf="regForm.dirty && regForm.get('jobTitle').hasError('required')">
                                        {{text3Error |translate}}</mat-error>
                                </mat-form-field>
                            </div>

                            <div class="form-record">
                                <mat-label>{{text4 | translate}}</mat-label>
                                <mat-form-field appearance="none">
                                    <input type="text" [readonly]="true" matInput placeholder="{{text4Placeholder | translate}}"
                                        formControlName="mobile">
                                    <mat-error *ngIf="regForm.get('mobile').hasError('required')">
                                        {{text4Error1 | translate}}
                                    </mat-error>
                                    <mat-error *ngIf="regForm.get('mobile').hasError('pattern')"> {{text4Error2 | translate}}
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="form-record">
                                <mat-label>{{text5 | translate}}</mat-label>
                                <mat-form-field appearance="none">
                                    <input type="text" [readonly]="true" matInput placeholder="{{text5Placeholder | translate}}"
                                        formControlName="email">
                                    <mat-error *ngIf="regForm.dirty && regForm.get('email').hasError('required')">
                                        {{text5Error1 | translate}}</mat-error>
                                    <mat-error *ngIf="regForm.get('email').hasError('email')">{{text5Error2 | translate}}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <span *ngIf="isEmailExist" class="mail-exist-error">{{text5Error3 | translate}}</span>
                        </div>

                    </div>
                </div>

                <div class="button-div">
                
                        <button type="reset" mat-button (click)="formCancel()">{{cancelBtnTitle | translate}}</button>
           
                        <button type="submit" mat-flat-button (click)="updateProfile()" class="saveBtn">{{updateBtnTitle | translate}}</button>
                  
                </div>


            </form>
        </mat-card-content>
    </mat-card>

</div>