import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from '@atmeservices/user.service';
import { SnackbarService } from 'src/app/helpers/snackbar.service';
import { ROLE_SUPERADMIN } from '../../../../../atme-shared/dist/Constants';

@Component({
  selector: 'app-custom-model',
  templateUrl: './custom-model.component.html',
  styleUrls: ['./custom-model.component.scss']
})
export class CustomModelComponent implements OnInit {

  incomingData: any
  userData: any
  ownerData: any
  breakpoint: any
  admin = false;
  detailToShow: boolean;
  imageToShow = false;
  picurl: string | null;

  constructor(private _snackBar: SnackbarService, private readonly userService: UserService,
     public dialogRef: MatDialogRef<CustomModelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    // console.log(data['title']);
    // Object.keys(data.data));
    if (data) {
      if (data['title'] == 'Owner' || data['title'] == 'Fleet' || data['title'] == 'User' || data['title'] == 'Vessel') {
        this.detailToShow = false
      } else {
        this.detailToShow = true;
      }
      if (data['image_url']) {
        this.imageToShow = true;
        this.picurl = data['image_url'];
        console.log(this.picurl)
      }
      //  else {
      //   this.imageToShow = false;
      // }
      this.userData = Object.keys(data['data']).map(key => ({ type: key, value: data['data'][key] }));
    }


  }

  ngOnInit() {
    this.breakpoint = (window.innerWidth <= 425) ? 2 : 2;
  }

  // fetch login users role

  // Window resize for login divisions
  onResize(event) {
    this.breakpoint = (event.target.innerWidth <= 425) ? 2 : 2;
  }

  public closeMe() {
    this.dialogRef.close();
  }

}
