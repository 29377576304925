/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { BASE_URI, USER_API, LINKEDIN_API } from '@atme/serverAPI';
import { UserS } from '@atme/entity/User';
import { SocialAuthService } from 'angularx-social-login';

@Injectable({ providedIn: 'root' })
export class AccountService {

  private userSubject: BehaviorSubject<any>;

  public user: Observable<any>;

  constructor(private router: Router, private readonly http: HttpClient, private authService: SocialAuthService) {

    this.userSubject = new BehaviorSubject<any>(localStorage.getItem('jwttoken'));
    this.user = this.userSubject.asObservable();


  }


  public get userValue(): any {

    return this.userSubject.value;

  }

  login(token: string, name: string):void {

    localStorage.setItem('jwttoken', token);
    localStorage.setItem('name', name);
    // LocalStorage.setItem('userName', userName);
    this.userSubject.next(token);

  }

  loginWithLinkedIN() {

    return this.http.get(`${BASE_URI}${LINKEDIN_API}/`).pipe(
      catchError((err) => throwError(err))
    );

  }

  verifyLinkedInUser(code: string) {

    return this.http.post(`${BASE_URI}${LINKEDIN_API}/code`, { code }).pipe(
      catchError((err) => throwError(err))
    );

  }

  logout() {

    // Remove user from local storage and set current user to null
    this.authService.signOut(true)['catch']((error) => {

      console.error(error);

    });
    localStorage.clear();
    this.userSubject.next(null);
    this.router.navigate([ '' ]);

  }

  providerValidation(user: UserS): Observable<unknown> {

    return this.http.post<UserS>(`${BASE_URI}${USER_API}/verifyprovider`, user).pipe(
      catchError((err) => throwError(err))
    );

  }

}
