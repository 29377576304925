import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '@atmeservices/user.service';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { UserInvitationS } from '@atme/entity/userInvitation';

// Import custom validator to validate that password and confirm password fields match
import { MustMatch } from '../../Helpers/must-match.validator';
import { InvitationManagementService } from '@atmeservices/invitation-management.service';
import { TranslateService } from '@ngx-translate/core';
import { CommunicationService } from '@atmeservices/communication.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  error: string | null;

  regForm: FormGroup

  loading = false;

  invitationId;

  isInvitationValid = true;

  form: FormGroup = new FormGroup({
    name: new FormControl(''),
    email: new FormControl(''),
    password: new FormControl(''),
    cPassword: new FormControl(''),
  });
  inviteData: any = [];

  constructor(private readonly userService: UserService,
    private readonly router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private communicationService: CommunicationService,
    private readonly invitationService: InvitationManagementService,
  ) { }

  submit(): void {

    this.error = null;

    if (this.inviteData.organisationId) {
      this.regForm.value.organisation = this.inviteData.organisationId;
    }

    if (this.regForm.valid) {

      if (this.invitationId) {
        this.regForm.value['uniqueId'] = this.invitationId;
      }

      this.loading = true;

      this.userService.signUp(this.regForm.value).subscribe((message) => {

        if (message)
          this.loading = false;
        this.router.navigateByUrl('/');

      }, (error) => {

        this.loading = false;
        this.error = error;

      });

    }

  }

  createForm(): void {

    this.regForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      companyName: ['', [Validators.required]],
      jobTitle: ['', [Validators.required]],
      mobile: ['', [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      cPassword: ['', [Validators.required]],
      // eslint-disable-next-line new-cap
    }, { validator: MustMatch('password', 'cPassword') });

  }

  ngOnInit(): void {

    this.createForm();
    this.invitationId = this.route.snapshot.queryParamMap.get('code');

    if (this.invitationId) {
      this.fetchInvitationData(this.invitationId)
    }

    this.communicationService.getLanguage().subscribe((value) => {
      this.onLanguageChange(value)
    })

  }
  onLanguageChange(lang: string) {
    this.langC = lang;
    this.translate.use(lang);
  }

  langC: string = "";
  title: string = "signup.title";
  placeHolder1: string = "signup.placeHolder1";
  placeHolder1Error: string = "signup.placeHolder1Error";
  placeHolder2: string = "signup.placeHolder2";
  placeHolder2Error: string = "signup.placeHolder2Error";
  placeHolder3: string = "signup.placeHolder3";
  placeHolder3Error: string = "signup.placeHolder3Error";
  placeHolder4: string = "signup.placeHolder4";
  placeHolder4Error1: string = "signup.placeHolder4Error1";
  placeHolder4Error2: string = "signup.placeHolder4Error2";
  placeHolder5: string = "signup.placeHolder5";
  placeHolder5Error1: string = "signup.placeHolder5Error1";
  placeHolder5Error2: string = "signup.placeHolder5Error2";
  placeHolder6: string = "signup.placeHolder6";
  placeHolder7: string = "signup.placeHolder7";
  placeHolder7Error1: string = "signup.placeHolder7Error1";
  placeHolder7Error2: string = "signup.placeHolder7Error2";
  loginBtn: string = "signup.loginBtn";
  label1: string = "signup.label1";
  regBtn: string = "signup.regBtn";
  forgotPassBtn: string = "signup.forgotPassBtn";
  invittationError: string = "signup.invittationError";



  fetchInvitationData(invitationId) {

    let invitationData = { invitationId: invitationId }
    this.invitationService.fetchInvitationByUniqueId(invitationData).subscribe(result => {

      if (result['invitationData']) {
        this.inviteData = result['invitationData'];
        const isInvitation = this.invitationId ? this.regForm.patchValue({ email: this.inviteData.invitedUserMailId }) : false;
      }
      else {
        this.isInvitationValid = false;
      }



    })
  }

}
