import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BASE_URI, USER_API, USER_INVITATION_API, SIGNUP_API, LOGIN_API } from '@atme/serverAPI';
import { catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class InvitationManagementService {

  constructor(
    private readonly http: HttpClient
  ) { }

  sendInvitation(invitationData: any): Observable<unknown> {

    return this.http.post(`${BASE_URI}${USER_API}/invite`, { params: invitationData }).pipe(
      catchError((err) => throwError(err))
    );

  }
  fetchInvitationByUniqueId(invitationId: any): Observable<unknown> {

    return this.http.get(`${BASE_URI}${USER_INVITATION_API}/invitation`, { params: invitationId }).pipe(
      catchError((err) => throwError(err))
    );

  }
  fetchInvitationOfOrganisation(): Observable<unknown> {

    return this.http.get(`${BASE_URI}${USER_INVITATION_API}/organisation`).pipe(
      catchError((err) => throwError(err))
    );

  }
  revokeInvitation(invitationData): Observable<unknown> {

    return this.http.get(`${BASE_URI}${USER_INVITATION_API}/revoke`, { params: invitationData }).pipe(
      catchError((err) => throwError(err))
    );

  }
}
