import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommunicationService } from '@atmeservices/communication.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dialog-box',
  templateUrl: './dialog-box.component.html',
  styleUrls: ['./dialog-box.component.scss']
})
export class DialogBoxComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogBoxComponent>,
    private translate: TranslateService,
    private communicationService: CommunicationService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }


  langC: string = "";
  cancelBtnTitle: string = "cancelBtnTitle";
  confirmBtn: string = "confirmBtn";

  onLanguageChange(lang: string) {
    this.langC = lang;
    this.translate.use(lang);
  }

  ngOnInit(): void {
    this.communicationService.getLanguage().subscribe((value) => {
      this.onLanguageChange(value)
    })
  }

  cancel() {
    this.dialogRef.close();
  }
  confirm() {
    this.dialogRef.close(true);
  }

}
