/* eslint-disable dot-notation */
/* eslint-disable id-blacklist */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganisationManagementService } from '@atmeservices/organisation-management.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { SnackbarService } from 'src/app/helpers/snackbar.service';
import { IndustryService } from '../../../services/industry.service';
import { Location } from '@angular/common';
import { CommunicationService } from '@atmeservices/communication.service';
import { TranslateService } from '@ngx-translate/core';

const resizeWidth = 425;
const columnNumber2 = 2;
const columnNumber1 = 1;

@Component({
  selector: 'app-organisation-management-dialog',
  templateUrl: './organisation-management-dialog.component.html',
  styleUrls: ['./organisation-management-dialog.component.scss']
})
export class OrganisationManagementDialogComponent implements OnInit {

  breakpoint: any;

  regForm: FormGroup;

  organisationId: string;

  isEdit = false;

  selectedFiles: FileList;

  selectedMedia = null;

  picurl: string;

  errorMsg: string;

  industryData: any = [];

  loading: boolean;

  successMessage = 'Organisation deleted successfully...';

  errorMessage = 'Error!';

  organisationDataForEdit: any = [];


  favicon: string;

  isEditable = true;

  organisationName: string;

  industrySelected: string;

  isCreateOrganisation = false;

  isChecked: boolean;

  discovery = false;


  constructor(
    public dialogRef: MatDialogRef<OrganisationManagementDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private route: ActivatedRoute,
    private readonly router: Router,
    private formBuilder: FormBuilder,
    private communicationService: CommunicationService,
    private translate: TranslateService,
    private industryService: IndustryService,
    private readonly organisationService: OrganisationManagementService,
    private _snackBar: SnackbarService,
    private _location: Location,


  ) {

    if (data.component === 'create') {

      this.organisationId = data;
      this.isCreateOrganisation = true;
      // this.fetchTeamForEdit(data);

    }

    if (data.component === "view") {
      this.isEditable = true;
      this.fetchOrganisationForEdit();

    }
    else if (data.component === "update") {
      this.isEditable = false;
      this.fetchOrganisationForEdit();

    }

  }

  ngOnInit(): void {

    this.breakpoint = innerWidth <= resizeWidth ? columnNumber1 : columnNumber2;
    this.createForm();
    this.fetchIndustryList();
    this.organisationId = this.route.snapshot.queryParamMap.get('id');
    this.communicationService.getLanguage().subscribe((value) => {
      this.onLanguageChange(value)
    })
  }



  onLanguageChange(lang: string) {
    this.langC = lang;
    this.translate.use(lang);
  }

  langC: string = "";
  title1: string = "organisation-dialoge.title1";
  label1: string = "organisation-dialoge.Label1";
  label2: string = "organisation-dialoge.Label2";
  label2PlaceHolder: string = "organisation-dialoge.Label2PlaceHolder";
  label2Error: string = "organisation-dialoge.Label2Error";
  label3: string = "organisation-dialoge.Label3";
  label3PlaceHolder: string = "organisation-dialoge.Label3PlaceHolder";
  label3Error: string = "organisation-dialoge.Label3Error";
  label4: string = "organisation-dialoge.Label4";
  label5: string = "organisation-dialoge.Label5";
  label5PlaceHolder: string = "organisation-dialoge.Label5PlaceHolder";
  label5Error: string = "organisation-dialoge.Label5Error";
  label6: string = "organisation-dialoge.Label6";
  label6PlaceHolder: string = "organisation-dialoge.Label6PlaceHolder";
  title2: string = "organisation-dialoge.title2";
  successMsg1: string = "organisation-dialoge.successMsg1";
  successMsg2: string = "organisation-dialoge.successMsg2";
  errorMsg1: string = "organisation-dialoge.errorMsg1";
  errorMsg2: string = "organisation-dialoge.errorMsg2";
  cancelBtn: string = "cancelBtnTitle";
  changeBtn: string = "imageUploadBtn";
  saveBtn: string = "saveBtnTitle";
  updateBtn: string = "updateBtnTitle";

  public closeMe(): void {

    this.dialogRef.close();

  }

  // Window resize for login divisions
  onResize(event): void {

    this.breakpoint = event.target.innerWidth <= resizeWidth ? columnNumber1 : columnNumber2;

  }

  createForm(): void {

    this.regForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      organisation_Id: ['', [Validators.required]],
      favicon: [''],
      organisation_discovery: [''],
      industry: ['', [Validators.required]],
      description: [''],
    });

  }

  editOrganisationDetails() {
    this.isEditable = false;
  }

  cancelEditOrganisationDetails() {

    this.isEditable = true;
    this.organisationDataForEdit;
    this.organisationDataForEdit ? this.regForm.patchValue(this.organisationDataForEdit) : this.organisationId = null;
    this.organisationName = this.organisationDataForEdit['name'];
    this.myControlIndustry.patchValue({ name: this.organisationDataForEdit['industry'] })
    this.isChecked = this.organisationDataForEdit['organisation_discovery'] == 'true' ? true : false;

    if (this.organisationDataForEdit.favicon) {
      this.favicon = this.organisationDataForEdit.favicon;
    }

  }


  fetchOrganisationForEdit(): void {

    this.organisationService.fetchOrganisationForEdit().subscribe((message) => {

      this.organisationDataForEdit = message
      this.organisationId = message['_id']
      message['_id'] ? this.regForm.patchValue(message) : this.organisationId = null;
      this.organisationName = message['name'];
      this.myControlIndustry.patchValue({ name: message['industry'] })
      this.isChecked = message['organisation_discovery'] == 'true' ? true : false;

      if (message.favicon) {
        this.favicon = message.favicon;
      }
    });

  }

  // Edit Organisation
  editOrganisation(): void {

    if (this.regForm.valid) {

      this.loading = true;

      const organisationData = { organisationId: this.regForm.value.organisation_Id };
      this.organisationService.fetchOrganisationByOrganisationId(organisationData).subscribe((message) => {

        if (message?.Result && this.organisationId != message?.Result?._id) {
          this.errorMsg = this.translate.instant(this.errorMsg1),
          this.loading = false;
          return;
        }

        this.regForm.value.favicon = this.favicon;

        this.organisationService.updateOrganisation(this.regForm.value, this.organisationId).subscribe((message) => {

          this.loading = false;
          if (message['message'] === 'success') {

            this._snackBar.openSnackBar(this.translate.instant(this.successMsg2), 'success');
            this.fetchOrganisationForEdit();
            this.isEditable = false;
            this.dialogRef.close();

          } else {

            this._snackBar.openSnackBar(this.translate.instant(this.errorMsg2), 'danger');

          }

        });

      })

    }

  }

  createOrganisation(): void {

    this.regForm.value.favicon = this.favicon;
    this.regForm.value.industry = this.industrySelected;
    this.regForm.value['organisation_discovery'] = this.discovery;

    if (this.regForm.valid) {

      this.regForm.value.favicon = this.picurl;

      const organisationData = { organisationId: this.regForm.value.organisation_Id };
      this.organisationService.fetchOrganisationByOrganisationId(organisationData).subscribe((message) => {

        if (message.Result) {
          this.errorMsg = this.translate.instant(this.errorMsg1)
          return;
        }

        this.organisationService.createOrganisation(this.regForm.value).subscribe((message) => {

          if (message['message'] === 'success') {
            this.communicationService.setValueForOrganisation(true)
            this._snackBar.openSnackBar(this.translate.instant(this.successMsg1), 'success');
            this.dialogRef.close(true);

          } else {

            this._snackBar.openSnackBar(this.translate.instant(this.errorMsg2), 'danger');

          }

        });

      })

    }

  }


  btnToggle(event) {

    if (event?.checked) {
      this.discovery = true;
      this.regForm.value['organisation_discovery'] = true;
    }
    else if (!event?.checked) {
      this.discovery = false;
      this.regForm.value['organisation_discovery'] = false;
    }

  }

  selectFile = (event: Event): void => {

    const fileTag = <HTMLInputElement>event.target;
    this.selectedFiles = fileTag.files;
    this.onFileSelect(this.selectedFiles[0]);

  }

  onFileSelect(file: File): void {

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {

      this.selectedMedia = reader.result.toString();
      this.favicon = this.selectedMedia;

    };

  }

  myControlIndustry = new FormControl();
  filteredOptionsIndustry: Observable<any>;

  displayFnIndustry(title: any): string {
    return title && title?.name ? title.name : '';
  }

  private _filterIndustry(name: string): any {
    const filterValue = name.toLowerCase();

    return this.industryData.filter(option => option?.name.toLowerCase().includes(filterValue));
  }

  fetchIndustryList() {

    this.loading = true;

    this.industryService.fetchIndustryList().subscribe((message) => {

      this.loading = false;

      this.industryData = message

      this.filteredOptionsIndustry = this.myControlIndustry.valueChanges.pipe(
        startWith(''),
        map(value => (typeof value === 'string' ? value : value.name)),
        map(name => (name ? this._filterIndustry(name) : this.industryData.slice())),
      );


    });
  }

  industryToSelect(value: any) {
    console.log(value);

    this.regForm.value.industry = value.name;
    this.industrySelected = value.name;
  }


  closeEditPage() {
    this._location.back();
  }

}


