/* eslint-disable dot-notation */
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { BASE_URI, JOBTITLE_API } from '@atme/serverAPI';
import { JobTitleS } from '@atme/entity/jobTitle';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class JobTitleService {

  constructor(
    private readonly http: HttpClient
  ) { }

  createJobTitle(jobTitle: JobTitleS): Observable<any> {

    return this.http.post<JobTitleS>(`${BASE_URI}${JOBTITLE_API}`, jobTitle).pipe(
      catchError((err) => throwError(err))
    );

  }

  updateJobTitle(jobTitle: JobTitleS, jobTitleId: string): Observable<any> {

    return this.http.put<JobTitleS>(`${BASE_URI}${JOBTITLE_API}`, {
      params: jobTitle,
      jobTitleId
    }).pipe(
      catchError((err) => throwError(err))
    );

  }

  deleteJobTitleById(titleId: any): Observable<unknown> {

    return this.http.delete(`${BASE_URI}${JOBTITLE_API}`, { params: titleId }).pipe(
      catchError((err) => throwError(err))
    );

  }

  fetchJobTitleList(): Observable<any> {

    return this.http.get(`${BASE_URI}${JOBTITLE_API}`).pipe(
      catchError((err) => throwError(err))
    );

  }
  fetchJobTitleListForJD(jobFamily: any): Observable<any> {

    return this.http.get(`${BASE_URI}${JOBTITLE_API}`, { params: jobFamily }).pipe(
      catchError((err) => throwError(err))
    );

  }

  fetchJobTitleById(titleData: any): Observable<any> {

    return this.http.get(`${BASE_URI}${JOBTITLE_API}/edit`, { params: titleData }).pipe(
      catchError((err) => throwError(err))
    );

  }

}
