import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.scss' ]
})
export class AppComponent {

  constructor(public router: Router,  private route: ActivatedRoute) {

    this.route.paramMap.subscribe(val => {
                  // put the code from `ngOnInit` here
                  console.log("132413255255252525")
                });

  }

}
