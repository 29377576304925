/* eslint-disable prefer-const */
/* eslint-disable dot-notation */
/* eslint-disable id-blacklist */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CommunicationService } from '@atmeservices/communication.service';
import { JobFamilyService } from '@atmeservices/job-family.service';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from 'src/app/helpers/snackbar.service';

const resizeWidth = 425;
const columnNumber = 2;

@Component({
  selector: 'app-job-family-management-dialog',
  templateUrl: './job-family-management-dialog.component.html',
  styleUrls: ['./job-family-management-dialog.component.scss']
})
export class JobFamilyManagementDialogComponent implements OnInit {

  breakpoint: any;

  regForm: FormGroup;

  JobFamilyId: string;

  isEdit = false;

  selectedFiles: FileList;

  selectedMedia = null;

  picurl: any;

  constructor(
    public dialogRef: MatDialogRef<JobFamilyManagementDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private route: ActivatedRoute,
    private readonly router: Router,
    private readonly jobFamilyService: JobFamilyService,
    private formBuilder: FormBuilder,
    private _snackBar: SnackbarService,
    private translate: TranslateService,
    private communicationService: CommunicationService,


  ) {

    if (data) {

      this.JobFamilyId = data;
      this.fetchJobFamilyForEdit(data);

    }

  }

  ngOnInit(): void {

    this.breakpoint = innerWidth <= resizeWidth ? columnNumber : columnNumber;
    this.createForm();
    this.communicationService.getLanguage().subscribe((value) => {
      this.onLanguageChange(value)
    })
  }

  onLanguageChange(lang: string) {
    this.langC = lang;
    this.translate.use(lang);
  }

  langC: string = "";
  createTitle: string = "jobFamilyDialoge.createTitle";
  updateTitle: string = "jobFamilyDialoge.updateTitle";
  labe1: string = "jobFamilyDialoge.labe1";
  labe1PlaceHolder: string = "jobFamilyDialoge.labe1PlaceHolder";
  labe1Error: string = "jobFamilyDialoge.labe1Error";
  cancelBtn: string = "cancelBtnTitle";
  changeBtn: string = "imageUploadBtn";
  saveBtn: string = "saveBtnTitle";
  updateBtn: string = "updateBtnTitle";
  createSucessMsg: string = "jobFamilyDialoge.createSucessMsg";
  updateSucessMsg: string = "jobFamilyDialoge.updateSucessMsg";
  errorMsg1: string = "jobFamilyDialoge.errorMsg1";
  errorMsg2: string = "jobFamilyDialoge.errorMsg2";
  
  public closeMe() {

    this.dialogRef.close();

  }

  // Window resize for login divisions
  onResize(event) {

    this.breakpoint = event.target.innerWidth <= resizeWidth ? columnNumber : columnNumber;

  }

  createForm(): void {

    this.regForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      image: [''],
    });

  }

  createJobFamily() {

    this.regForm.value['image'] = this.picurl;
    if (this.regForm.valid) {

      this.jobFamilyService.createJobFamily(this.regForm.value).subscribe((message) => {

        if (message['message'] === 'success') {

          this._snackBar.openSnackBar(this.translate.instant(this.createSucessMsg), 'success');
          this.dialogRef.close(true);

        } else if (message['message'] === 'exist') {

          this._snackBar.openSnackBar(this.translate.instant(this.errorMsg1), 'danger');

        } else {

          this._snackBar.openSnackBar(this.translate.instant(this.errorMsg2), 'danger');

        }

      });

    }

  }

  fetchJobFamilyForEdit(jobId: string) {

    let data = { jobFamilyId: jobId };
    this.jobFamilyService.fetchJobFamilyById(data).subscribe((message) => {

      message['_id'] ? this.regForm.patchValue(message) : this.JobFamilyId = null;
      this.picurl = message['image'];
      this.isEdit = true;

    });

  }

  updateJobFamily() {

    this.regForm.value['image'] = this.picurl;
    this.jobFamilyService.updateJobFamily(this.regForm.value, this.JobFamilyId).subscribe((message) => {

      if (message['message'] === 'success') {

        this._snackBar.openSnackBar(this.translate.instant(this.updateSucessMsg), 'success');
        this.dialogRef.close(true);

      } else if (message['message'] === 'exist') {

        this._snackBar.openSnackBar(this.translate.instant(this.errorMsg1), 'danger');

      } else {

        this._snackBar.openSnackBar(this.translate.instant(this.errorMsg2), 'danger');

      }

    });

  }

  selectFile = (event: Event): void => {

    const fileTag = <HTMLInputElement>event.target;
    this.selectedFiles = fileTag.files;
    this.onFileSelect(this.selectedFiles[0]);

  }

  onFileSelect(file: File): void {

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {

      this.selectedMedia = reader.result.toString();
      this.picurl = this.selectedMedia;

    };

  }

}
