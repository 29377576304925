/* eslint-disable no-extra-parens */
/* eslint-disable id-blacklist */
/* eslint-disable dot-notation */
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SnackbarService } from 'src/app/helpers/snackbar.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { SkillService } from '../../services/skill.service';
import { SkillManagementDialogeComponent } from './skill-management-dialoge/skill-management-dialoge.component';
import { CustomDialogComponent } from '../../components/custom-dialog/custom-dialog.component';
import { DialogBoxComponent } from '../../components/dialog-box/dialog-box.component';
import { SkillS } from '@atme/entity/skills';
import { CommunicationService } from '@atmeservices/communication.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-skill-management',
  templateUrl: './skill-management.component.html',
  styleUrls: ['./skill-management.component.scss']
})
export class SkillManagementComponent implements OnInit {

  displayedColumns: string[] = ['name', 'action'];

  dataSource = new MatTableDataSource<SkillS>();

  // successMessage = 'Skill deleted successfully...';

  // errorMessage = 'Error!';

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  @ViewChild(MatSort) sort!: MatSort;

  loading = false;

  skillData = [];

  constructor(
    public dialog: MatDialog,
    private _snackBar: SnackbarService,
    private readonly skillService: SkillService,
    private translate: TranslateService,
    private communicationService: CommunicationService,
    private router: Router) { }

  ngOnInit(): void {

    this.fetchSkillList();
    this.communicationService.getLanguage().subscribe((value) => {
      this.onLanguageChange(value)
    })

  }
  onLanguageChange(lang: string) {
    this.langC = lang;
    this.translate.use(lang);
  }

  langC: string = "";
  title: string = "skill.title";
  addBtn: string = "skill.addBtn";
  addBtnToolTip: string = "addBtnToolTip";
  search: string = "tableSearchPlaceHolder";
  label1: string = "skill.labe1";
  label2: string = "skill.labe2";
  tableNullData: string = "nullData-table-error";
  viewToolTip: string = "viewToolTip";
  editToolTip: string = "editToolTip";
  deleteToolTip: string = "deleteToolTip";
  deleteDialogTitle: string = "skill.deleteTitle";
  deleteDialogMsg1: string = "skill.deleteMsg1";
  deleteDialogMsg2: string = "skill.deleteMsg2";
  deleteSuccess: string = "skill.successMsg";
  deleteError: string = "skill.errorMsg";
  paginatorLabel: string = "paginatorLabel";

  successMessage = this.translate.instant(this.deleteSuccess);

  errorMessage = this.translate.instant(this.deleteError);

  // Search skill
  applyFilter(event: Event): void {

    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {

      this.dataSource.paginator.firstPage();

    }

  }

  // Fetch all skill records
  fetchSkillList(): void {

    this.loading = true;

    this.skillService.fetchSkillList().subscribe((message) => {

      this.loading = false;
      this.skillData = message;

      this.dataSource = new MatTableDataSource(this.skillData);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

      // Update data in data source when available

      // This.streamOfDataUpdates.subscribe(newData => this.myDataSource.data = newData);

    }, (error) => {

      this.loading = false;
      console.error(error);

    });

  }

  // View skill
  viewSkill(SkillData: { name: string; image: string; }): void {

    const skillDataO = {
      'Skill Name': SkillData?.name,
    };
    const skillDataObj = {
      'data': skillDataO,
      'title': 'Skill Details',
    };
    // eslint-disable-next-line id-blacklist
    this.dialog.open(CustomDialogComponent, { data: skillDataObj });

  }

  // Edit skill
  editSkill(skillId: string): void {

    const dialogRef = this.dialog.open(SkillManagementDialogeComponent, { data: skillId });
    dialogRef.afterClosed().subscribe((result) => {

      if (result) {

        this.fetchSkillList();

      }

    });

  }

  // Delete Skill
  deleteSkill(skillData: { name: string; }): void {

    const confirmDialog = this.dialog.open(DialogBoxComponent, {
      data: {
        title: this.translate.instant(this.deleteDialogTitle),
        message: `${this.translate.instant(this.deleteDialogMsg1)} "${skillData?.name}" ${this.translate.instant(this.deleteDialogMsg2)}`
      }
    });
    confirmDialog.afterClosed().subscribe((result) => {

      if (result === true) {

        this.skillService.deleteSkillById(skillData).subscribe((message) => {

          if (message['message'] === 'success') {

            this._snackBar.openSnackBar(this.successMessage, 'success');
            this.fetchSkillList();

          } else {

            this._snackBar.openSnackBar(this.errorMessage, 'danger');

          }
          this.loading = false;

        }, (error) => {

          console.error(error);
          this._snackBar.openSnackBar(this.errorMessage, 'danger');
          this.loading = false;

        });


      }

    });

  }

  createSkill(): void {

    const dialogRef = this.dialog.open(SkillManagementDialogeComponent);
    // Create subscription
    dialogRef.afterClosed().subscribe((result) => {

      if (result) {

        this.fetchSkillList();

      }

    });

  }

}
