<div [class.overlay]="loading" [class.spinner]="loading" (click)="$event.stopPropagation();"></div>
<div class="createdJd" *ngIf="jdCreatedData">
    <div #MyPdfDiv class="jd-form-wrapper">

        <div class="pdf-button-wrapper">
            <button mat-icon-button [matMenuTriggerFor]="menu" matTooltip="Menu">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button (click)="exportAsPDF()" mat-menu-item>
                    <mat-icon>picture_as_pdf</mat-icon>
                    <span>Save as PDF</span>
                </button>

                <button (click)="exportAsDocx()" mat-menu-item>
                    <mat-icon>insert_drive_file</mat-icon>
                    <span>Save as Doc</span>
                </button>
            </mat-menu>
        </div>

        <div class="logo-wrappper">

            <div class="company-logo">
                <img class="logo-company-logo" src="../../../assets/images/p-101-logo.jpeg" alt="P-101 logo" />
            </div>
            <div class="organisation-logo">
                <img class="logo-organisation-logo"
                    [src]="teamFavicon?teamFavicon:'../../../assets/images/team-logo.jpg'" alt="Organisation logo" />
            </div>


        </div>

        <!-- <form [formGroup]="regForm">

            <div class="div-wrapper d-flex margin-bottom-div">
                <div class="half-width">
                    <p>Name </p>
                    <mat-form-field [class.errorInputFields]="!regForm.get('name').value" appearance="none"
                        class="full-width">
                        <input [readonly]="permissionForEditJd" type="text" matInput
                            placeholder="Name of Job Description" formControlName="name" required>
                    </mat-form-field>
                </div>
                <div class="half-width">
                    <p>Code </p>
                    <mat-form-field [class.errorInputFields]="!regForm.get('code').value" appearance="none"
                        class="full-width">
                        <input [readonly]="permissionForEditJd" type="text" matInput placeholder="Code"
                            formControlName="code" required>
                    </mat-form-field>
                </div>
            </div>

            <div class="div-wrapper d-flex margin-bottom-div">
                <div class="half-width">
                    <p>Job Title </p>
                    <mat-form-field appearance="none" class="full-width">
                        <input [readonly]="permissionForEditJd" type="text" matInput placeholder="Job Title"
                            formControlName="jobTitle" required>
                    </mat-form-field>
                </div>
                <div class="half-width">
                    <p>Reporting To </p>
                    <mat-form-field appearance="none" class="full-width">
                        <input [readonly]="permissionForEditJd" type="text" matInput placeholder="Reporting To Position"
                            formControlName="reportingTo" required>
                    </mat-form-field>
                </div>
            </div>

            <div class="div-wrapper margin-bottom-div">
                <p>Purpose Of The Role:</p>
                <textarea [readonly]="permissionForEditJd" cdkTextareaAutosize class="text-feild textArea"
                    id="reportingTo" name="reportingTo" formControlName="purpose_role"
                    placeholder="Enter Purpose Of The Role..." required></textarea>
            </div>
            <div class="div-wrapper margin-bottom-div">
                <p>Functional Context:</p>
                <textarea [readonly]="permissionForEditJd" cdkTextareaAutosize class="text-feild textArea"
                    id="functionalContext" name="functionalContext" formControlName="functional_content"
                    placeholder="Enter Functional Context..." required></textarea>
            </div>

            <div class="div-wrapper margin-bottom-div">
                <p>Roles And Responsibilities:</p>
                <div class="roles-responsibilities-list">
                    <div style="margin-top: 2%;" *ngIf="roles_responsibilities.length">
                        <div class="ul-div"
                            *ngFor="let item of regForm.get('roles_responsibilities')['controls']; let i = index;"
                            formArrayName="roles_responsibilities"
                            [class.errorDynamic-keyParameters]="!regForm.get('roles_responsibilities')['controls'][i].value">
                            <li>
                                <div>
                                    <span style="padding-right: 5px;font-size: 24px;">•</span>
                                </div>
                                <textarea cdkTextareaAutosize [readonly]="permissionForEditJd" type="text"
                                    [formControlName]="i" required></textarea>
                            </li>
                        </div>
                    </div>

                    <p *ngIf="!key_performance.length" style="text-align:center;">No Data Found</p>
                </div>
            </div>

            <div class="div-wrapper margin-bottom-div">
                <p>Key Performance Indicators:</p>
                <div id="performance-indicators-div" class="performance-indicators-list">
                    <div style="margin-top: 2%;" *ngIf="key_performance.length">
                        <div class="ul-div"
                            *ngFor="let item of regForm.get('key_performance')['controls']; let i = index;"
                            formArrayName="key_performance">
                            <li>
                                <div>
                                    <span style="padding-right: 5px;font-size: 24px;">•</span>
                                </div>
                                <textarea cdkTextareaAutosize [readonly]="permissionForEditJd" type="text"
                                    [formControlName]="i" required></textarea>
                            </li>

                        </div>
                    </div>

                </div>
            </div>

            <div class="div-wrapper d-flex margin-bottom-div">
                <div formGroupName="experienceRange" class="half-width">
                    <p>Minimum Experience </p>
                    <mat-form-field [class.errorInputFields]="regForm.get('experienceRange').value.minimumExperience==0"
                        appearance="none" class="full-width">
                        <input [readonly]="permissionForEditJd" type="text" matInput placeholder="Minimum Experience"
                            formControlName="minimumExperience" required>
                    </mat-form-field>
                </div>
                <div class="half-width">
                    <p>Minimum Qualification </p>
                    <mat-form-field [class.errorInputFields]="!regForm.get('minimumQualification').value"
                        appearance="none" class="full-width">
                        <input [readonly]="permissionForEditJd" type="text" matInput placeholder="Minimum Qualification"
                            formControlName="minimumQualification" required>
                    </mat-form-field>
                </div>
            </div>

            <div class="div-wrapper d-flex margin-bottom-div">
                <div class="half-width" [class.full-width]=" !budgetResponsibility"
                    *ngIf="regForm.get('revenueResponsibility').value">
                    <p>Revenue Responsibility [USD] </p>
                    <mat-form-field appearance="none" class="full-width">
                        <input [readonly]="permissionForEditJd" type="text" matInput placeholder="Minimum Experience"
                            formControlName="revenueResponsibility" required>
                    </mat-form-field>
                </div>
                <div class="half-width" [class.full-width]="!revenueResponsibility"
                    *ngIf="regForm.get('budgetResponsibility').value">
                    <p>Budget Responsibility [USD]</p>
                    <mat-form-field appearance="none" class="full-width">
                        <input [readonly]="permissionForEditJd" type="text" matInput placeholder="Minimum Qualification"
                            formControlName="budgetResponsibility" required>
                    </mat-form-field>
                </div>
            </div>


            <div class="div-wrapper">
                <p>Required Skills:</p>
                <textarea cdkTextareaAutosize class="text-feild textAreaSkills" [(ngModel)]="skillDataToView"
                    [ngModelOptions]="{standalone: true}" placeholder="Enter Required Skills..."></textarea>
            </div>

            <div formArrayName="additionalFields"
                *ngFor="let item of regForm.get('additionalFields')['controls']; let i = index;">
                <div [formGroupName]="i" class="additional-columns">
                    <mat-form-field appearance="none" class="full-width">
                        <input [readonly]="permissionForEditJd" type="text" matInput placeholder="Enter Heading..."
                            formControlName="heading" required>
                    </mat-form-field>
                    <textarea cdkTextareaAutosize formControlName="value" [readonly]="permissionForEditJd"
                        class="text-feild textArea" placeholder="Enter Content..." required></textarea>
                </div>
                <hr>
            </div>
        </form> -->

        <form [formGroup]="regForm">



            <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
                <div class="example-box" cdkDrag *ngFor="let value of positionJsonArray,let i = index"
                    [cdkDragDisabled]="true">


                    <div cdkDropList cdkDropListOrientation="horizontal" class="example-list1"
                        (cdkDropListDropped)="dropHorizontal($event,i)" [cdkDragDisabled]="true">

                        <div class="example-box1" cdkDrag *ngFor="let data of value"
                            [cdkDragDisabled]="true"
                            [ngStyle]="(hover && permissionForEditJd) ? { 'box-shadow': 'none' } : ''"
                            (mouseover)="hover=true" (mouseleave)="hover=false">

                            <div *ngIf="data.type=='textField'" [class]=" data.class">
                                <p>{{data.label}}</p>
                                <mat-form-field [class.errorInputFields]="!regForm.get(data.formControlName).value"
                                    appearance="none" class="full-width">
                                    <input [readonly]="permissionForEditJd" type="text" matInput
                                        placeholder="{{'createJd.CodePlaceHolder' | translate}}"
                                        [formControlName]="data.formControlName" required>
                                </mat-form-field>
                            </div>

                            <div *ngIf="data.type == 'textFieldExperience'" [class]="data.class"
                                [formGroupName]="data?.formGroupName">
                                <p>{{data.label}}</p>
                                <mat-form-field
                                    [class.errorInputFields]="regForm.get('experienceRange').value.minimumExperience==0"
                                    appearance="none" class="full-width">
                                    <input [readonly]="permissionForEditJd" type="text" matInput
                                        placeholder="{{'createJd.CodePlaceHolder' | translate}}"
                                        [formControlName]="data.formControlName" required>
                                </mat-form-field>
                            </div>

                            <div *ngIf="data.type == 'textArea'" [class]="data.class">
                                <p>{{data.label}}</p>
                                <textarea [class.errorInputFields]="!regForm.get(data.formControlName).value"
                                    [readonly]="permissionForEditJd" cdkTextareaAutosize class="text-feild textArea"
                                    [formControlName]="data.formControlName"
                                    placeholder="{{'createJd.title6PlaceHolder' | translate}}" required></textarea>
                            </div>


                            <div *ngIf="data.type == 'custom'" class="div-wrapper margin-bottom-div">

                                <p>{{data.label}}</p>
                                <div [ngClass]="data.class1">
                                    <div style="margin-top: 2%;" *ngIf="data.ngIfCondition">
                                        <div [ngClass]="data.class2"
                                            *ngFor="let item of regForm.get(data.formControlName)['controls']; let i = index;"
                                            [formArrayName]="data.formControlName"
                                            [class.errorDynamic-keyParameters]="!regForm.get(data.formControlName)['controls'][i].value">
                                            <li>
                                                <div>
                                                    <span style="padding-right: 5px;font-size: 24px;">•</span>
                                                </div>
                                                <textarea cdkTextareaAutosize [readonly]="permissionForEditJd"
                                                    type="text" [formControlName]="i" required></textarea>
                                            </li>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="div-wrapper" *ngIf="data.type == 'skill'">
                                <p>{{data.label}}</p>
                                <textarea [class.errorInputFields]="!skillDataToView" [readonly]="permissionForEditJd"
                                    cdkTextareaAutosize class="text-feild textAreaSkills" [(ngModel)]="skillDataToView"
                                    [ngModelOptions]="{standalone: true}"
                                    placeholder="{{'createJd.title10PlaceHolder' | translate}}" required></textarea>
                                <div style="text-align: right;">
                                </div>
                            </div>

                            <ng-container *ngIf="data.type == 'additionalFields'">

                                <div formArrayName="additionalFields">
                                    <div [formGroupName]="data.formGroupName" class="additional-columns">
                                        <mat-form-field
                                            [class.errorDynamic-addFields]="!regForm.get(data.formArrayName)['controls'][data.formGroupName].value.heading||
                                            !regForm.get(data.formArrayName)['controls'][data.formGroupName].value.value"
                                            appearance="none" class="full-width">
                                            <input [readonly]="permissionForEditJd" type="text" matInput
                                                placeholder="{{data.placeHolder1 | translate}}"
                                                [formControlName]="data.formControlName1" required>
                                        </mat-form-field>
                                        <textarea
                                            [class.errorDynamic-addFields]="!regForm.get(data.formArrayName)['controls'][data.formGroupName].value.value||
                                            !regForm.get(data.formArrayName)['controls'][data.formGroupName].value.heading"
                                            [formControlName]="data.formControlName2" cdkTextareaAutosize
                                            [readonly]="permissionForEditJd" class="text-feild textArea"
                                            placeholder="{{data.placeHolder1 | translate}}" required></textarea>
                                    </div>

                                    <hr>
                                </div>

                            </ng-container>

                        </div>

                    </div>

                </div>
            </div>

        </form>


    </div>
</div>
<div *ngIf="!jdCreatedData" class=jdNotFoundDiv>
    <h3>Requested Job Discription is Not Found</h3>
</div>