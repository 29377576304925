<div>
    <mat-icon class="model-close-icon" mat-dialog-close>close</mat-icon>
    <!-- <div class="model-header">
        <h2 class="mat-dialog-title"> {{data['title']}}</h2>
    </div> -->
</div>
<mat-dialog-content class="mat-typography">

    <mat-grid-list [cols]="breakpoint" rowHeight="2:.3" (window:resize)="onResize($event)">

        <mat-card *ngIf="imageToShow" class="image-wrapper">
            <img id="image" mat-card-image [src]="picurl ? picurl : '../../../../assets/images/avathar.png'">
        </mat-card>

        <div>
            <h2 class="alarm_title">{{data['title']}}</h2>
            <table>
                <tr class="tr" *ngFor="let link of dataObject">
                    <td class="key">{{ link.type }}</td>
                    <td class="colen">:</td>
                    <td class="value">{{ link.value }}</td>
                </tr>
            </table>

        </div>
    </mat-grid-list>


</mat-dialog-content>
<mat-dialog-actions align="center">
    <button mat-button mat-dialog-close class="button-close" data-backdrop="static" data-keyboard="false">Close</button>
</mat-dialog-actions>





<!-- <h1  mat-dialog-title>View</h1>
<mat-list role="list">
    <mat-list-item role="listitem">Item 1</mat-list-item>
    <mat-list-item role="listitem">Item 2</mat-list-item>
    <mat-list-item role="listitem">Item 3</mat-list-item>
  </mat-list>
    <mat-dialog-content>
        {{data.first_name}}

        

    </mat-dialog-content>
<mat-dialog-actions>
    <button  mat-raised-button (click)="closeMe()">Close</button>
</mat-dialog-actions> 

<div>
    <mat-list role="list">
        <mat-list-item role="listitem">
            <span>{{data.first_name}}</span><span>{{data.first_name}}</span>
        </mat-list-item>
        <mat-list-item role="listitem">
            <span>{{data.first_name}}</span><span>{{data.first_name}}</span>
        </mat-list-item>
        <mat-list-item role="listitem">Item 3</mat-list-item>
      </mat-list>

      <mat-dialog-actions>
        <button  mat-raised-button (click)="closeMe()">Close</button>
    </mat-dialog-actions>
</div>-->