/* eslint-disable dot-notation */
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from '@atmeservices/user.service';
import { SnackbarService } from 'src/app/helpers/snackbar.service';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommunicationService } from '@atmeservices/communication.service';

const resizeWidth = 425;
const columnNumber2 = 2;
const columnNumber1 = 1;

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  breakpoint: any

  error: string | null;

  loading = false;

  constructor(private readonly userService: UserService,
    private route: ActivatedRoute,
    private readonly router: Router,
    private _snackBar: SnackbarService,
    private _location: Location,
    private translate: TranslateService,
    private communicationService: CommunicationService,
  ) { }

  forgotPasswordForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  });

  ngOnInit(): void {

    this.breakpoint = innerWidth <= resizeWidth ? columnNumber1 : columnNumber2;
    this.communicationService.getLanguage().subscribe((value) => {
      this.onLanguageChange(value)
    })

  }
  onLanguageChange(lang: string) {
    this.langC = lang;
    this.translate.use(lang);
  }

  langC: string = "";
  title: string = "forgotpassword.title";
  label1: string = "forgotpassword.label1";
  label1PlaceHolder: string = "forgotpassword.label1PlaceHolder";
  label1Error1: string = "forgotpassword.label1Error1";
  label1Error2: string = "forgotpassword.label1Error2";
  resetBtn: string = "forgotpassword.resetBtn";
  placeHolder2Error: string = "forgotpassword.placeHolder2Error";
  successMsg: string = "forgotpassword.successMsg";
  errorMsg: string = "forgotpassword.errorMsg";
  cancelBtn: string = "cancelBtnTitle";

  // Window resize for login divisions
  onResize(event): void {

    this.breakpoint = event.target.innerWidth <= resizeWidth ? columnNumber1 : columnNumber2;

  }

  forgotPassword(): void {

    this.error = null;
    if (this.forgotPasswordForm.status === 'VALID') {

      this.loading = true;
      this.userService.forgotPassword(this.forgotPasswordForm.value.email).subscribe((message) => {

        this.loading = false;
        if (message['message'] === 'success') {

          this._snackBar.openSnackBar(this.translate.instant(this.successMsg), 'success');
          this.router.navigateByUrl('/');

        } else if (message['message'] === 'Email is Wrong') {

          this.error = this.translate.instant(this.errorMsg);
          this.loading = false;
          this._snackBar.openSnackBar(this.translate.instant(this.errorMsg), 'danger');

        }

      });

    }

  }

  cancel(): void {

    this._location.back();

  }

  formInputChanged(): void {

    this.error = null;

  }

}
