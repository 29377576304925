import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SnackbarService } from 'src/app/helpers/snackbar.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { QualificationService } from '../../services/qualification.service';
import { CustomDialogComponent } from '../../components/custom-dialog/custom-dialog.component';
import { DialogBoxComponent } from '../../components/dialog-box/dialog-box.component';
import { TranslateService } from '@ngx-translate/core';
import { CommunicationService } from '@atmeservices/communication.service';

@Component({
  selector: 'app-detail-component',
  templateUrl: './detail-component.component.html',
  styleUrls: ['./detail-component.component.scss']
})
export class DetailComponentComponent implements OnInit {

  displayedColumns: string[] = ['name', 'status' ,'action'];

  dataSource = new MatTableDataSource<any>();

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  @ViewChild(MatSort) sort!: MatSort;

  loading = false;

  qualificationData: any = [];

  constructor(
    public dialog: MatDialog,
    private _snackBar: SnackbarService,
    private readonly qualificationService: QualificationService,
    private translate: TranslateService,
    private communicationService: CommunicationService,
    private router: Router) { }

  ngOnInit(): void {
     this.fetchSampleData();
  }


applyFilter(event: Event): void {

    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {

      this.dataSource.paginator.firstPage();

    }

  }
fetchSampleData(): void {

    this.loading = true;

    this.qualificationService.fetchQualificationList().subscribe((message) => {

      this.loading = false;
      this.qualificationData = message;

      this.dataSource = new MatTableDataSource(this.qualificationData);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;


    }, (error) => {

      this.loading = false;
      console.error(error);

    });

  }
}

