import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { OverviewService } from '@atmeservices/overview.service'
import { UserService } from '@atmeservices/user.service';
import { ROLE_SUPERADMIN } from '@atme/Constants';
import { OverviewDialogComponent } from './overview-dialog/overview-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { CommunicationService } from '@atmeservices/communication.service';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})



export class OverviewComponent implements OnInit {

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild('invitePaginator') paginatorInvite: MatPaginator;

  individualUsers = [];

  loading = false;

  organisation = [];

  displayedColumns: string[] = ['index', 'name', 'email', 'jobTitle', 'number'];

  dataSource = new MatTableDataSource<any>();

  displayedColumnsOrganisation: string[] = ['index', 'name', 'id', 'number', 'action'];

  dataSourceOrganisation = new MatTableDataSource<any>();

  notAdmin = false

  constructor(
    public readonly overviewService: OverviewService,
    private userService: UserService,
    private translate: TranslateService,
    private communicationService: CommunicationService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.fetchLoginuserRole()
    this.communicationService.getLanguage().subscribe((value) => {
      this.onLanguageChange(value)
    })
  }
  onLanguageChange(lang: string) {
    this.langC = lang;
    this.translate.use(lang);
  }

  langC: string = "";
  title1: string = "overview.title1";
  label1: string = "overview.label1";
  label2: string = "overview.label2";
  label3: string = "overview.label3";
  label4: string = "overview.label4";
  label5: string = "overview.label5";
  title2: string = "overview.title2";
  label6: string = "overview.label6";
  label7: string = "overview.label7";
  label8: string = "overview.label8";
  label9: string = "overview.label9";
  label10: string = "overview.label10";
  search: string = "tableSearchPlaceHolder";
  viewToolTip: string = "viewToolTip";
  tableNullData: string = "nullData-table-error";
  permissionError: string = "overview.permissionError";
  paginatorLabel: string = "paginatorLabel";

  fetchLoginuserRole() {
    this.loading = true
    this.userService.fetchLoginuserRole().subscribe(userRole => {
      this.loading = false
      if (userRole?.code == ROLE_SUPERADMIN) {
        this.fetchOrganisations()
        this.fetchIndividualUsers()
      }
      else {
        this.notAdmin = true;
      }
    })

  }

  fetchIndividualUsers(): void {

    this.overviewService.fetchIndivitualUsers().subscribe((message) => {

      this.dataSource = new MatTableDataSource(message.Result);
      this.dataSource.paginator = this.paginator;
    })

  }

  applyFilterUser(event: Event) {

    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

  }
  applyFilterOrganisation(event: Event) {

    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceOrganisation.filter = filterValue.trim().toLowerCase();

  }

  fetchOrganisations(): void {

    this.overviewService.fetchOrganisations().subscribe((message) => {

      this.dataSourceOrganisation = new MatTableDataSource(message.Result);
      this.dataSourceOrganisation.paginator = this.paginatorInvite;

    })

  }

  viewMembers(orgId) {
    const organisationData = { id: orgId }
    this.loading = true
    this.overviewService.fetchOrganisationMembers(organisationData).subscribe((message) => {
      this.loading = false
      const userObj = []
      message.users.forEach((user, index) => {
        userObj.push(user)
      })
      
      // console.log(fleetObj)

      this.dialog.open(OverviewDialogComponent, { data: userObj });

    })
  }


}
