import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { IndustryService } from '../../services/industry.service';
import { Location } from '@angular/common';
import { JobFamilyService } from '@atmeservices/job-family.service';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LabelType, Options } from "@angular-slider/ngx-slider";
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { QualificationService } from '@atmeservices/qualification.service';
import { JobLevelsService } from '@atmeservices/job-levels.service';
import { JobTitleService } from '@atmeservices/job-title.service';
import { MatChipInputEvent } from '@angular/material/chips/chip-input';
import { SkillService } from '@atmeservices/skill.service';
import { SnackbarService } from 'src/app/helpers/snackbar.service';
import { JobDescriptionService } from '@atmeservices/job-description.service';
import { JdRequestS } from '@atme/entity/jd-request';
import { COMMA, ENTER, I } from '@angular/cdk/keycodes';
import { MatDialog } from '@angular/material/dialog';
import { InviteDialogeComponent } from 'src/app/components/invite-dialoge/invite-dialoge.component';
import { ActivatedRoute, Router } from '@angular/router';
import { exportJobDiscriptionAs } from 'src/app/Helpers/exportJdAs';
import { WorkFlowService } from '@atmeservices/work-flow.service';
import { TranslateService } from '@ngx-translate/core';
import { CommunicationService } from '@atmeservices/communication.service';
import { CdkDragDrop, CdkDragEnter, CdkDragExit, moveItemInArray } from '@angular/cdk/drag-drop';


@Component({
  selector: 'app-create-jd',
  templateUrl: './create-jd.component.html',
  styleUrls: ['./create-jd.component.scss'],
})

export class CreateJdComponent implements OnInit {

  @ViewChild('MyPdfDiv') pdfDiv: ElementRef;

  loading = false;

  regForm: FormGroup;

  industryData: any = [];

  industryDataToShow: any = [];

  showLoadMoreBtn = false;

  showLoadMoreBtnJobFamily = false;

  industryCurrentIndex = 10;

  selectedIndustry = {};

  jobFamilyData: any = [];

  jobFamilyDataToShow: any = [];

  selectedjobFamily = {};

  jobFamilyCurrentIndex = 10;

  selectedQualification = {};

  selectedjobLevelData = {};

  selectedjobTitleData = {};

  selectedReportingTo = {};

  jobLevelData: any = [];

  jobLevelDataIndex = 10;

  showLoadMoreBtnJobLvl = false;

  jobLevelDataToShow: any = [];

  jobTitleData: any = [];

  skillData: any = [];

  selectedSkills: any = [];

  budgetResponsibility = false

  revenueResponsibility = false

  jobTitleDataForReportingTo: any = [];

  selectedSkillsArray: any = [];

  organisationFavicon: string

  additionalQualifications: any = [];

  jdRequest_id: string;

  permissionForEditJd = false;

  menuPermissions = true;

  editModeForJdView = false;

  isApproved = false;

  dynamicArrayIndex = 0;

  steps = {

    industry: true,
    jobFamily: false,
    jobLevel: false,
    experienceRange: false,
    reportingTo: false,
    viewJd: false,

  };


  /* There is a bug present in below code for sliders.
  when i change the language of the app from within the component that contains the slider the language of the slider doesn't change */


  value: number = 1;
  highValue: number = 10;
  options: Options = {
    floor: 0,
    ceil: 50,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return `<b>${this.translate.instant(this.minYear)}</b>` + value;
        // return `<b>Min Years: </b>` + value;
        case LabelType.High:
          return `<b>${this.translate.instant(this.maxYear)}</b>` + value;
        default:
          return value + "";
      }
    }
  };

  Rvalue: number = 8000;
  RhighValue: number = 20000;
  Roptions: Options = {
    floor: 0,
    ceil: 100000,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return `<b>${this.translate.instant(this.minAmount)}</b>` + value;
        case LabelType.High:
          return `<b>${this.translate.instant(this.maxAmount)}</b>` + value;
        default:
          return '' + value;
      }
    }
  };
  RevValue: number = 8000;
  RevOptions: Options = {
    floor: 0,
    ceil: 100000,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return `<b>${this.translate.instant(this.minAmount)}</b>` + value;
        case LabelType.High:
          return `<b>${this.translate.instant(this.maxAmount)}</b>` + value;
        default:
          return "" + value;
      }
    }
  };
  bValue: number = 8000;
  // bHighValue: number = 20000;
  bOptions: Options = {
    floor: 0,
    ceil: 100000,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return `<b>${this.translate.instant(this.minAmount)}</b>` + value;
        case LabelType.High:
          return `<b>${this.translate.instant(this.maxAmount)}</b>` + value;
        default:
          return "" + value;
      }
    }
  };

  key_performance = [];

  uniqueCode: string

  roles_responsibilities = [''];

  optionsQualification: any = [];
  selectedWorkModel: any;
  experienceRange: { minimumExperience: number; maximumExperience: number; };
  skillDataToView: any;
  jdCreatedData;
  jdList
  errorName = ''
  errorCode = ''

  // mat-chips
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  jdLogo: any;
  organisationLogo: any;
  items: FormArray;
  jdListEdit;
  isApprove;
  currentApprover;

  constructor(
    private readonly industryService: IndustryService,
    private _location: Location,
    private crd: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private readonly jobLevelService: JobLevelsService,
    private readonly JobDescriptionService: JobDescriptionService,
    private readonly jobTitleService: JobTitleService,
    private readonly qualificationService: QualificationService,
    private readonly jobFamilyService: JobFamilyService,
    private readonly skillService: SkillService,
    private readonly workFlowService: WorkFlowService,
    private translate: TranslateService,
    private communicationService: CommunicationService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    public router: Router,
    private _snackBar: SnackbarService
  ) {
    // this.loadImage()
  }


  positionJsonArray: any = [
    [{
      type: 'textField',
      label: 'Name',
      class: 'full-width',
      inputType: 'text',
      formControlName: 'name',
      placeHolder: 'createJd.NamePlaceHolder'
    },

    {
      type: 'textField',
      label: 'Code',
      class: 'full-width',
      inputType: 'text',
      formControlName: 'code',
      placeHolder: 'createJd.CodePlaceHolder',
    }
    ],
    [{
      type: 'textField',
      label: 'Job Title',
      class: 'full-width',
      inputType: 'text',
      formControlName: 'jobTitle',
      placeHolder: 'createJd.title4-label3',
    },

    {
      type: 'textField',
      label: 'Reporting To',
      class: 'full-width',
      inputType: 'text',
      formControlName: 'reportingTo',
      placeHolder: 'createJd.title5-label1',
    }
    ],
    [
      {
        type: 'textArea',
        label: 'Purpose Of The Role',
        class: 'full-width',
        inputType: 'text',
        formControlName: 'purpose_role',
        require: 'required',
        readonly: 'permissionForEditJd',
        ngIfCondition: true,
        placeHolder: 'createJd.title6PlaceHolder',
      }
    ],
    [
      {
        type: 'textArea',
        label: 'Functional Contest',
        class: 'full-width',
        inputType: 'text',
        formControlName: 'functional_content',
        placeHolder: 'createJd.title7PlaceHolder',
      }
    ],
    [
      {
        type: 'custom',
        label: 'Roles and Responsibilities',
        class1: 'roles-responsibilities-list',
        class2: 'ul-div',
        class3: 'removebtn-roles',
        class4: "removebtn-performance",
        class5: 'add-btn-performance',
        formControlName: 'roles_responsibilities',
        function1: 'removeFromListRole',
        function2: 'addNewColomnToListRole',
        ngIfCondition: 'roles_responsibilities.length'
      }
    ],
    [
      {
        type: 'custom',
        label: 'Key Performance Indicators',
        class1: 'performance-indicators-list',
        class2: 'ul-div',
        class3: 'removebtn-roles',
        class4: "removebtn-performance",
        class5: 'add-btn-performance',
        formControlName: 'key_performance',
        function1: 'removeFromList',
        function2: 'addNewColomnToList',
        ngIfCondition: 'key_performance.length'
      }
    ],
    [{
      type: 'textFieldExperience',
      label: 'Minimum Experience',
      class: 'full-width',
      inputType: 'text',
      formGroupName: 'experienceRange',
      formControlName: 'minimumExperience',
      placeHolder: 'createJd.minExperience',
    },

    {
      type: 'textField',
      label: 'Minimum Qualification',
      class: 'full-width',
      inputType: 'text',
      formControlName: 'minimumQualification',
      placeHolder: 'createJd.title4-label2',
    }
    ],
    [{
      type: 'skill',
      label: 'Required Skills',

    }
    ],

  ]

  chooseFunction(name: string, index: number) {

    if (name == 'removeFromListRole') {
      this.removeFromListRole(index)
    }
    else if (name == 'addNewColomnToListRole') {
      this.addNewColomnToListRole()
    }
    else if (name == 'removeFromList') {
      this.removeFromList(index)
    }
    else if (name == 'addNewColomnToList') {
      this.addNewColomnToList()
    }

  }

  dropHorizontal(event: CdkDragDrop<string[]>, index: number) {
    moveItemInArray(this.positionJsonArray[index], event.previousIndex, event.currentIndex);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.positionJsonArray, event.previousIndex, event.currentIndex);
  }


  ngOnInit(): void {
    this.fetchIndustryList()
    this.createForm();
    const jdCode = this.route.snapshot.queryParamMap.get('jdData');
    this.jdList = this.route.snapshot.queryParamMap.get('jdList');
    this.isApprove = this.route.snapshot.queryParamMap.get('approve');
    this.currentApprover = this.route.snapshot.queryParamMap.get('currentApprover');
    this.jdListEdit = this.route.snapshot.queryParamMap.get('jdListEdit');
    this.uniqueCode = jdCode;
    if (jdCode) {
      this.loading = true;
      this.fetchJobDescription(jdCode);
    }
    this.communicationService.getLanguage().subscribe((value) => {
      this.onLanguageChange(value)
    })
  }


  createPositionArray() {

    this.positionJsonArray
    let dummyArray = [];
    if (this.revenueResponsibility || this.budgetResponsibility) {

      if (this.revenueResponsibility) {
        dummyArray.push(
          {
            type: 'textField',
            label: 'Revenue Responsibility [USD]',
            class: 'full-width',
            inputType: 'text',
            formControlName: 'revenueResponsibility',
            placeHolder: 'createJd.title5-label3',
            ngIfCondition: `${this.revenueResponsibility}`
          }
        )
      }
      if (this.budgetResponsibility) {
        dummyArray.push(
          {
            type: 'textField',
            label: 'Budget Responsibility [USD]',
            class: 'full-width',
            inputType: 'text',
            placeHolder: 'createJd.title5-label4',
            formControlName: 'budgetResponsibility',
            ngIfCondition: false
          }
        )
      }
      this.positionJsonArray.splice(-1, 0, dummyArray)
    }
  }

  onLanguageChange(lang: string) {
    this.langC = lang;
    this.translate.use(lang);
  }


  langC: string = "";
  minYear: string = "createJd.minYear"
  maxYear: string = "createJd.maxYear"
  minAmount: string = "createJd.minAmount"
  maxAmount: string = "createJd.maxAmount"


  jdViewEditback() {
    this._location.back();
  }

  createForm(): void {

    this.regForm = this.formBuilder.group({
      industry: ['', [Validators.required]],
      jobFamily: [''],
      joblevel: [''],
      name: ['', [Validators.required]],
      code: ['', [Validators.required]],
      experienceRange: this.formBuilder.group({
        minimumExperience: [0, [Validators.required]],
        maximumExperience: [0, [Validators.required]],
      }),
      minimumQualification: ['', [Validators.required]],
      additionalQualifications: new FormArray([]),
      key_performance: new FormArray([]),
      jobTitle: ['', [Validators.required]],
      skills: new FormArray([]),
      reportingTo: ['', [Validators.required]],
      salaryRange: [''],
      revenueResponsibility: [''],
      budgetResponsibility: [''],
      workModel: [''],
      purpose_role: ['', [Validators.required]],
      functional_content: ['', [Validators.required]],
      roles_responsibilities: new FormArray([]),
      jdRequest_id: [''],
      additionalFields: this.formBuilder.array([])
    });

  }

  createItem(): FormGroup {
    return this.formBuilder.group({
      heading: ['', [Validators.required]],
      value: ['', [Validators.required]],
    });
  }

  /* INDUSTRY */

  // Fetch all Industry records
  fetchIndustryList(): void {

    this.loading = true;

    this.industryService.fetchIndustryList().subscribe((message) => {

      this.loading = false;
      this.industryData = [...message];
      this.industryDataToShow = message.splice(0, this.industryCurrentIndex);
      this.showLoadMoreBtn = this.industryData.length >= this.industryCurrentIndex ? true : false;

    }, (error) => {

      this.loading = false;
      console.error(error);

    });

  }



  LoadMoreIndustry() {
    this.industryCurrentIndex = this.industryCurrentIndex + 10 <= this.industryData.length ?
      (this.industryCurrentIndex + 10) : this.industryData.length;

    this.industryDataToShow = [...this.industryData.slice(0, this.industryCurrentIndex)];
    this.showLoadMoreBtn = this.industryData.length > this.industryCurrentIndex ? true : false;
    this.crd.detectChanges();
  }


  selectIndustry(industry) {

    this.selectedIndustry = industry;
    this.regForm.value.industry = industry.name;
    /* To Go To JobFamily  */
    this.steps.industry = false;
    this.steps.jobFamily = true;
    this.fetchJobFamilyList()
  }

  goBackIndustry() {
    this._location.back();
  }

  onKeyName(event) {
    if (event.keyCode !== 8) {
      this.errorName = ''
    }
    if (event.keyCode === 8) {
      this.errorName = 'Please Enter Name'
    }
  }
  onKeyCode(event) {
    if (event.keyCode !== 8) {
      this.errorCode = ''
    }
    if (event.keyCode === 8) {
      this.errorCode = 'Please Enter Code';
    }
  }

  /* Job Family */

  backJobFamily() {
    this.fetchIndustryList()
    this.steps.industry = true;
    this.steps.jobFamily = false;
  }


  // Fetch all JobFamily records
  fetchJobFamilyList(): void {

    this.loading = true;

    this.jobFamilyService.fetchJobFamilyList().subscribe((message) => {

      this.loading = false;
      this.jobFamilyData = [...message];
      this.jobFamilyDataToShow = [...this.jobFamilyData.slice(0, this.jobFamilyCurrentIndex)];
      this.showLoadMoreBtnJobFamily = this.jobFamilyData.length > this.jobFamilyCurrentIndex ? true : false;
      this.crd.detectChanges();

    }, (error) => {

      this.loading = false;
      console.error(error);

    });

  }


  LoadMoreJobFamily() {
    this.jobFamilyCurrentIndex = this.jobFamilyCurrentIndex + 10 <= this.jobFamilyData.length ?
      (this.jobFamilyCurrentIndex + 10) : this.jobFamilyData.length;

    this.jobFamilyDataToShow = [...this.jobFamilyData.slice(0, this.jobFamilyCurrentIndex)];
    this.showLoadMoreBtnJobFamily = this.jobFamilyData.length > this.jobFamilyCurrentIndex ? true : false;
    this.crd.detectChanges();
  }


  selectjobFamily(jobFamily) {

    this.selectedjobFamily = jobFamily;
    this.regForm.value.jobFamily = jobFamily.name;
    /* To Go To Job Level */
    this.steps.jobFamily = false;
    this.steps.jobLevel = true;
    this.fetchJobLevelList()

  }

  nextBtnJobLevel() {

    if (!this.selectedjobFamily['name']) {
      this._snackBar.openSnackBar('Please Choose a Job Family', 'danger');
      return;
    }
    // this.steps.jobFamily = false;
    // this.steps.jobLevel = true;
    // this.fetchJobLevelList()

  }

  /* Job Level */

  // Fetch all Job Level records
  fetchJobLevelList(): void {

    this.loading = true;

    this.jobLevelService.fetchJobLevelList().subscribe((message) => {

      this.loading = false;
      this.jobLevelData = [...message];
      this.jobLevelDataToShow = [...message.slice(0, this.jobLevelDataIndex)];
      this.showLoadMoreBtnJobLvl = this.jobLevelData.length > this.jobLevelDataIndex ? true : false;
      this.crd.detectChanges();

    }, (error) => {

      this.loading = false;
      console.error(error);

    });

  }
  LoadMoreJobLvl() {

    this.jobLevelDataIndex = this.jobLevelDataIndex + 10 <= this.jobLevelData.length ?
      (this.jobLevelDataIndex + 10) : this.jobLevelData.length;

    this.jobLevelDataToShow = [...this.jobLevelData.slice(0, this.jobLevelDataIndex)];
    this.showLoadMoreBtnJobLvl = this.jobLevelData.length > this.jobLevelDataIndex ? true : false;
    this.crd.detectChanges();

  }

  backJobLevel() {
    this.steps.jobFamily = true;
    this.steps.jobLevel = false;
    // this.fetchJobFamilyList()
  }

  selectjobLevel(jobLevel) {

    this.regForm.value.joblevel = jobLevel.name;
    this.selectedjobLevelData = jobLevel;

    /* To Go To Experience Range */
    if (!this.selectedjobLevelData['name']) {
      this._snackBar.openSnackBar('Please Choose a Job Level', 'danger');
      return;
    }
    this.steps.jobLevel = false;
    this.steps.experienceRange = true;
    this.fetchQualificationList();
    this.fetchJobTitleList();
    this.fetchSkillList()

  }

  /* Experience Range */

  backExperience() {

    this.steps.jobLevel = true;
    this.steps.experienceRange = false;

  }

  // Fetch all Qualification records
  fetchQualificationList(): void {

    this.loading = true;

    this.qualificationService.fetchQualificationList().subscribe((message) => {

      this.loading = false;
      this.optionsQualification = message;
      this.filteredOptionsQualification = this.myControlQualification.valueChanges.pipe(
        startWith(''),
        map(value => (typeof value === 'string' ? value : value.name)),
        map(name => (name ? this._filterQua(name) : this.optionsQualification.slice())),
      );


    }, (error) => {

      this.loading = false;
      console.error(error);

    });

  }

  myControlQualification = new FormControl();
  filteredOptionsQualification: Observable<any>;


  displayFnQua(Qualification: any): string {
    return Qualification && Qualification.name ? Qualification.name : '';
  }

  private _filterQua(name: string): any {
    const filterValue = name.toLowerCase();

    return this.optionsQualification.filter(option => option?.name.toLowerCase().includes(filterValue));
  }

  qualificationSelect(qualification) {
    this.selectedQualification = qualification;
  }
  jobTitleSelect(jobTitle) {
    this.selectedjobTitleData = jobTitle;
  }


  /* Job Title */

  myControlTitle = new FormControl();
  filteredOptionsTitle: Observable<any>;

  displayFnTitle(title: any): string {
    return title && title.name ? title.name : '';
  }

  private _filterTitle(name: string): any {
    const filterValue = name.toLowerCase();

    return this.jobTitleData.filter(option => option?.name.toLowerCase().includes(filterValue));
  }
  // Fetch all Job Title records
  fetchJobTitleList(): void {

    this.loading = true;
    let jobFamilyData = { jobFamily: this.selectedjobFamily['_id'] }
    this.jobTitleService.fetchJobTitleListForJD(jobFamilyData).subscribe((message) => {

      this.loading = false;
      this.jobTitleData = message;


      this.filteredOptionsTitle = this.myControlTitle.valueChanges.pipe(
        startWith(''),
        map(value => (typeof value === 'string' ? value : value.name)),
        map(name => (name ? this._filterTitle(name) : this.jobTitleData.slice())),
      );

    }, (error) => {

      this.loading = false;
      console.error(error);

    });

  }


  // Add New Additional  Qualification
  addChips(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.additionalQualifications.push(value);
      const control = <FormArray>this.regForm.get('additionalQualifications');
      control.push(this.formBuilder.control(event.value))
    }
    if (event.input) {
      event.input.value = '';
    }
  }

  // Remove Additional  Qualification
  removeChips(name): void {
    let tagArr = this.regForm.get('additionalQualifications') as FormArray;
    tagArr.removeAt(this.regForm.get('additionalQualifications').value.findIndex(tagname => tagname == name));
    const index = this.additionalQualifications.indexOf(name);
    if (index >= 0) {
      this.additionalQualifications.splice(index, 1);
    }
  }

  /* Skills */

  // Fetch all skill records
  fetchSkillList(): void {

    this.loading = true;

    this.skillService.fetchSkillList().subscribe((message) => {

      this.loading = false;
      this.skillData = message;
      for (let skill of this.skillData) {
        this.selectedSkills[`${skill}`] = false;
      }


    }, (error) => {

      this.loading = false;
      console.error(error);

    });

  }

  formControlSkills = new FormControl(['Skills']);

  changeSelected(event: { selected: any }, skill: { selected: any }): void {

    // this.selectedSkills[`${skill}`] = event.selected;
    if (event.selected) {
      if (this.selectedSkillsArray.indexOf(skill) == -1) {
        this.selectedSkillsArray.push(skill);
      }

    } else {
      let index = this.selectedSkillsArray.indexOf(skill);
      if (index != -1) {
        this.selectedSkillsArray.splice(index, 1);
      }
    }
  }


  /* Reporting To */

  nextBtnReportingTo() {

    this.experienceRange = {
      minimumExperience: this.value,
      maximumExperience: this.highValue
    };
    // this.regForm.value.experienceRange = this.experienceRange;
    if (!this.selectedQualification['name']) {
      this._snackBar.openSnackBar(this.translate.instant('createJd.qualificationError'), 'danger');
      return;
    }
    if (!this.selectedjobTitleData['name']) {
      this._snackBar.openSnackBar(this.translate.instant('createJd.jobitleError'), 'danger');
      return;
    }

    if (!this.selectedSkillsArray.length) {
      this._snackBar.openSnackBar(this.translate.instant('createJd.skillError'), 'danger');
      return;
    }

    this.steps.experienceRange = false;
    this.steps.reportingTo = true;
    this.fetchJobTitleListForReportingTo()
  }


  // Fetch all Job Title records
  fetchJobTitleListForReportingTo(): void {

    this.loading = true;

    this.jobTitleService.fetchJobTitleList().subscribe((message) => {

      this.loading = false;
      this.jobTitleDataForReportingTo = message;


      this.filteredOptionsTitleReporting = this.myControlTitleReporting.valueChanges.pipe(
        startWith(''),
        map(value => (typeof value === 'string' ? value : value.name)),
        map(name => (name ? this._filterTitleReporting(name) : this.jobTitleDataForReportingTo.slice())),
      );

    }, (error) => {

      this.loading = false;
      console.error(error);

    });

  }

  reportingToSelect(reportingTo) {
    this.selectedReportingTo = reportingTo;
  }


  myControlTitleReporting = new FormControl();
  filteredOptionsTitleReporting: Observable<any>;

  displayFnTitleReporting(title: any): string {
    return title && title?.name ? title.name : '';
  }

  private _filterTitleReporting(name: string): any {
    const filterValue = name.toLowerCase();

    return this.jobTitleData.filter(option => option?.name.toLowerCase().includes(filterValue));
  }

  revenueToggle(event) {
    if (event?.checked) {
      this.revenueResponsibility = true;
      this.revenueResponsibility == true ? this.regForm.get('revenueResponsibility').setValidators([Validators.required])
        : this.regForm.get('revenueResponsibility').clearValidators()
    }
    else if (!event?.checked) {
      this.revenueResponsibility = false;
      this.revenueResponsibility == false ? this.regForm.controls['revenueResponsibility'].setValue('') : '';
    }

  }
  budgeToggle(event) {
    if (event?.checked) {
      this.budgetResponsibility = true;
      this.budgetResponsibility == true ? this.regForm.get('budgetResponsibility').setValidators([Validators.required])
        : this.regForm.get('budgetResponsibility').clearValidators()
    }
    else if (!event?.checked) {
      this.budgetResponsibility = false;
      this.budgetResponsibility == false ? this.regForm.controls['budgetResponsibility'].setValue('') : '';
    }
  }

  backReportTo() {

    this.steps.experienceRange = true;
    this.steps.reportingTo = false;

  }

  onChangeButtonToggle(event) {
    this.selectedWorkModel = event.value;
  }

  /* Submit Data */

  submitData() {

    this.regForm.value.skills = this.selectedSkillsArray;

    if (!this.selectedReportingTo['name']) {
      this._snackBar.openSnackBar(this.translate.instant('createJd.reportingToError'), 'danger');
      return;
    }
    if (!this.selectedWorkModel) {
      this._snackBar.openSnackBar(this.translate.instant('createJd.workModelError'), 'danger');
      return;
    }



    let dummyDataObject: JdRequestS = {

      industry: this.selectedIndustry['name'],
      name: this.regForm.value.name,
      code: this.regForm.value.code,
      jobFamily: this.selectedjobFamily['name'],
      joblevel: this.selectedjobLevelData['name'],
      experienceRange: this.experienceRange,
      minimumQualification: this.selectedQualification['name'],
      jobTitle: this.selectedjobTitleData['name'],
      reportingTo: this.selectedReportingTo['name'],
      salaryRange: this.Rvalue,
      revenueResponsibility: this.revenueResponsibility ? this.RevValue : null,
      budgetResponsibility: this.budgetResponsibility ? this.bValue : null,
      skill: this.selectedSkillsArray,
      workModel: this.selectedWorkModel,
      additionalQualifications: this.regForm.value.additionalQualifications
    }

    this.JobDescriptionService.createJobDescriptionRequest(dummyDataObject).subscribe((result) => {

      console.log(result);
      if (result?.message === 'success') {

        // Manupulate Location Array Based on User selections
        this.createPositionArray()

        if (result?.jd) {
          result['jd'] ? this.regForm.patchValue(result['jd']) : null;
          this.jdRequest_id = result['jd']._id;
          this.skillDataToView = result['jd'].skill.join(' , ');
        }
        if (result?.jdData) {

          result['jdData'] ? this.regForm.patchValue(result.jdData[0]) : null;

          this.key_performance = result.jdData[0].key_performance;
          const control = <FormArray>this.regForm.get('key_performance')
          this.key_performance.forEach(val => control.push(this.formBuilder.control(val)));
          this.regForm.controls['key_performance'].setValue(this.key_performance)

          this.roles_responsibilities = result.jdData[0].roles_responsibilities;
          const controlRoles = <FormArray>this.regForm.get('roles_responsibilities')
          this.roles_responsibilities.forEach(val => controlRoles.push(this.formBuilder.control(val)));
          this.regForm.controls['roles_responsibilities'].setValue(this.roles_responsibilities)
        }
        if (result?.team) {
          this.organisationFavicon = result.team.favicon;
        }
        this.steps.viewJd = true;
        this.steps.reportingTo = false;
        this.jdCreatedData = result['jdData'];
      }

    })

  }

  addNewColomnToListRole() {
    this.key_performance.push('');
    const control = <FormArray>this.regForm.get('roles_responsibilities');
    control.push(this.formBuilder.control('', [Validators.required]))
  }

  removeFromListRole(index) {

    let keyPerformanceArray = this.regForm.get('roles_responsibilities') as FormArray;
    keyPerformanceArray.removeAt(index);
  }

  addNewColomnToList() {
    this.key_performance.push('');
    const control = <FormArray>this.regForm.get('key_performance');
    control.push(this.formBuilder.control('', [Validators.required]))
  }

  removeFromList(index) {

    let keyPerformanceArray = this.regForm.get('key_performance') as FormArray;
    keyPerformanceArray.removeAt(index);
  }

  addNewColomn() {
    const column = this.regForm.get('additionalFields') as FormArray;
    column.push(this.createItem());

    this.positionJsonArray.push([this.positionoFDFields(this.dynamicArrayIndex)]);
    console.log(this.dynamicArrayIndex, 'index');
  }

  positionoFDFields(length: number) {
    this.dynamicArrayIndex++
    return {
      type: 'additionalFields',
      formGroupName: length,
      formArrayName: 'additionalFields',
      formControlName1: 'heading',
      placeHolder1: 'createJd.title11PlaceHolder1',
      formControlName2: 'value',
      placeHolder2: 'createJd.title11PlaceHolder2'

    }

  }

  deleteAddedRow(index, data) {

    const column = this.regForm.get('additionalFields') as FormArray;
    this.positionJsonArray.forEach(element => {

      if (element.indexOf(data) != -1) {

        let index = this.positionJsonArray.indexOf(element);
        if (this.positionJsonArray[index].length === 1) {
          this.positionJsonArray.splice(this.positionJsonArray.indexOf(element), 1);
        }
        else if (this.positionJsonArray[index].length > 1) {
          this.positionJsonArray[index].splice(element.indexOf(data), 1);
        }

      }
    })
    column.removeAt(index);

    this.positionJsonArray.forEach((element: Array<any>, indexValue: number) => {

      element.forEach((data, ind) => {
        if (data?.formGroupName && data.formGroupName > index) {
          this.positionJsonArray[indexValue][ind].formGroupName--
        }
      })
    })
    this.dynamicArrayIndex--
  }

  addNewFieldsToArray(data) {

    const column = this.regForm.get('additionalFields') as FormArray;
    column.push(this.createItem());
    this.positionJsonArray.forEach(element => {

      let index
      if (element.indexOf(data) != -1) {
        index = this.positionJsonArray.indexOf(element);
        this.positionJsonArray[index].push(this.positionoFDFields(this.dynamicArrayIndex))

      }
    })
    console.log(this.dynamicArrayIndex, 'index');

  }

  findDynamicFieldIndex() {

    const column = this.regForm.get('additionalFields') as FormArray;
    this.dynamicArrayIndex = column.length;
    console.log(this.dynamicArrayIndex, 'index');


  }

  saveJD() {

    this.regForm.value.skills = this.skillDataToView.split(' , ');
    this.regForm.value.organisation = this.jdCreatedData.organisation
    this.regForm.value.jdRequest_id = this.jdRequest_id;
    this.regForm.value.positionData = this.positionJsonArray;

    if (!<FormArray>this.regForm.get('key_performance').value.length) {
      this._snackBar.openSnackBar(this.translate.instant('createJd.nullFieldError'), 'danger');
      return
    }
    if (!<FormArray>this.regForm.get('roles_responsibilities').value.length) {
      this._snackBar.openSnackBar(this.translate.instant('createJd.nullFieldError'), 'danger');
      return
    }
    this.regForm.value['favicon'] = this.organisationFavicon
    this.loading = true

    if (this.regForm.valid) {
      this.JobDescriptionService.saveJobDescription(this.regForm.value).subscribe((result) => {
        console.log(result)
        this.uniqueCode = result.uniqueCode;
        if (result.message === 'success') {
          this._snackBar.openSnackBar(this.translate.instant('createJd.successMsg'), 'success');
          this.permissionForEditJd = true;
          this.menuPermissions = false;
          this.loading = false
        }
        this.loading = false
      })

    } else {
      this._snackBar.openSnackBar(this.translate.instant('createJd.nullFieldError'), 'danger');
    }

  }

  shareJDLink() {

    const link = location.href.replace(this.router.url, '')
    let component = { name: 'linkshare', link: `${link}/${this.uniqueCode}` }
    this.dialog.open(InviteDialogeComponent, { data: component });

  }

  // Load Image

  exportAsPDF() {
    exportJobDiscriptionAs.exportAsPDF(this.regForm.value, this.organisationFavicon, this.revenueResponsibility,
      this.budgetResponsibility, this.skillDataToView)
  }


  exportAsDocx() {

    exportJobDiscriptionAs.exportAsDocx(this.regForm.value, this.jdLogo, this.organisationLogo, this.organisationFavicon,
      this.revenueResponsibility, this.budgetResponsibility, this.skillDataToView)
    1
  }

  fetchJobDescription(code: string) {

    this.loading = true;
    this.JobDescriptionService.fetchJobDescription({ code: code }).subscribe((result) => {

      console.log(result);

      this.jdCreatedData = result?.jd;
      this.isApproved = result?.jd?.workFlowApproved
      this.steps.viewJd = true;
      this.steps.industry = false;
      this.menuPermissions = false;
      this.editModeForJdView = true;
      this.permissionForEditJd = true;

      if (result?.jd) {

        this.editModeForJdView = true;
        this.permissionForEditJd = this.jdListEdit ? false : true;

        result['jd'] ? this.regForm.patchValue(result['jd']) : null;
        this.jdRequest_id = result['jd']._id;
        this.skillDataToView = result['jd'].skills.join(' , ');

        this.key_performance = result.jd.key_performance;
        const control = <FormArray>this.regForm.get('key_performance')
        this.key_performance.forEach(val => control.push(this.formBuilder.control(val)));
        this.regForm.controls['key_performance'].setValue(this.key_performance)

        this.roles_responsibilities = result['jd'].roles_responsibilities;
        const controlRoles = <FormArray>this.regForm.get('roles_responsibilities')
        this.roles_responsibilities.forEach(val => controlRoles.push(this.formBuilder.control(val)));
        this.regForm.controls['roles_responsibilities'].setValue(this.roles_responsibilities);

        this.budgetResponsibility = this.regForm.value.budgetResponsibility != null ? true : false;
        this.revenueResponsibility = this.regForm.value.revenueResponsibility != null ? true : false;;
        this.organisationFavicon = result.jd.favicon;

        this.bindData(result.jd.additionalFields)
        this.positionJsonArray = result?.jd?.positionData;
        this.findDynamicFieldIndex();
        this.loading = false;
      }


    })

  }

  bindData(data) {

    let arr = data;
    let control = this.regForm.get('additionalFields') as FormArray;
    while (control.length) {
      control.removeAt(0);
    }
    this.items = arr;
    arr.forEach((x, i) => {
      control.push(this.formBuilder.group({
        heading: x.heading,
        value: x.value
      }));
    });

  }

  editJD() {
    if (this.regForm.valid) {

      this.loading = true;

      this.regForm.value.skills = this.skillDataToView.split(' , ');

      this.regForm.value._id = this.jdCreatedData._id;

      this.regForm.value.positionData = this.positionJsonArray;

      this.JobDescriptionService.updateJobDescription(this.regForm.value).subscribe(result => {
        this.loading = false;
        if (result.message === 'success') {

          this._snackBar.openSnackBar(this.translate.instant('createJd.updateSuccessMsg'), 'success');
        }
      })
    }
    else {
      this._snackBar.openSnackBar(this.translate.instant('createJd.nullFieldError'), 'danger');
    }
  }

  editShareJD() {
    this.permissionForEditJd = false;
    this.jdListEdit = 'false';

  }

  rejectJD() {
    console.log('rejected');
    const jdDataReject = { id: this.jdCreatedData?._id, code: this.jdCreatedData?.uniqueCode }
    this.workFlowService.updateWorkFlowStatusReject(jdDataReject).subscribe(message => {
      console.log(message);
      if (message['message'] === 'success') {
        this._snackBar.openSnackBar(this.translate.instant('createJd.rejectSucessMsg'), 'success');
        this._location.back();
      }
      else {
        this._snackBar.openSnackBar(this.translate.instant('createJd.rejectErrorMsg'), 'danger');
      }

    })

  }
  approveJD() {
    console.log('approve');
    const jdData = { id: this.jdCreatedData?._id, code: this.jdCreatedData?.uniqueCode }
    this.workFlowService.updateWorkFlowStatusApprove(jdData).subscribe(message => {
      console.log(message);
      if (message['message'] === 'success') {
        this._snackBar.openSnackBar(this.translate.instant('createJd.approveSuccessMsg'), 'success');
        this._location.back();
      } else {
        this._snackBar.openSnackBar(this.translate.instant('createJd.approveErrorMsg'), 'danger');
      }

    })

  }

}