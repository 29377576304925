/* eslint-disable dot-notation */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable id-blacklist */
/* eslint-disable no-extra-parens */
import { AfterViewInit, Component, ViewChild, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SnackbarService } from 'src/app/helpers/snackbar.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { OrganisationManagementService } from '../../services/organisation-management.service'
import { OrganisationManagementDialogComponent } from './organisation-management-dialog/organisation-management-dialog.component'
import { IndustryService } from '@atmeservices/industry.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { InvitationManagementService } from '@atmeservices/invitation-management.service';
import { DialogBoxComponent } from 'src/app/components/dialog-box/dialog-box.component';
import { CommunicationService } from '@atmeservices/communication.service';
import { TranslateService } from '@ngx-translate/core';



@Component({
  selector: 'app-organisation-management',
  templateUrl: './organisation-management.component.html',
  styleUrls: ['./organisation-management.component.scss']
})
export class OrganisationManagementComponent implements OnInit {

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild('invitePaginator') paginatorInvite: MatPaginator;

  displayedColumns: string[] = ['name', 'email', 'jobTitle', 'mobile'];

  dataSource = new MatTableDataSource<any>();

  displayedColumnsInvitation: string[] = ['email', 'invited-time', 'status', 'accepted-time', 'action'];

  dataSourceInvitation = new MatTableDataSource<any>();

  organisationId: any;

  loading = false;

  membersData = [];

  invitationData

  constructor(
    public dialog: MatDialog,
    private _snackBar: SnackbarService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private _location: Location,
    private translate: TranslateService,
    private communicationService: CommunicationService,
    private readonly organisationService: OrganisationManagementService,
    private readonly invitationService: InvitationManagementService,
    private router: Router) { }

  ngOnInit(): void {

    this.organisationId = this.route.snapshot.queryParamMap.get('id');
    this.fetchOrganisationMembers()
    this.fetchInvitationsOfOrganisation();
    this.communicationService.getValueForInvitation().subscribe((value) => {
      this.fetchInvitationsOfOrganisation()
    })
    this.communicationService.getLanguage().subscribe((value) => {
      this.onLanguageChange(value)
    })
    
  }
  onLanguageChange(lang: string) {
    this.langC = lang;
    this.translate.use(lang);
  }

  langC: string = "";
  title1: string = "organisation.title1";
  button1: string = "organisation.button1";
  button2: string = "organisation.button2";
  title1A: string = "organisation.title1A";
  search: string = "tableSearchPlaceHolder";
  title1Label1: string = "organisation.title1Label1";
  title1Label2: string = "organisation.title1Label2";
  title1Label3: string = "organisation.title1Label3";
  title1Label4: string = "organisation.title1Label4";
  title2: string = "organisation.title2";
  title2Label1: string = "organisation.title2Label1";
  title2Label2: string = "organisation.title2Label2";
  title2Label3: string = "organisation.title2Label3";
  title2Label4: string = "organisation.title2Label4";
  title2Label5: string = "organisation.title2Label4";
  title2ToolTip: string = "organisation.title2ToolTip";
  tableNullData: string = "noData-table-error";
  revokeTitle: string = "organisation.revoke-Title";
  revokeMsg1: string = "organisation.revoke-Msg1";
  revokeMsg2: string = "organisation.revoke-Msg2";
  successMsg: string = "organisation.successMsg1";
  errorMsg: string = "organisation.errorMsg";
  paginatorLabel: string = "paginatorLabel";



  viewOrganization() {
    const organisationData = { component: 'view' }
    const dialogRef = this.dialog.open(OrganisationManagementDialogComponent, { data: organisationData });
  }
  updateOrganization() {
    const organisationData = { component: 'update' }
    const dialogRef = this.dialog.open(OrganisationManagementDialogComponent, { data: organisationData });
    dialogRef.afterClosed().subscribe((result) => {
      this.communicationService.setValueForOrganisation(true)
    })
  }

  fetchOrganisationMembers() {

    this.organisationService.fetchOrganisationMembers().subscribe((members) => {

      this.membersData = members?.users ? members?.users : '';
      this.dataSource = new MatTableDataSource(this.membersData);
      this.dataSource.paginator = this.paginator;


    })

  }
  fetchInvitationsOfOrganisation() {

    this.invitationService.fetchInvitationOfOrganisation().subscribe((invitation) => {

      this.invitationData = invitation['Result'] ? invitation['Result'] : '';
      this.dataSourceInvitation = new MatTableDataSource(this.invitationData);
      this.dataSourceInvitation.paginator = this.paginatorInvite;



    })

  }

  revokeInvitation(invitationData) {


    const confirmDialog = this.dialog.open(DialogBoxComponent, {
      data: {
        title: this.translate.instant(this.revokeTitle),
        message: `${this.translate.instant(this.revokeMsg1)} "${invitationData.invitedUserMailId}" ${this.translate.instant(this.revokeMsg2)}`
      }
    });
    confirmDialog.afterClosed().subscribe((result) => {

      if (result === true) {

        this.invitationService.revokeInvitation({ id: invitationData._id }).subscribe(result => {

          console.log(result);
          if (result['message'] === 'success') {

            this._snackBar.openSnackBar(this.translate.instant(this.successMsg), 'success');
            this.fetchInvitationsOfOrganisation()
          }
          else {
            this._snackBar.openSnackBar(this.translate.instant(this.errorMsg), 'error');
          }

        })

      }
    })



  }

  applyFilterInvite(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceInvitation.filter = filterValue.trim().toLowerCase();
  }
  applyFilterMember(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
