<div [class.overlay]="loading" [class.spinner]="loading" (click)="$event.stopPropagation();"></div>
<!-- <div>
    <mat-icon class="model-close-icon" mat-dialog-close>close</mat-icon>
</div> -->
<div *ngIf="isInvite">

    <!-- <mat-icon class="model-close-icon" mat-dialog-close>close</mat-icon> -->
    <mat-dialog-content class="mat-typography">
        <mat-icon class="model-close-icon" mat-dialog-close>close</mat-icon>
     

            <h2 class="title">{{inviteTitle | translate}}</h2>
           
            <form [formGroup]="regForm" class="invite-form">
                <div class="form-record">
                    <mat-label class="emailLabel">{{invitelabel | translate}}</mat-label>
                    <mat-form-field appearance="none" class="invite-mat-form-field">
                        <mat-chip-list #chipList aria-label="Email">
                            <mat-chip *ngFor="let name of email" [selectable]="selectable" [color]="accent"
                                [removable]="removable" (removed)="removeChips(name)" formArrayName="email">
                                {{name}}
                                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                            </mat-chip>
                            <input placeholder="{{inviteplaceHolder | translate}}" [matChipInputFor]="chipList"
                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                                (matChipInputTokenEnd)="addChips($event)">
                        </mat-chip-list>

                    </mat-form-field>
                </div>
            </form>
            <p *ngIf="validEmail" class="error">{{inviteinvalidError | translate}}</p>
            <p *ngIf="emailError && emailExistError" class="error">{{inviteuserExistError | translate}}</p>
            <p *ngIf="inputFieldNullError" class="error">{{inviteNullError | translate}}</p>

     

    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <!-- <button mat-button mat-dialog-close class="button-close" data-backdrop="static" data-keyboard="false">Close</button> -->
        <button (click)=sendInvitation() mat-button class="button-invite">{{inviteBtnText | translate}}</button>
    </mat-dialog-actions>


    <div class="invite-via">INVITE VIA</div>

        <div class="social-icons">
            <div class="social-card">
                <mat-icon class="fb"><img src="../../../assets/images/fb-icon.png"></mat-icon>
            </div>
            <div class="social-card">
                <mat-icon class="fb"><img src="../../../assets/images/linkedin-icon.png"> </mat-icon>
            </div>
        </div>

</div>

<div class="linkShare" *ngIf="isLinkShare">
    <mat-dialog-content class="mat-typography">
      
            <h3>{{shareJdTitle | translate}}</h3>

            <div>
                <mat-form-field appearance="none">

                    <input type="text" matInput [(ngModel)]="linkValue" #userinput readonly>
                    <button class="clipboard_btn" (click)="copyInputMessage(userinput)"
                        matTooltip="{{shareToolTip | translate}}" type="button" mat-icon-button color="primary">
                        <mat-icon>filter_none</mat-icon>
                    </button>
                </mat-form-field>

            </div>

    </mat-dialog-content>

</div>