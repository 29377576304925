<div [class.overlay]="loading" [class.spinner]="loading" (click)="$event.stopPropagation();"></div>
<!-- <div class="header-container">
    <h2 class="header">
        {{title | translate}}
    </h2>
    
</div>
 -->
<div class="main-wrap">

    <div class="sub-header-div">
        <form class="search-form">
            <mat-form-field class="search-full-width" appearance="none">
                <mat-icon matSuffix>search</mat-icon>
                <div class="search-box">
                    <input type="text" matInput placeholder="{{'Search' | translate}}" (keyup)="applyFilter($event)" #input>
                </div>
            </mat-form-field>
        </form>
        <button class="add-btn2" (click)="createQualification()" mat-button matTooltip="{{addBtnToolTip | translate}}">
            <mat-icon class="button-icon"><img src="../../../assets/images/add-icon.png"></mat-icon> {{addBtn | translate}}
        </button>
    </div>

    <mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>


        <!-- First name Column -->
        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{label1 | translate}} </mat-header-cell>
            <mat-cell class="inputFieldOverflow" *matCellDef="let element"> {{element.name}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="status">{{'Status' | translate}} </mat-header-cell>
            <mat-cell class="inputFieldOverflow" *matCellDef="let element"> <mat-slide-toggle [aria-label]="isSubscribedToEmailsMessage">
            </mat-slide-toggle> </mat-cell>
        </ng-container>

        <!-- Action Column -->
        <ng-container matColumnDef="action" >
            <mat-header-cell *matHeaderCellDef class="action"> {{label2 | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let element">
                <div style="display: flex;">
                    <mat-icon matTooltip="{{viewToolTip | translate}}" (click)="viewQualification(element)"
                        class="mat-action-icon">visibility
                    </mat-icon>
                    <mat-icon matTooltip="{{editToolTip | translate}}" (click)="editQualification(element._id)"
                        class="mat-action-icon">
                        edit
                    </mat-icon>
                    <mat-icon id="deletebtn" (click)="deleteQualification(element)"
                        matTooltip="{{deleteToolTip | translate}}" class="mat-action-icon">
                        delete_outline</mat-icon>
                </div>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        <!-- <mat-row *matRowDef="let row; columns: displayedColumns;"
          (click)="selection.toggle(row)">
        </mat-row> -->

        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell no-data" colspan="2">{{nullData-table-error | translate}} "{{input.value}}"</td>
        </tr>
    </mat-table>
    <mat-paginator [length]="100" [pageSize]="5" [pageSizeOptions]="[5, 10, 25, 100]">
    </mat-paginator>
</div>