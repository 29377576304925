/* eslint-disable dot-notation */
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { BASE_URI, ORGANISATION_API, USER_API } from '@atme/serverAPI';
import { OrganisationS } from '@atme/entity/organisation';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class OrganisationManagementService {

  constructor(
    private readonly http: HttpClient
  ) { }

  createOrganisation(organisation: OrganisationS): Observable<any> {

    return this.http.post<OrganisationS>(`${BASE_URI}${ORGANISATION_API}`, organisation).pipe(
      catchError((err) => throwError(err))
    );

  }

  updateOrganisation(organisation: OrganisationS, organisationId: string): Observable<any> {

    return this.http.put<OrganisationS>(`${BASE_URI}${ORGANISATION_API}`, {
      params: organisation, organisationId
    }).pipe(
      catchError((err) => throwError(err))
    );

  }

  deleteOrganisationById(organisationId: any): Observable<unknown> {

    return this.http.delete(`${BASE_URI}${ORGANISATION_API}`, { params: organisationId }).pipe(
      catchError((err) => throwError(err))
    );

  }

  fetchOrganisationList(): Observable<any> {

    return this.http.get(`${BASE_URI}${ORGANISATION_API}`).pipe(
      catchError((err) => throwError(err))
    );

  }

  fetchOrganisationById(organisationData: any): Observable<any> {

    return this.http.get(`${BASE_URI}${ORGANISATION_API}/id`, { params: organisationData }).pipe(
      catchError((err) => throwError(err))
    );

  }
  fetchOrganisationForEdit(): Observable<any> {

    return this.http.get(`${BASE_URI}${ORGANISATION_API}/edit`).pipe(
      catchError((err) => throwError(err))
    );

  }
  fetchOrganisationByOrganisationId(organisationData: any): Observable<any> {

    return this.http.get(`${BASE_URI}${ORGANISATION_API}/organisationId`, { params: organisationData }).pipe(
      catchError((err) => throwError(err))
    );

  }
  fetchOrganisationName(): Observable<any> {

    return this.http.get(`${BASE_URI}${ORGANISATION_API}/organisationName`).pipe(
      catchError((err) => throwError(err))
    );

  }
  fetchOrganisationMembers(): Observable<any> {

    return this.http.get(`${BASE_URI}${USER_API}/members`).pipe(
      catchError((err) => throwError(err))
    );

  }

}