<div>
    <mat-icon class="model-close-icon" mat-dialog-close>close</mat-icon>
</div>

<div class="page-heading">
    <h1>{{title | translate}}</h1>
</div>

<mat-dialog-content class="mat-typography">



        <div class="dashboard-container">
            <mat-label>{{text1 | translate}}</mat-label>
            <mat-form-field (click)="$event.stopPropagation()" class="example-full-width" appearance="fill">
                <input type="text" matInput (keyup)="handleWorkFlowSelect($event)" [matAutocomplete]="auto"
                    placeholder="{{text1 | translate}}" required>

            </mat-form-field>
            <mat-error *ngIf="isError">{{text2 | translate}}</mat-error>

            <mat-autocomplete (optionSelected)="workFlowOnSelect($event.option.value)"
                (click)="$event.stopPropagation()" #auto="matAutocomplete" [displayWith]="displayFn">
                <div class="custome-toolTip-wrapper">
                    <mat-option class="mat-options" *ngFor="let option of filteredArray" [value]="option">
                        {{option.name}}
                    </mat-option>
                </div>
            </mat-autocomplete>
            <div class="workflow-participents" *ngFor="let participant of selectedFlow">

                <div class="reviewers-approvers">
                    <h3 class="review-header">{{flowDetail1 | translate}}</h3>

                    <div style="display: flex;
                    justify-content: space-evenly;" *ngFor="let data of participant?.participateData">
                        <div class="reviewers" *ngIf="data.type === 'reviewer'">
                            {{returnUserName(data,participant)}}
                        </div>
                    </div>
                </div>
                <div class="reviewers-approvers">
                    <h3 class="review-header">{{flowDetail2 | translate}}</h3>

                    <div style="display: flex;
                    justify-content: space-evenly;" *ngFor="let data of participant?.participateData">

                        <div class="reviewers" *ngIf="data.type === 'approver'">
                            {{returnUserName(data,participant)}}
                        </div>
                        <div class="reviewers" *ngIf="data.type === 'approver'">
                            {{returnUserLevel(data,participant)}}
                        </div>
                    </div>
                </div>

                <!-- <div class="approvers" *ngFor="let approver of workFlowApprovers">
                    {{approver?.name}}
                </div> -->
            </div>

        </div>
        <!-- *ngFor="let reviewer of workFlowReviewers" -->



</mat-dialog-content>



<mat-dialog-actions align="center">
    <button mat-button mat-dialog-close class="button-close" data-backdrop="static"
        data-keyboard="false">{{cancelBtnTitle | translate}}</button>
    <button *ngIf="!isEdit" mat-button class="button-close" (click)="assignWorkFlow()">{{saveBtnTitle |
        translate}}</button>
    <button *ngIf="isEdit" mat-button class="button-close" (click)="updateIndustry()">{{updateBtnTitle |
        translate}}</button>
</mat-dialog-actions>