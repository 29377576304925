<div [class.overlay]="loading" [class.spinner]="loading" (click)="$event.stopPropagation();"></div>

<div *ngIf="!isCreateOrganisation">
    <mat-card>
        <div class="editBtnDiv">
            <mat-icon mat-dialog-close>close</mat-icon>
        </div>
        <mat-card-content>
            <form [formGroup]="regForm">
                <div class="outer-form-container">

                    <mat-card-subtitle>{{title1 | translate}}</mat-card-subtitle>
                    <hr>
                    <div class="inner-form-container">

                        <div class="form-field-wrapper">

                            <div class="form-record" style="display: flex;align-items: center;">
                                <mat-label>{{label1 | translate}}</mat-label>
                                <div class="profile-wrapper">
                                    <img id="profile-pic" mat-card-image
                                        [src]="favicon ? favicon : '../../../../assets/images/favicon-team.jpg'"
                                        width="10%">
                                    <label *ngIf="!isEditable" class="uploadbtn" for="pic-uploader">{{changeBtn |
                                        translate}}</label>
                                    <input id="pic-uploader" type="file" (change)="selectFile($event);"
                                        accept="image/*">

                                </div>
                            </div>

                            <div class="form-record">
                                <mat-label>{{label2 | translate}} </mat-label>
                                <mat-form-field appearance="none">
                                    <input [readonly]="isEditable" type="text" matInput
                                        placeholder="{{label2PlaceHolder | translate}}" formControlName="name" required>
                                    <mat-error
                                        *ngIf="regForm.touched||regForm.dirty && regForm.get('name').hasError('required')">
                                        {{label2Error | translate}} </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="form-record">
                                <mat-label>{{label3 | translate}}</mat-label>
                                <mat-form-field appearance="none">
                                    <input [readonly]="isEditable" type="text" matInput
                                        placeholder="{{label3PlaceHolder | translate}}"
                                        formControlName="organisation_Id">
                                    <mat-error
                                        *ngIf="regForm.touched||regForm.dirty && regForm.get('organisation_Id').hasError('required')">
                                        {{label3Error | translate}} </mat-error>
                                </mat-form-field>

                            </div>
                            <p class="error" *ngIf="errorMsg">{{errorMsg}}</p>

                            <div *ngIf="isEditable" class="form-record">
                                <mat-label>{{label4 | translate}}</mat-label>
                                <mat-form-field appearance="none">
                                    <input [readonly]="isEditable" type="text" matInput
                                        formControlName="organisation_discovery">
                                </mat-form-field>
                                <!-- <mat-slide-toggle (change)="btnToggle($event)">
                                </mat-slide-toggle> -->
                            </div>
                            <div *ngIf="!isEditable" class="form-record">
                                <mat-label>{{label4 | translate}}</mat-label>
                                <mat-slide-toggle [checked]="isChecked?true:false" (change)="btnToggle($event)">
                                </mat-slide-toggle>
                            </div>

                            <div class="form-record form-record-reportingTo">
                                <mat-label>{{label5 | translate}}</mat-label>

                                <mat-form-field appearance="none">
                                    <input [readonly]="isEditable" placeholder="{{label5PlaceHolder | translate}}"
                                        type="text" matInput [formControl]="myControlIndustry" [matAutocomplete]="autoI"
                                        required>
                                    <mat-autocomplete #autoI="matAutocomplete" [displayWith]="displayFnIndustry"
                                        (optionSelected)="industryToSelect($event.option.value)">
                                        <mat-option *ngFor="let optionR of filteredOptionsIndustry | async"
                                            [value]="optionR">
                                            {{optionR.name}}
                                        </mat-option>
                                    </mat-autocomplete>
                                    <mat-error *ngIf="myControlIndustry.status =='INVALID'">
                                        {{label5Error | translate}} </mat-error>
                                </mat-form-field>

                            </div>
                            <div class="form-record">
                                <mat-label>{{label6 | translate}} </mat-label>
                                <mat-form-field appearance="none">
                                    <textarea cdkTextareaAutosize [readonly]="isEditable" type="text" matInput
                                        placeholder="{{label6PlaceHolder | translate}}"
                                        formControlName="description"></textarea>
                                </mat-form-field>
                            </div>
                        </div>


                    </div>
                </div>

                <div *ngIf="!isEditable" class="button-div">
                    <div class="button-cancel">
                        <button mat-dialog-close mat-button>{{cancelBtn | translate}}</button>
                    </div>
                    <div class="button">
                        <button (click)="editOrganisation()" type="submit" mat-button>{{updateBtn | translate}}</button>
                    </div>
                </div>


            </form>

            <!-- <button (click)="dialogeBox()">click me</button> -->
        </mat-card-content>
    </mat-card>
</div>


<div class="createOrganisation" *ngIf="isCreateOrganisation">

    <mat-card-content>
        <form [formGroup]="regForm">
            <div class="outer-form-container">

                <h1 style="text-align: center;">{{title2 | translate}}</h1>
                <hr>
                <div class="inner-form-container">

                    <div class="form-field-wrapper">

                        <div class="form-record" style="display: flex;align-items: center;">
                            <mat-label>{{label1 | translate}}</mat-label>
                            <div class="profile-wrapper">
                                <img id="profile-pic" mat-card-image
                                    [src]="favicon ? favicon : '../../../../assets/images/favicon-team.jpg'"
                                    width="10%">
                                <label class="uploadbtn" for="pic-uploader">{{changeBtn | translate}}</label>
                                <input id="pic-uploader" type="file" (change)="selectFile($event);" accept="image/*">

                            </div>
                        </div>

                        <div class="form-record">
                            <mat-label>{{label2 | translate}} </mat-label>
                            <mat-form-field appearance="none">
                                <input type="text" matInput placeholder="{{label2PlaceHolder | translate}}"
                                    formControlName="name" required>
                                <mat-error
                                    *ngIf="regForm.touched||regForm.dirty && regForm.get('name').hasError('required')">
                                    {{label2Error | translate}} </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="form-record">
                            <mat-label>{{label3 | translate}}</mat-label>
                            <mat-form-field appearance="none">
                                <input type="text" matInput placeholder="{{label3PlaceHolder | translate}}"
                                    formControlName="organisation_Id">
                                <mat-error
                                    *ngIf="regForm.touched||regForm.dirty && regForm.get('organisation_Id').hasError('required')">
                                    {{label3Error | translate}}</mat-error>
                            </mat-form-field>

                        </div>
                        <p class="error" *ngIf="errorMsg">{{errorMsg}}</p>

                        <div class="form-record">
                            <mat-label>{{label4 | translate}}</mat-label>
                            <mat-slide-toggle [checked]="isChecked?true:false" (change)="btnToggle($event)">
                            </mat-slide-toggle>
                        </div>

                        <div class="form-record form-record-reportingTo">
                            <mat-label>{{label5 | translate}}</mat-label>

                            <mat-form-field appearance="none">
                                <input placeholder="{{label5PlaceHolder | translate}}" type="text" matInput
                                    [formControl]="myControlIndustry" [matAutocomplete]="autoI" required>
                                <mat-autocomplete #autoI="matAutocomplete" [displayWith]="displayFnIndustry"
                                    (optionSelected)="industryToSelect($event.option.value)">
                                    <mat-option *ngFor="let optionR of filteredOptionsIndustry | async"
                                        [value]="optionR">
                                        {{optionR.name}}
                                    </mat-option>
                                </mat-autocomplete>
                                <mat-error *ngIf="myControlIndustry.status =='INVALID'">
                                    {{label5Error | translate}}</mat-error>
                            </mat-form-field>

                        </div>
                        <div class="form-record">
                            <mat-label>{{label6 | translate}} </mat-label>
                            <mat-form-field appearance="none">
                                <textarea cdkTextareaAutosize type="text" matInput
                                    placeholder="{{label6PlaceHolder | translate}}"
                                    formControlName="description"></textarea>
                            </mat-form-field>
                        </div>
                    </div>


                </div>
            </div>
        </form>

    </mat-card-content>
    <mat-dialog-actions align="center">
        <button mat-button mat-dialog-close class="button-close" data-backdrop="static"
            data-keyboard="false">{{cancelBtn | translate}}</button>
        <button mat-button class="button-close" (click)="createOrganisation()">{{saveBtn | translate}}</button>
    </mat-dialog-actions>
</div>