/* eslint-disable id-blacklist */
/* eslint-disable dot-notation */
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CommunicationService } from '@atmeservices/communication.service';
import { JobDescriptionService } from '@atmeservices/job-description.service';
import { WorkFlowService } from '@atmeservices/work-flow.service';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from 'src/app/helpers/snackbar.service';


const resizeWidth = 425;
const columnNumber = 2;


@Component({
  selector: 'app-dashboard-dialogue',
  templateUrl: './dashboard-dialogue.component.html',
  styleUrls: ['./dashboard-dialogue.component.scss']
})
export class DashboardDialogueComponent implements OnInit {

  breakpoint: number;

  regForm: FormGroup;

  isEdit = false;

  loading: boolean;

  isError = false;

  flowDataArray: any = [];

  filteredArray: any = [];

  jdId: string;

  jdName: string;

  selectedWorkFlow;

  workFlowApprovers = [];

  workFlowReviewers = [];

  selectedFlow: any;


  constructor(
    public dialogRef: MatDialogRef<DashboardDialogueComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private route: ActivatedRoute,
    private readonly router: Router,
    private translate: TranslateService,
    private communicationService: CommunicationService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private workFlowService: WorkFlowService,
    public dialog: MatDialog,
    private jobDescriptionService: JobDescriptionService,
    private _snackBar: SnackbarService) {
    this.jdId = data.id;
    this.jdName = data.name;
  }


  langC: string = "";
  title: string = "jD.jd-dialoge-title";
  text1: string = "jD.jd-dialoge-text1";
  text2: string = "jD.jd-dialoge-error1";
  flowDetail1: string = "jD.jD-table4-flowDetail1";
  flowDetail2: string = "jD.jD-table4-flowDetail2";
  saveBtnTitle: string = "saveBtnTitle";
  updateBtnTitle: string = "updateBtnTitle";
  cancelBtnTitle: string = "cancelBtnTitle";



  onLanguageChange(lang: string) {
    this.langC = lang;
    this.translate.use(lang);
  }

  ngOnInit(): void {
    this.breakpoint = innerWidth <= resizeWidth ? columnNumber : columnNumber;
    this.fetchWorkFlows();
    this.communicationService.getLanguage().subscribe((value) => {
      this.onLanguageChange(value)
    })
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  public closeMe(): void {

    this.dialogRef.close();

  }

  // Window resize for login divisions
  onResize(event): void {

    this.breakpoint = event.target.innerWidth <= resizeWidth ? columnNumber : columnNumber;

  }

  displayFn(user): string {
    return user && user.name ? user.name : '';
  }

  fetchWorkFlows() {

    this.workFlowService.fetchWorkflowNames().subscribe(flow => {
      this.flowDataArray = flow.Result;
      this.filteredArray = flow.Result;
    })
  }
  handleWorkFlowSelect = (event) => {

    const KEY_CODE_DOWN = 38;
    const KEY_CODE_UP = 40;
    if (!event || event.keyCode === KEY_CODE_DOWN || event.keyCode === KEY_CODE_UP) {

      return;

    }
    const itmQ = event.target.value;

    if (typeof itmQ !== 'string') {

      return;

    }

    this.filteredArray = this.flowDataArray.filter(option => option.name.toLowerCase().includes(itmQ));

  };

  workFlowOnSelect(value) {

    this.selectedWorkFlow = value;
    this.isError = false;
    this.selectedFlow = this.filteredArray.filter(flow => flow?._id === value?._id);
    this.selectedFlow = this.selectedFlow;
    this.workFlowApprovers


  }
  assignWorkFlow() {

    if (this.selectedWorkFlow == null) {
      this.isError = true;
      return;
    }

    this.selectedFlow[0]['jdId'] = this.jdId;
    console.log(this.selectedFlow);

    this.jobDescriptionService.workFlowManagement(this.selectedFlow[0]).subscribe(data => {

      if (data.message === "success") {
        this._snackBar.openSnackBar(`Sucessfully Assigned ${this.selectedFlow?.name} Work Flow to the ${this.jdName} Job Description`, 'success');
        this.dialogRef.close(true);
      }
      else {
        this._snackBar.openSnackBar('Error occured during work flow assignment', 'danger');
      }

    })


  }


  returnUserData(item, userArray) {

    for (let user of userArray.userData) {
      if (item?.user.toString() == user?._id.toString()) {
        return { name: `${user?.name}`, level: `${item?.level}` }
      }
    }

  }

  returnUserName(item, userData) {
    const retundata: any = this.returnUserData(item, userData)
    return `${retundata?.name}`
  }

  returnUserLevel(item, userData) {
    const retundata: any = this.returnUserData(item, userData)
    return `Level - ${retundata?.level}`
  }


}
