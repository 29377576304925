<div [class.overlay]="loading" [class.spinner]="loading" (click)="$event.stopPropagation();"></div>


<div [class.overlay]="loading" [class.spinner]="loading" (click)="$event.stopPropagation();"></div>


<div class="container">
  <mat-card class="outer">
    <img src="../../../assets/images/loginBg2.png" class="card-bg1">
    <img src="../../../assets/images/loginBg3.png" class="card-bg2">

    <div class="colLeft">
    
      
        <div class="colContent">
          <h1 class="login-title"><b>Sign in</b> to<br>
            JD Direct</h1>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ex consectetur libero et earum quibusdam rerum itaque
           dolore omnis eos nemo! Officia eveniet enim sapiente</p>
           <p class="subtags">Don’t have an account ? <a routerLink="/signup">Sign Up</a></p>
          </div>
    
    </div>
    <div class="colRight">
    
    <div class="formsfields">

      <form [formGroup]="form" (ngSubmit)="submit()">

        <div class="form-record">
          <!-- <mat-label>Mobile Number</mat-label> -->
          <mat-form-field appearance="fill">
            <input type="text" matInput placeholder="{{placeHolder1 | translate}}" formControlName="email">
          </mat-form-field>
       
          <!-- <mat-label>Mobile Number</mat-label> -->
          <mat-form-field appearance="fill">
            <input type="password" matInput placeholder="{{placeHolder2 | translate}}" formControlName="password">
          </mat-form-field>
        </div>

        <p *ngIf="error" class="error">
          {{ error }}
        </p>

        <span class="forgotPw"> <a routerLink="/forgot-password">Forgot Password ?</a></span>

        <div class="bottom-container">
         
            <!-- <button type="submit" mat-button>Login</button> -->
            <button type="submit" class="submit-btn" mat-button>{{signinBtn | translate}}</button>
         
        </div>
      </form>


    </div>

    <div class="signup-via">Sign Up With</div>

    <div class="social-btn-wrapper">
      <button class="social-btn d-flex social-btn-div" (click)="signInWithGoogle()">
        <img class="btn-icon" src="/assets/images/googleL.png" alt="">
      </button>
      <button class="social-btn d-flex social-btn-div" (click)="signInWithFB()">
        <img class="btn-icon" src="/assets/images/FacebookL.png" alt="">
      </button>
      <!-- (click)="socialSignIn('linkedin')" -->
      <button class="social-btn d-flex social-btn-div">

        <a [href]='linkedInHref'><img style="height: 46px;" class="btn-icon" src="/assets/images/linkedin-icon.svg"
            alt=""></a>
      </button>

      <!-- <a  [href]='linkedInHref'><img class="linkedin-btn" src="/assets/images/linkedin-icon.svg" alt="LinkedIn"></a> -->
    </div>
    <!-- <hr class="hr"> -->
    <!-- <div>
      <div class="facebook-google-account text-decor">
  
        {{label1 | translate}}<br>{{label1A | translate}}
      </div>
      <button class="signup-btn d-flex" routerLink="/signup">
        <img class="btn-icon" src="/assets/images/email-icon.png" alt="">
        
        <span>{{label2 | translate}}</span>
      </button>
    </div> -->
  </div>
  </mat-card>
</div>