import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { JobDescriptionService } from '@atmeservices/job-description.service';
import { exportJobDiscriptionAs } from '../../Helpers/exportJdAs'


@Component({
  selector: 'app-view-jd',
  templateUrl: './view-Public-Jd.component.html',
  styleUrls: ['./view-Public-Jd.component.scss']
})
export class ViewJdComponent implements OnInit {

  regForm: FormGroup;
  jdRequest_id: any;
  skillDataToView: any;
  key_performance = [];
  roles_responsibilities = [];
  teamFavicon: any;
  uniqueCode: string;
  permissionForEditJd = true;
  budgetResponsibility = false;
  revenueResponsibility = false;
  additionalFields = [];
  items: FormArray;
  jdLogo: any;
  organisationLogo: any;
  loading = false;
  jdCreatedData
  positionJsonArray: any = []



  constructor(
    private formBuilder: FormBuilder,
    private readonly JobDescriptionService: JobDescriptionService,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.createForm()
    this.uniqueCode = this.router.url.replace('/', '');
    this.fetchJobDescription()
    this.loadImage()

  }

  createForm(): void {

    this.regForm = this.formBuilder.group({
      industry: ['', [Validators.required]],
      code: [''],
      name: [''],
      jobFamily: [''],
      joblevel: [''],
      experienceRange: this.formBuilder.group({
        minimumExperience: [0, [Validators.required]],
        maximumExperience: [0, [Validators.required]],
      }),
      minimumQualification: ['', [Validators.required]],
      additionalQualifications: new FormArray([]),
      key_performance: new FormArray([]),
      jobTitle: ['', [Validators.required]],
      skills: new FormArray([]),
      reportingTo: ['', [Validators.required]],
      salaryRange: [''],
      revenueResponsibility: [''],
      budgetResponsibility: [''],
      workModel: [''],
      purpose_role: ['', [Validators.required]],
      functional_content: ['', [Validators.required]],
      roles_responsibilities: new FormArray([]),
      jdRequest_id: [''],
      additionalFields: this.formBuilder.array([])
    });

  }


  fetchJobDescription() {
    this.loading = true;
    this.JobDescriptionService.fetchJobDescriptionPublic({ code: this.uniqueCode }).subscribe((result) => {

      console.log(result);
      this.loading = false;
      if (result?.isUserLogin) {
        this.router.navigate(['/home/create-jd'], { queryParams: { jdData: this.uniqueCode } });
        return
      }

      if (result?.jd) {
        this.jdCreatedData = result.jd;
        result['jd'] ? this.regForm.patchValue(result['jd']) : null;
        this.jdRequest_id = result['jd']._id;
        this.skillDataToView = result['jd'].skills.join(' , ');

        this.key_performance = result.jd.key_performance;
        const control = <FormArray>this.regForm.get('key_performance')
        this.key_performance.forEach(val => control.push(this.formBuilder.control(val)));
        this.regForm.controls['key_performance'].setValue(this.key_performance)

        this.roles_responsibilities = result['jd'].roles_responsibilities;
        const controlRoles = <FormArray>this.regForm.get('roles_responsibilities')
        this.roles_responsibilities.forEach(val => controlRoles.push(this.formBuilder.control(val)));
        this.regForm.controls['roles_responsibilities'].setValue(this.roles_responsibilities);

        this.budgetResponsibility = this.regForm.value.budgetResponsibility != null ? true : false;
        this.revenueResponsibility = this.regForm.value.revenueResponsibility != null ? true : false;;
        this.teamFavicon = result.jd.favicon;
        this.positionJsonArray = result?.jd?.positionData;

        this.bindData(result.jd.additionalFields)

      }

      console.log(this.regForm.value);


    })

  }
  dropHorizontal(event: CdkDragDrop<string[]>, index: number) {
    moveItemInArray(this.positionJsonArray[index], event.previousIndex, event.currentIndex);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.positionJsonArray, event.previousIndex, event.currentIndex);
  }

  bindData(data) {

    let arr = data;
    let control = this.regForm.get('additionalFields') as FormArray;
    while (control.length) {
      control.removeAt(0);
    }
    this.items = arr;
    arr.forEach((x, i) => {
      control.push(this.formBuilder.group({
        heading: x.heading,
        value: x.value
      }));
    });

  }



  /* For Exporrting JD as PDF */
  // async exportAsPDF() {

  //   const doc = new jsPDF('p', 'mm', 'a4')
  //   var img = new Image()
  //   img.src = '../../../assets/images/p-101-logo.jpeg'
  //   doc.addImage(img, 'png', 10, 10, 26, 14)
  //   var img = new Image()
  //   img.src = this.teamFavicon ? this.teamFavicon : '../../../assets/images/team-logo.jpg'
  //   doc.addImage(img, 'png', 187, 10, 12, 12)

  //   doc.setFontSize(9);

  //   let titleReporting = [['JOB TITLE ', [`${this.regForm.value.jobTitle}`]], [['REPORTING TO '], [`${this.regForm.value.reportingTo}`]]]

  //   titleReporting.forEach((title, index) => {

  //     autoTable(doc, {

  //       margin: { left: 10, right: 10 },
  //       columnStyles: { 0: { halign: 'left', textColor: 20, fontStyle: 'bold', fontSize: 9.4, cellWidth: 40 } },
  //       body: [title],
  //       bodyStyles: { lineWidth: 0, fillColor: null, cellPadding: 3 },
  //       startY: index === 0 ? 30 : (doc as any).lastAutoTable.finalY + 1.5,
  //       theme: 'grid',
  //       showHead: 'firstPage',
  //       pageBreak: 'auto'
  //     });

  //   })

  //   let startOfY = (doc as any).lastAutoTable.finalY + 7

  //   /* Purpose of the Role */

  //   const purposeTitle = [['Purpose of the Role']]
  //   const purposeData = [[this.regForm.value.purpose_role]]

  //   autoTable(doc, {

  //     margin: { left: 10 },
  //     headStyles: {
  //       lineWidth: 0, halign: 'justify', textColor: [255, 255, 255], fontStyle: 'bold', cellWidth: 185, fillColor: [70, 155, 235],
  //       // cellPadding: { left: 5 }
  //     },
  //     head: purposeTitle,
  //     body: purposeData,
  //     bodyStyles: { lineWidth: 0, halign: 'justify', fillColor: null, cellPadding: { right: 3, top: 4 } },
  //     startY: startOfY,
  //     theme: 'grid',
  //     showHead: 'firstPage',
  //     pageBreak: 'auto',
  //   });

  //   /* Functional Context */

  //   const functionalContextTitle = [['Functional Context']]
  //   const functionalContextData = [[this.regForm.value.functional_content]]

  //   autoTable(doc, {

  //     margin: { left: 10, right: 10 },
  //     headStyles: {
  //       lineWidth: 0, halign: 'justify', lineColor: 50, cellWidth: 185, textColor: [255, 255, 255], fontStyle: 'bold', fillColor: [70, 155, 235],
  //       // cellPadding: { left: 5 }
  //     },
  //     head: functionalContextTitle,
  //     body: functionalContextData,
  //     bodyStyles: { lineWidth: 0, halign: 'justify', fillColor: null, cellPadding: { right: 3, top: 4 } },
  //     startY: (doc as any).lastAutoTable.finalY + 7,
  //     theme: 'grid',
  //     showHead: 'firstPage',
  //     pageBreak: 'auto'
  //   });

  //   /* Roles And Responsibilities */

  //   const rolesTitle = [['Roles And Responsibilities']]
  //   const rolesData = []
  //   this.regForm.value.roles_responsibilities.forEach((element, index) => {
  //     rolesData.push([element])
  //   })

  //   autoTable(doc, {

  //     margin: { left: 10, right: 10 },
  //     headStyles: {
  //       lineWidth: 0, halign: 'justify', lineColor: 50, cellWidth: 185, textColor: [255, 255, 255], fontStyle: 'bold', fillColor: [70, 155, 235],
  //       // cellPadding: { left: 5 }
  //     },
  //     head: rolesTitle,
  //     body: rolesData,
  //     bodyStyles: { lineWidth: 0, halign: 'justify', fillColor: null, cellPadding: { right: 3, top: 4 } },
  //     startY: (doc as any).lastAutoTable.finalY + 7,
  //     theme: 'grid',
  //     showHead: 'firstPage',
  //     pageBreak: 'auto',
  //     didParseCell(data) {
  //       if (data.cell.section === "body") {
  //         data.cell.text[0] = '• ' + data.cell.text[0];
  //       }
  //     },
  //   });

  //   /* Key Performance Indicators */

  //   const keyPerformanceTitle = [['Key Performance Indicators']]
  //   const keyPerformanceData = []
  //   this.regForm.value.key_performance.forEach((element, index) => {
  //     keyPerformanceData.push([element])
  //   })

  //   autoTable(doc, {

  //     margin: { left: 10, right: 10 },
  //     headStyles: {
  //       lineWidth: 0, halign: 'justify', lineColor: 50, cellWidth: 185, textColor: [255, 255, 255], fontStyle: 'bold', fillColor: [70, 155, 235],
  //       // cellPadding: { left: 5 }
  //     },
  //     head: keyPerformanceTitle,
  //     body: keyPerformanceData,
  //     bodyStyles: { lineWidth: 0, cellWidth: 185, halign: 'justify', fillColor: null, cellPadding: { top: 4 } },
  //     startY: (doc as any).lastAutoTable.finalY + 7,
  //     theme: 'grid',
  //     showHead: 'firstPage',
  //     pageBreak: 'auto',
  //     didParseCell(data) {
  //       if (data.cell.section === "body") {
  //         data.cell.text[0] = '• ' + data.cell.text[0];
  //       }
  //     },

  //   });

  //   /* Minimum Experience , Minimum Qualification */

  //   let titleExperience = [
  //     [[`Minimum Experience `], [`${this.regForm.value.experienceRange.minimumExperience} Years`]],
  //     [[`Minimum Qualification  `], [`${this.regForm.value.minimumQualification}`]]
  //   ]

  //   if (this.revenueResponsibility) {
  //     titleExperience.push([['Revenue Responsibility [USD] :'], [`${this.regForm.value.revenueResponsibility}`]])
  //   }

  //   if (this.budgetResponsibility) {
  //     titleExperience.push([['Budget Responsibility [USD] :'], [this.regForm.value.budgetResponsibility]])
  //   }

  //   titleExperience.push([['Required Skill'], [this.skillDataToView]])

  //   titleExperience.forEach((data, index) => {
  //     // doc.setFontSize(11);
  //     // fillColor: [41, 128, 185]
  //     autoTable(doc, {

  //       margin: { left: 10, right: 10 },
  //       styles: { lineWidth: 0.5 },
  //       columnStyles: {
  //         0: { fillColor: [70, 155, 235], textColor: 255, halign: 'left', fontStyle: 'bold', fontSize: 9.4, cellWidth: 70 },
  //         1: { cellWidth: 115 }
  //       },
  //       body: [data],
  //       bodyStyles: { fontSize: 9, },
  //       startY: index === 0 ? (doc as any).lastAutoTable.finalY + 7 : (doc as any).lastAutoTable.finalY,
  //       theme: 'plain',
  //       showHead: 'firstPage',
  //       pageBreak: 'auto'
  //     });

  //   })

  //   const additionalFieldsTitle = []
  //   const additionalFieldsData = []
  //   await this.regForm.value.additionalFields?.forEach(field => {
  //     additionalFieldsTitle.push([field?.heading])
  //     additionalFieldsData.push([field?.value])
  //   })

  //   /* Additional fields */
  //   const pageHeight = doc.internal.pageSize.getHeight();


  //   additionalFieldsTitle.forEach((title, index) => {
  //     autoTable(doc, {

  //       margin: { left: 10, right: 10 },
  //       headStyles: {
  //         lineWidth: 0, halign: 'justify', lineColor: 50, cellWidth: 185, textColor: [255, 255, 255], fontStyle: 'bold', fillColor: [70, 155, 235],
  //         // cellPadding: { left: 5 }
  //       },
  //       head: [title],
  //       body: [additionalFieldsData[index]],
  //       bodyStyles: { lineWidth: 0, halign: 'justify', fillColor: null, cellPadding: { right: 3, top: 4 } },
  //       startY: (doc as any).lastAutoTable.finalY + 7,
  //       theme: 'grid',
  //       showHead: 'firstPage',
  //       pageBreak: 'auto'
  //     });
  //   })

  //   doc.save('Job Description.pdf');
  // }


  // Load Image
  loadImage() {
    let image1 = '../../../assets/images/p-101-logo.jpeg'
    let image2 = '../../../assets/images/team-logo.jpg'

    this.getBase64ImageFromUrl(image1)
      .then(result => {
        this.jdLogo = result
      })
      .catch(err => console.error(err));

    this.getBase64ImageFromUrl(image2)
      .then(result => {
        this.organisationLogo = result
      })
      .catch(err => console.error(err));
  }
  exportAsPDF() {
    exportJobDiscriptionAs.exportAsPDF(this.regForm.value, this.teamFavicon, this.revenueResponsibility,
      this.budgetResponsibility, this.skillDataToView)
  }

  async getBase64ImageFromUrl(imageUrl) {

    var res = await fetch(imageUrl);
    var blob = await res.blob();

    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.addEventListener("load", function () {
        resolve(reader.result);
      }, false);

      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })
  }

  /* For Exporrting JD as Docx File */
  // exportAsDocx() {

  //   const base64dataJD: any = this.jdLogo;
  //   const base64dataOrganisation: any = this.organisationLogo;

  //   let borderStyle = {
  //     top: { style: BorderStyle.NIL }, bottom: { style: BorderStyle.NIL }, left: { style: BorderStyle.NIL },
  //     right: { style: BorderStyle.NIL },
  //   }
  //   let cellMargin = {
  //     top: convertInchesToTwip(0.15),
  //     bottom: convertInchesToTwip(0.15),
  //   }

  //   let reportRoleArray = [];

  //   this.regForm.value.roles_responsibilities.forEach((element, index) => {
  //     reportRoleArray.push(element)
  //   })
  //   let keyParameterArray = [];

  //   this.regForm.value.key_performance.forEach((element, index) => {
  //     keyParameterArray.push(element)
  //   })

  //   let reportBodyArray = [];

  //   reportBodyArray.push(
  //     new Paragraph({
  //       children: [
  //         new ImageRun({
  //           data: base64dataJD,
  //           transformation: {
  //             width: 110,
  //             height: 50
  //           },
  //           floating: {
  //             horizontalPosition: {
  //               relative: HorizontalPositionRelativeFrom.MARGIN,
  //               align: HorizontalPositionAlign.LEFT,
  //             },
  //             verticalPosition: {
  //               relative: VerticalPositionRelativeFrom.TOP_MARGIN,
  //               offset: 414400,
  //             },
  //           },

  //         }),
  //         new ImageRun({
  //           data: this.teamFavicon ? this.teamFavicon : base64dataOrganisation,
  //           transformation: {
  //             width: 60,
  //             height: 40
  //           },
  //           floating: {
  //             horizontalPosition: {
  //               relative: HorizontalPositionRelativeFrom.MARGIN,
  //               align: HorizontalPositionAlign.RIGHT,
  //             },
  //             verticalPosition: {
  //               relative: VerticalPositionRelativeFrom.TOP_MARGIN,
  //               offset: 414400,
  //             },
  //           },

  //         }),
  //       ],
  //     }),
  //   )

  //   reportBodyArray.push(
  //     new Table({
  //       rows: [
  //         new TableRow({
  //           children: [

  //             new TableCell({
  //               children: [new Paragraph({
  //                 children: [
  //                   new TextRun({
  //                     text: "Job Title",
  //                     size: 23,
  //                     bold: true,
  //                     font: 'Times New Roman'
  //                   })
  //                 ],

  //               })],
  //               borders: borderStyle,
  //               margins: cellMargin,
  //               width: {
  //                 size: 30,
  //                 type: WidthType.PERCENTAGE,
  //               },
  //             }),
  //             new TableCell({
  //               children: [new Paragraph({
  //                 children: [
  //                   new TextRun({
  //                     text: `${this.regForm.value.jobTitle}`,
  //                     size: 23,
  //                     bold: false,
  //                     font: 'Times New Roman'
  //                   })
  //                 ],

  //               })],
  //               margins: cellMargin,
  //               borders: borderStyle,
  //               width: {
  //                 size: 70,
  //                 type: WidthType.PERCENTAGE,
  //               },
  //             }),

  //           ],
  //         }),
  //         new TableRow({
  //           children: [
  //             new TableCell({
  //               children: [new Paragraph({
  //                 children: [
  //                   new TextRun({
  //                     text: "Reporting To",
  //                     size: 23,
  //                     bold: true,
  //                     font: 'Times New Roman'
  //                   })
  //                 ],

  //               })],
  //               margins: cellMargin,
  //               borders: borderStyle,
  //             }),
  //             new TableCell({
  //               children: [new Paragraph({
  //                 children: [
  //                   new TextRun({
  //                     text: `${this.regForm.value.reportingTo}`,
  //                     size: 23,
  //                     bold: false,
  //                     font: 'Times New Roman'
  //                   })
  //                 ],

  //               })],
  //               margins: cellMargin,
  //               borders: borderStyle,
  //             }),
  //           ],
  //         })
  //       ],
  //       width: {
  //         size: 100,
  //         type: WidthType.PERCENTAGE,
  //       }
  //     }),
  //   )

  //   reportBodyArray.push(
  //     new Paragraph({
  //       children: [
  //         new TextRun({
  //           text: " Purpose of the Role",
  //           color: '#ffffff',
  //           size: 25,
  //           bold: true,
  //           font: 'Times New Roman'
  //         })
  //       ],
  //       shading: {
  //         color: '#459ceb',
  //         type: ShadingType.SOLID
  //       },
  //       border: {
  //         top: { style: BorderStyle.NONE, space: 4, size: 2 },
  //         bottom: { style: BorderStyle.NONE, space: 4, size: 2 },
  //       },
  //       spacing: {
  //         before: 400,
  //         lineRule: LineRuleType.AUTO,
  //       },

  //     }),
  //     new Paragraph({
  //       children: [
  //         new TextRun({
  //           text: `${this.regForm.value.purpose_role}`,
  //           color: '#000000',
  //           size: 22,
  //           font: 'Times New Roman',
  //           bold: false,
  //         })
  //       ],
  //       indent: {
  //         left: 10
  //       },
  //       alignment: AlignmentType.JUSTIFIED,
  //       spacing: {
  //         before: 200,
  //         lineRule: LineRuleType.AUTO,
  //       },

  //     }),
  //     new Paragraph({
  //       children: [
  //         new TextRun({
  //           text: " Functional Context",
  //           color: '#ffffff',
  //           size: 25,
  //           bold: true,
  //           font: 'Times New Roman'
  //         })
  //       ],
  //       shading: {
  //         color: '#459ceb',
  //         type: ShadingType.SOLID
  //       },
  //       border: {
  //         top: { style: BorderStyle.NONE, space: 4, size: 2 },
  //         bottom: { style: BorderStyle.NONE, space: 4, size: 2 },
  //       },
  //       spacing: {
  //         before: 400,
  //         lineRule: LineRuleType.AUTO,
  //       },

  //     }),
  //     new Paragraph({
  //       children: [
  //         new TextRun({
  //           text: `${this.regForm.value.functional_content}`,
  //           color: '#000000',
  //           size: 22,
  //           bold: false,
  //           font: 'Times New Roman'
  //         })
  //       ],
  //       alignment: AlignmentType.JUSTIFIED,
  //       spacing: {
  //         before: 200,
  //         lineRule: LineRuleType.AUTO,
  //       },

  //     }),
  //   )

  //   reportBodyArray.push(
  //     new Paragraph({
  //       children: [
  //         new TextRun({
  //           text: " Roles And Responsibilities",
  //           color: '#ffffff',
  //           size: 25,
  //           bold: true,
  //           font: 'Times New Roman'
  //         })
  //       ],
  //       shading: {
  //         color: '#459ceb',
  //         type: ShadingType.SOLID
  //       },
  //       border: {
  //         top: { style: BorderStyle.NONE, space: 4, size: 2 },
  //         bottom: { style: BorderStyle.NONE, space: 4, size: 2 },
  //       },
  //       spacing: {
  //         before: 400,
  //         lineRule: LineRuleType.AUTO,
  //       },

  //     }),
  //   )

  //   for (let role of reportRoleArray) {
  //     reportBodyArray.push(
  //       new Paragraph({
  //         children: [
  //           new TextRun({
  //             text: `${role}`,
  //             color: '#000000',
  //             size: 22,
  //             bold: false,
  //             font: 'Times New Roman'
  //           })
  //         ],
  //         alignment: AlignmentType.JUSTIFIED,
  //         bullet: { level: 0 },
  //         spacing: {
  //           before: 200,
  //           lineRule: LineRuleType.AUTO,
  //         },

  //       }),
  //     )
  //   }

  //   reportBodyArray.push(
  //     new Paragraph({
  //       children: [
  //         new TextRun({
  //           text: " Key Performance Indicators",
  //           color: '#ffffff',
  //           size: 25,
  //           bold: true,
  //           font: 'Times New Roman'
  //         })
  //       ],
  //       shading: {
  //         color: '#459ceb',
  //         type: ShadingType.SOLID
  //       },
  //       border: {
  //         top: { style: BorderStyle.NONE, space: 4, size: 2 },
  //         bottom: { style: BorderStyle.NONE, space: 4, size: 2 },
  //       },
  //       spacing: {
  //         before: 400,
  //         lineRule: LineRuleType.AUTO,
  //       },

  //     }),
  //   )

  //   for (let key of keyParameterArray) {

  //     reportBodyArray.push(
  //       new Paragraph({
  //         children: [
  //           new TextRun({
  //             text: `${key}`,
  //             color: '#000000',
  //             size: 22,
  //             bold: false,
  //             font: 'Times New Roman'
  //           })
  //         ],
  //         alignment: AlignmentType.JUSTIFIED,
  //         bullet: { level: 0 },
  //         spacing: {
  //           before: 200,
  //           lineRule: LineRuleType.AUTO,
  //         },

  //       }),
  //     )

  //   }

  //   reportBodyArray.push(
  //     new Paragraph({
  //       children: [new TextRun({
  //         text: ``,
  //         color: '#FFFFFF',
  //         size: 22,
  //         characterSpacing: 4,
  //         font: 'Times New Roman'
  //       })],
  //       spacing: {
  //         before: 200,
  //         after: 200
  //       },
  //     }),
  //   )

  //   let tempRowArray = [];

  //   let titleExperience = [`Minimum Experience `, `Minimum Qualification  `]
  //   let titleExperienceData = [`${this.regForm.value.experienceRange.minimumExperience} Years`, `${this.regForm.value.minimumQualification}`]

  //   if (this.revenueResponsibility) {
  //     titleExperience.push('Revenue Responsibility [USD] ')
  //     titleExperienceData.push(`${this.regForm.value.revenueResponsibility}`)
  //   }

  //   if (this.budgetResponsibility) {
  //     titleExperience.push('Budget Responsibility [USD] ')
  //     titleExperienceData.push(this.regForm.value.budgetResponsibility)
  //   }

  //   titleExperience.push('Required Skill')
  //   titleExperienceData.push(this.skillDataToView)


  //   titleExperience.forEach((key, index) => {

  //     tempRowArray.push(new TableRow({
  //       children: [

  //         new TableCell({
  //           width: {
  //             size: 40,
  //             type: WidthType.PERCENTAGE,
  //           },
  //           margins: {
  //             marginUnitType: WidthType.DXA,
  //             top: 80,
  //             bottom: 80,
  //             left: 70,
  //           },
  //           shading: {
  //             color: '#459ceb',
  //             // fill: "D9E4EC",
  //             type: ShadingType.SOLID
  //           },
  //           verticalAlign: VerticalAlign.CENTER,
  //           children: [new Paragraph({
  //             children: [new TextRun({
  //               text: `${key}`,
  //               color: '#FFFFFF',
  //               size: 22,
  //               characterSpacing: 4,
  //               font: 'Times New Roman'
  //             })],
  //           })
  //           ],

  //         }),
  //         new TableCell({
  //           children: [new Paragraph({
  //             children: [new TextRun({
  //               text: `${titleExperienceData[index]}`,
  //               size: 22,
  //               characterSpacing: 4,
  //               font: 'Times New Roman'

  //             })],

  //           })
  //           ],
  //           verticalAlign: VerticalAlign.CENTER,
  //           margins: {
  //             marginUnitType: WidthType.DXA,
  //             top: 80,
  //             bottom: 80,
  //             left: 70,
  //           },
  //           width: {
  //             size: 60,
  //             type: WidthType.PERCENTAGE,
  //           }
  //         }),
  //       ],
  //     }))

  //   })


  //   const tableSummary =
  //     new Table({
  //       width: {
  //         size: 100,
  //         type: WidthType.PERCENTAGE,
  //       },
  //       rows: tempRowArray,

  //       borders: {
  //         top: {
  //           style: BorderStyle.THREE_D_ENGRAVE,
  //           size: 4,
  //           color: '#c7c7c7',
  //         },
  //         bottom: {
  //           style: BorderStyle.THREE_D_ENGRAVE,
  //           size: 4,
  //           color: '#c7c7c7',
  //         },
  //         left: {
  //           style: BorderStyle.THREE_D_ENGRAVE,
  //           size: 4,
  //           color: '#c7c7c7',
  //         },
  //         right: {
  //           style: BorderStyle.THREE_D_ENGRAVE,
  //           size: 4,
  //           color: '#c7c7c7',
  //         },
  //         insideHorizontal: {
  //           style: BorderStyle.THREE_D_ENGRAVE,
  //           size: 4,
  //           color: '#c7c7c7',
  //         },
  //         insideVertical: {
  //           style: BorderStyle.THREE_D_ENGRAVE,
  //           size: 4,
  //           color: '#c7c7c7',
  //         },
  //       }

  //     })

  //   reportBodyArray.push(tableSummary)

  //   const additionalFieldsTitle = []
  //   const additionalFieldsData = []

  //   this.regForm.value.additionalFields?.forEach(field => {
  //     additionalFieldsTitle.push(field?.heading)
  //     additionalFieldsData.push(field?.value)
  //   })

  //   additionalFieldsTitle.forEach((field, index) => {

  //     reportBodyArray.push(
  //       new Paragraph({
  //         children: [
  //           new TextRun({
  //             text: `${field}`,
  //             color: '#ffffff',
  //             size: 25,
  //             bold: true,
  //             font: 'Times New Roman'
  //           })
  //         ],
  //         shading: {
  //           color: '#459ceb',
  //           type: ShadingType.SOLID
  //         },
  //         border: {
  //           top: { style: BorderStyle.NONE, space: 4, size: 2 },
  //           bottom: { style: BorderStyle.NONE, space: 4, size: 2 },
  //         },
  //         spacing: {
  //           before: 400,
  //           lineRule: LineRuleType.AUTO,
  //         },

  //       }),
  //       new Paragraph({
  //         children: [
  //           new TextRun({
  //             text: `${additionalFieldsData[index]}`,
  //             color: '#000000',
  //             size: 22,
  //             bold: false,
  //             font: 'Times New Roman'
  //           })
  //         ],
  //         indent: {
  //           left: 10
  //         },
  //         alignment: AlignmentType.JUSTIFIED,
  //         spacing: {
  //           before: 200,
  //           lineRule: LineRuleType.AUTO,
  //         },

  //       }))

  //   })


  //   const doc = new Document({
  //     sections: [{
  //       properties: {},
  //       children: reportBodyArray
  //     }],
  //   });

  //   // Used to export the file into a .docx file
  //   Packer.toBlob(doc).then(blob => {
  //     saveAs(blob, 'Job Description.docx');
  //     // console.log('Document created successfully');
  //   });

  // }
  exportAsDocx() {

    exportJobDiscriptionAs.exportAsDocx(this.regForm.value, this.jdLogo, this.organisationLogo, this.teamFavicon,
      this.revenueResponsibility, this.budgetResponsibility, this.skillDataToView)
    1
  }

}
