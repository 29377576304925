<div [class.overlay]="loading" [class.spinner]="loading" (click)="$event.stopPropagation();"></div>
<div class="main-wrapper" *ngIf="!notAdmin">

    <h1>{{title1 | translate}}</h1>

    <div class="table-wrapper">
        <div class="sub-header-div">
            <form class="search-form">
                <mat-form-field class="search-full-width" appearance="none">
                    <mat-icon matPrefix>search</mat-icon>
                    <input type="text" matInput placeholder="{{search | translate}}" (keyup)="applyFilterUser($event)"
                        #input>
                </mat-form-field>
            </form>
        </div>

        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

            <!-- Position Column -->
            <ng-container matColumnDef="index">
                <th class="table-index" mat-header-cell *matHeaderCellDef> {{label1 | translate}} </th>
                <td class="table-index" mat-cell *matCellDef="let element let i = index;"> {{i+1}} </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th class="table-name" mat-header-cell *matHeaderCellDef>{{label2 | translate}} </th>
                <td class="table-name" mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="email">
                <th class="table-name" mat-header-cell *matHeaderCellDef> {{label3 | translate}} </th>
                <td class="table-name" mat-cell *matCellDef="let element"> {{element.email}} </td>
            </ng-container>
            <!-- Weight Column -->
            <ng-container matColumnDef="jobTitle">
                <th class="table-position" mat-header-cell *matHeaderCellDef> {{label4 | translate}}</th>
                <td class="table-position" mat-cell *matCellDef="let element"> {{element.jobTitle}} </td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="number">
                <th class="table-number" mat-header-cell *matHeaderCellDef>{{label5 | translate}}</th>
                <td class="table-number" mat-cell *matCellDef="let element">
                    <div>{{element.number}}</div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell no-data-error" colspan="5">{{tableNullData | translate}} "{{input.value}}"</td>
            </tr>
        </table>
        <mat-paginator style="margin-top: 20px;" [length]="100" [pageSize]="5" [pageSizeOptions]="[5, 10, 25, 100]">
        </mat-paginator>

    </div>

</div>
<div style="margin-top:15px"></div>
<div class="main-wrapper" *ngIf="!notAdmin">

    <h1>{{title2 | translate}}</h1>

    <div class="table-wrapper">
        <div class="sub-header-div">
            <form class="search-form">
                <mat-form-field class="search-full-width" appearance="none">
                    <mat-icon matPrefix>search</mat-icon>
                    <input type="text" matInput placeholder="{{search | translate}}"
                        (keyup)="applyFilterOrganisation($event)" #inputO>
                </mat-form-field>
            </form>
        </div>

        <table mat-table [dataSource]="dataSourceOrganisation" class="mat-elevation-z8">

            <!-- Position Column -->
            <ng-container matColumnDef="index">
                <th class="table-index" mat-header-cell *matHeaderCellDef>{{label6 | translate}} </th>
                <td class="table-index" mat-cell *matCellDef="let element let i = index;"> {{i+1}} </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th class="table-name" mat-header-cell *matHeaderCellDef> {{label7 | translate}} </th>
                <td class="table-name" mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="id">
                <th class="table-name" mat-header-cell *matHeaderCellDef> {{label8 | translate}} </th>
                <td class="table-name" mat-cell *matCellDef="let element"> {{element.organisation_Id}} </td>
            </ng-container>
            <!-- Weight Column -->

            <!-- Symbol Column -->
            <ng-container matColumnDef="number">
                <th class="table-number" mat-header-cell *matHeaderCellDef> {{label9 | translate}} </th>
                <td class="table-number" mat-cell *matCellDef="let element">
                    <div>{{element.number}}</div>
                </td>
            </ng-container>
            <ng-container matColumnDef="action" >
                <th class="table-number" mat-header-cell *matHeaderCellDef class="action"> {{label10 | translate}} </th>
                <td class="table-number" mat-cell *matCellDef="let element">
                    <div>
                        <mat-icon matTooltip="{{viewToolTip | translate}}" (click)="viewMembers(element._id)" class="mat-action-icon">
                            visibility</mat-icon>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsOrganisation"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsOrganisation;"></tr>

            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell no-data-error" colspan="5">{{tableNullData | translate}} "{{inputO.value}}"</td>
            </tr>
        </table>
        <mat-paginator #invitePaginator class="paginator" style="margin-top: 20px;" [length]="100" [pageSize]="5"
            [pageSizeOptions]="[5, 10, 25, 100]">
        </mat-paginator>

    </div>

</div>
<div style="text-align: center;" *ngIf="notAdmin">
    <h3>{{permissionError | translate}}</h3>
</div>