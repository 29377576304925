/* eslint-disable require-unicode-regexp */
/* eslint-disable dot-notation */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '@atmeservices/user.service';
import { Location } from '@angular/common';
import { SnackbarService } from 'src/app/helpers/snackbar.service';

// Import custom validator to validate that password and confirm password fields match
import { MustMatch } from '../../../Helpers/must-match.validator';
import { TranslateService } from '@ngx-translate/core';
import { CommunicationService } from '@atmeservices/communication.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  loading = false;

  error = false;

  oldPassError = false;

  newPassError = false;

  showNewPsw = false;

  showConPsw = false;

  showCurrentonPsw = false;

  visibility = 'visibility'

  cvisibility = 'visibility';

  currentvisibility = 'visibility';

  hideCurrentPsw: boolean;

  errorClass1 = 'err_chk_icon_red'

  errorClass2 = 'err_chk_icon_red'

  errorClass3 = 'err_chk_icon_red'

  errorClass4 = 'err_chk_icon_red'

  errorIcon1 = 'error_outline'

  errorIcon2 = 'error_outline'

  errorIcon3 = 'error_outline'

  errorIcon4 = 'error_outline'

  green = 'green';

  red = 'red';

  // Check_circle_outline
  changePasswordForm = this.formBuilder.group({
    oldPassword: [''],
    newPassword: ['', [Validators.required, Validators.pattern('^.*(?=.{8,})(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(^[a-zA-Z0-9@\$=!:.#%]+$)')]],
    confirmPassword: ['', Validators.required],
    // eslint-disable-next-line new-cap
  }, { validator: MustMatch('newPassword', 'confirmPassword') }
  )


  constructor(private readonly userService: UserService, private _snackBar: SnackbarService,
    private readonly router: Router, private route: ActivatedRoute, private _location: Location,
    private translate: TranslateService,
    private communicationService: CommunicationService,
    private formBuilder: FormBuilder) { }

  ngOnInit(): void {

    this.isUserPasswordExistOrNot();
    this.communicationService.getLanguage().subscribe((value) => {
      this.onLanguageChange(value)
    })

  }

  langC: string = "";
  title: string = "changePass.title";
  label1: string = "changePass.label1";
  label1Placeholder: string = "changePass.label1-placeholder";
  label1Error1: string = "changePass.label1-error1";
  label1Error2: string = "changePass.label1-error2";
  label2: string = "changePass.label2";
  label2Placeholder: string = "changePass.label2-placeholder";
  label2Error1: string = "changePass.label2-error1";
  label2Error2: string = "changePass.label2-error2";
  label2Error3: string = "changePass.label2-error3";
  label2Error4: string = "changePass.label2-error4";
  label2Error5: string = "changePass.label2-error5";
  label3: string = "changePass.label3";
  label3Placeholder: string = "changePass.label3-placeholder";
  label3Error1: string = "changePass.label3-error1";
  label3Error2: string = "changePass.label3-error2";
  changePassBtn: string = "changePass.changePassBtn";
  cancelBtn: string = "cancelBtnTitle";
  sucessMessage: string = "sucessMessage";
  errorMessage1: string = "errorMessage1";
  errorMessage2: string = "errorMessage2";




  onLanguageChange(lang: string) {
    this.langC = lang;
    this.translate.use(lang);
  }


  passHideUnhide(): void {

    this.showNewPsw = !this.showNewPsw;
    this.visibility = this.showNewPsw ? 'visibility_off' : 'visibility';

  }

  cPassHideUnhide(): void {

    this.showConPsw = !this.showConPsw;
    this.cvisibility = this.showConPsw ? 'visibility_off' : 'visibility';

  }

  currentPassHideUnhide(): void {

    this.showCurrentonPsw = !this.showCurrentonPsw;
    this.currentvisibility = this.showConPsw ? 'visibility_off' : 'visibility';

  }

  changePassword(): void {

    if (this.changePasswordForm.status === 'VALID') {

      this.loading = true;
      this.userService.changePassword(this.changePasswordForm.value).subscribe((message) => {

        this.loading = false;
        this.oldPassError = false;
        this.newPassError = false;
        if (message['message'] === 'success') {

          this._snackBar.openSnackBar(this.translate.instant(this.sucessMessage), 'success');
          this._location.back();

        } else if (message['message'] === 'old password is wrong') {

          this.oldPassError = true;
          this._snackBar.openSnackBar(this.translate.instant(this.errorMessage1), 'danger');

        } else if (message['message'] === 'use another password') {

          this.newPassError = true;
          this._snackBar.openSnackBar(this.translate.instant(this.errorMessage2), 'danger');

        }

      });

    }

  }

  isUserPasswordExistOrNot(): void {

    this.userService.isUserPasswordExistOrNot().subscribe((message) => {

      if (message['passwordExist']) {

        this.hideCurrentPsw = false;
        this.changePasswordForm.get('oldPassword').clearValidators();

      } else {

        this.hideCurrentPsw = true;
        this.changePasswordForm.get('oldPassword').setValidators([Validators.required]);

      }


    });

  }

  onFocusold(): void {

    this.oldPassError = false;

  }

  onFocusnew(): void {

    this.newPassError = false;

  }

  errorCheck(): void {

    // eslint-disable-next-line require-unicode-regexp
    if (this.changePasswordForm.value.newPassword.match(/\d/g)) {

      this.errorIcon1 = 'check_circle_outline';
      this.errorClass1 = 'err_chk_icon_green';

    } else {

      this.errorClass1 = 'err_chk_icon_red';
      this.errorIcon1 = 'error_outline';

    }
    // eslint-disable-next-line require-unicode-regexp
    if (this.changePasswordForm.value.newPassword.match(/(?=.*[a-z])/g)) {

      this.errorIcon2 = 'check_circle_outline';
      this.errorClass2 = 'err_chk_icon_green';

    } else {

      this.errorClass2 = 'err_chk_icon_red';
      this.errorIcon2 = 'error_outline';

    }
    if (this.changePasswordForm.value.newPassword.match(/(?=.*[A-Z])/g)) {

      this.errorIcon3 = 'check_circle_outline';
      this.errorClass3 = 'err_chk_icon_green';

    } else {

      this.errorClass3 = 'err_chk_icon_red';
      this.errorIcon3 = 'error_outline';

    }
    if (this.changePasswordForm.value.newPassword.match(/(?=.{8,})^\S*$/g)) {

      this.errorIcon4 = 'check_circle_outline';
      this.errorClass4 = 'err_chk_icon_green';

    } else {

      this.errorClass4 = 'err_chk_icon_red';
      this.errorIcon4 = 'error_outline';

    }

  }


  cancel(): void {

    this._location.back();

  }

}
