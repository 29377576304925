<div [class.overlay]="loading" [class.spinner]="loading" (click)="$event.stopPropagation();"></div>


<div class="container" *ngIf="isInvitationValid">
  <mat-card class="outer">

    <div class="colLeft">
    
      
      <div class="colContent">
        <h1 class="login-title"><b>Sign Up</b> to<br>
          JD Direct</h1>
      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ex consectetur libero et earum quibusdam rerum itaque
         dolore omnis eos nemo! Officia eveniet enim sapiente</p>
         <p class="subtags"> {{label1 | translate}}  <a routerLink="/login">{{loginBtn | translate}}</a></p>
        </div>
  
  </div>
  <div class="colRight">

    <div class="formsfields">

      <form [formGroup]="regForm" (ngSubmit)="submit()">

        <div class="form-record">
          <!-- <mat-label>Name</mat-label> -->
          <mat-form-field>
            <input type="text" matInput placeholder="{{placeHolder1 | translate}}" formControlName="name">
            <mat-error *ngIf="regForm.touched && regForm.get('name').hasError('required')">
              {{placeHolder1Error | translate}}</mat-error>
          </mat-form-field>
        </div>

        <div class="form-record">
          <!-- <mat-label>Company Name</mat-label> -->
          <mat-form-field>
            <input type="text" matInput placeholder="{{placeHolder2 | translate}}" formControlName="companyName">
            <mat-error *ngIf="regForm.touched && regForm.get('companyName').hasError('required')">
              {{placeHolder2Error | translate}} </mat-error>
          </mat-form-field>
        </div>
        <div class="form-record">
          <!-- <mat-label>Job-Title</mat-label> -->
          <mat-form-field>
            <input type="text" matInput placeholder="{{placeHolder3 | translate}}" formControlName="jobTitle">
            <mat-error *ngIf="regForm.touched && regForm.get('jobTitle').hasError('required')">
              {{placeHolder3Error | translate}} </mat-error>
          </mat-form-field>
        </div>
        <div class="form-record">
          <!-- <mat-label>Corperate Email</mat-label> -->
          <mat-form-field>
            <input type="email" matInput placeholder="{{placeHolder4 | translate}}" formControlName="email">
            <mat-error *ngIf="regForm.touched && regForm.get('email').hasError('required')">
              {{placeHolder4Error1 | translate}} </mat-error>
            <mat-error *ngIf="regForm.get('email').hasError('email')">{{placeHolder4Error2 | translate}}</mat-error>
          </mat-form-field>
        </div>
        <div class="form-record">
          <!-- <mat-label>Mobile Number</mat-label> -->
          <mat-form-field>
            <input type="text" matInput placeholder="{{placeHolder5 | translate}}" formControlName="mobile">
            <mat-error *ngIf="regForm.touched && regForm.get('mobile').hasError('required')">
              {{placeHolder5Error1 | translate}} </mat-error>
            <mat-error *ngIf="regForm.get('mobile').hasError('pattern')">{{placeHolder5Error2 | translate}}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form-record">
          <!-- <mat-label>Password</mat-label> -->
          <mat-form-field>
            <input type="password" matInput placeholder="{{placeHolder6 | translate}}" formControlName="password">
          </mat-form-field>
        </div>
        <div class="form-record">
          <!-- <mat-label>Confirm Password</mat-label> -->
          <mat-form-field>
            <input type="password" matInput placeholder="{{placeHolder7 | translate}}" formControlName="cPassword">
            <mat-error *ngIf="regForm.get('cPassword').hasError('required')">{{placeHolder7Error1 | translate}}
            </mat-error>
            <mat-error *ngIf="regForm.get('cPassword').errors?.mustMatch">{{placeHolder7Error2 | translate}}</mat-error>
          </mat-form-field>
        </div>

        <p *ngIf="error" class="error">
          {{ error }}
        </p>
        <button type="submit" class="submit-btn" mat-button>{{regBtn | translate}}</button>

      
        <!-- <span class="forgot-password-btn">
          <a routerLink="/forgot-password">{{forgotPassBtn | translate}}</a>
        </span> -->
      </form>

      <!-- <div class="button">
        <button type="submit" mat-button>Register</button>
      </div> -->

    </div>
    <div class="signup-via">Sign Up With</div>

    <div class="social-btn-wrapper">
      <button class="social-btn d-flex social-btn-div" (click)="signInWithGoogle()">
        <img class="btn-icon" src="/assets/images/googleL.png" alt="">
      </button>
      <button class="social-btn d-flex social-btn-div" (click)="signInWithFB()">
        <img class="btn-icon" src="/assets/images/FacebookL.png" alt="">
      </button>
      <!-- (click)="socialSignIn('linkedin')" -->
      <button class="social-btn d-flex social-btn-div">

        <a [href]='linkedInHref'><img style="height: 46px;" class="btn-icon" src="/assets/images/linkedin-icon.svg"
            alt=""></a>
      </button>

      <!-- <a  [href]='linkedInHref'><img class="linkedin-btn" src="/assets/images/linkedin-icon.svg" alt="LinkedIn"></a> -->
    </div>
    </div>

  </mat-card>
</div>

<div *ngIf="inviteData && !isInvitationValid" class="invitation-expired">
  <h3>{{invittationError | translate}}</h3>
</div>