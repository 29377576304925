/* eslint-disable no-extra-parens */
/* eslint-disable id-blacklist */
/* eslint-disable dot-notation */
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SnackbarService } from 'src/app/helpers/snackbar.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { WorkFlowDialogeComponent } from './work-flow-dialoge/work-flow-dialoge.component';
import { CustomDialogComponent } from '../../components/custom-dialog/custom-dialog.component';
import { DialogBoxComponent } from '../../components/dialog-box/dialog-box.component';
import { SkillS } from '@atme/entity/skills';
import { WorkFlowService } from '@atmeservices/work-flow.service';
import { WorkFlow, WorkFlowS } from '@atme/entity/work-flow';
import { TranslateService } from '@ngx-translate/core';
import { CommunicationService } from '@atmeservices/communication.service';

@Component({
  selector: 'app-work-flow-management',
  templateUrl: './work-flow-management.component.html',
  styleUrls: ['./work-flow-management.component.scss']
})
export class WorkFlowManagementComponent implements OnInit {

  displayedColumns: string[] = ['name', 'action'];

  dataSource = new MatTableDataSource<SkillS>();

  successMessage = 'Work Flow deleted successfully...';

  errorMessage = 'Error!';

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  @ViewChild(MatSort) sort!: MatSort;

  loading = false;

  flowData = [];

  constructor(
    public dialog: MatDialog,
    private _snackBar: SnackbarService,
    private translate: TranslateService,
    private communicationService: CommunicationService,
    private readonly workFlowService: WorkFlowService,
    private router: Router) { }


  langC: string = "";
  title: string = "workFlow.title";
  addBtn: string = "workFlow.addBtn";
  addBtnToolTip: string = "workFlow.addBtn-tooltip";
  searchplaceHolder: string = "tableSearchPlaceHolder";
  table1: string = "workFlow.table1";
  table2: string = "workFlow.table2";
  editToolTip: string = "editToolTip";
  deleteToolTip: string = "deleteToolTip";
  viewToolTip: string = "viewToolTip";
  tableNullError: string = "nullData-table-error";


  ngOnInit(): void {

    this.fetchWorkFlowList();
    this.communicationService.getLanguage().subscribe((value) => {
      this.onLanguageChange(value)
    })

  }

  onLanguageChange(lang: string) {
    this.langC = lang;
    this.translate.use(lang);
  }

  // Search Work Flow
  applyFilter(event: Event): void {

    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {

      this.dataSource.paginator.firstPage();

    }

  }

  viewWorkFlow(flowData: any): void {

    const dataToView = {
      data: flowData,
      type: 'view'
    }
    const dialogRef = this.dialog.open(WorkFlowDialogeComponent, { data: dataToView });

  }
  editWorkFlow(flowData: string) {
    const dataToView = {
      data: flowData,
      type: 'edit'
    }
    const dialogRef = this.dialog.open(WorkFlowDialogeComponent, { data: dataToView });
    dialogRef.afterClosed().subscribe((result) => {

      if (result) {

        this.fetchWorkFlowList();

      }

    });
  }

  createWorkFlow(): void {

    const dialogRef = this.dialog.open(WorkFlowDialogeComponent);
    // Create subscription
    dialogRef.afterClosed().subscribe((result) => {

      if (result) {

        this.fetchWorkFlowList();

      }

    });

  }
  deleteWorkFlow(workFlow: WorkFlow) {

    const confirmDialog = this.dialog.open(DialogBoxComponent, {
      data: {
        title: 'Delete work flow',
        message: `Are you sure you want to delete work flow "${workFlow.name}" from the system?`
      }
    });
    confirmDialog.afterClosed().subscribe((result) => {

      if (result === true) {

        this.workFlowService.deleteFlowById({ id: workFlow._id }).subscribe(result => {

          console.log(result);
          if (result['message'] === 'success') {

            this._snackBar.openSnackBar('Work flow deleted Successfully', 'success');
            this.fetchWorkFlowList();
          }
          // 'info'
          else if ((result['message'] === 'not')) {
            this._snackBar.openSnackBar('Cannot delete Workflow ,this workflow is assigned to one or more job descriptions', 'warning');
          }
          else {
            this._snackBar.openSnackBar('An Error Occured Please try again later', 'danger');
          }

        })

      }
    })

  }

  // Fetch all Work Flow records
  fetchWorkFlowList(): void {

    this.loading = true;

    this.workFlowService.fetchWorkflow().subscribe((message) => {

      this.loading = false;
      this.flowData = message.Result;
      console.log(message);


      this.dataSource = new MatTableDataSource(this.flowData);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;


    }, (error) => {

      this.loading = false;
      console.error(error);

    });

  }

}

