import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DatePipe } from '@angular/common';
import { LoginComponent } from './pages/login/login.component';
import { SignupComponent } from './pages/signup/signup.component';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule} from '@angular/material/tabs';

import { MatBadgeModule } from '@angular/material/badge';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { EditProfileComponent } from './pages/edit-profile/edit-profile.component';
import { SocialLoginModule, SocialAuthServiceConfig, GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import { environment } from '@atmeenvironments/environment';
import { ChangePasswordComponent } from './pages/sidemenu/change-password/change-password.component';
import { SidemenuComponent } from './pages/sidemenu/sidemenu.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { ForgotPasswordComponent } from './pages/signup/forgot-password/forgot-password.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { CustomDialogComponent } from './components/custom-dialog/custom-dialog.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { DialogBoxComponent } from './components/dialog-box/dialog-box.component';
import { IndustryManagementComponent } from './pages/industry-management/industry-management.component';
import { IndustryManagementDialogeComponent } from './pages/industry-management/industry-management-dialoge/industry-management-dialoge.component';
import { QualificationManagementComponent } from './pages/qualification-management/qualification-management.component';
import { QualificationManagementDialogComponent } from './pages/qualification-management/qualification-management-dialog/qualification-management-dialog.component';
import { JobFamilyManagementComponent } from './pages/job-family-management/job-family-management.component';
import { JobFamilyManagementDialogComponent } from './pages/job-family-management/job-family-management-dialog/job-family-management-dialog.component';
import { JobLevelManagementComponent } from './pages/job-level-management/job-level-management.component';
import { JobLevelManagementDialogeComponent } from './pages/job-level-management/job-level-management-dialoge/job-level-management-dialoge.component';
import { JobTitleManagementComponent } from './pages/job-title-management/job-title-management.component';
import { JobTitleManagementDialogeComponent } from './pages/job-title-management/job-title-management-dialoge/job-title-management-dialoge.component';
import { SkillManagementComponent } from './pages/skill-management/skill-management.component';
import { SkillManagementDialogeComponent } from './pages/skill-management/skill-management-dialoge/skill-management-dialoge.component';
import { InviteDialogeComponent } from './components/invite-dialoge/invite-dialoge.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { CreateJdComponent } from './pages/create-jd/create-jd.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ViewJdComponent } from './pages/view-Public-Jd/view-Public-Jd.component';
import { OrganisationManagementComponent } from './pages/organisation-management/organisation-management.component';
import { OrganisationManagementDialogComponent } from './pages/organisation-management/organisation-management-dialog/organisation-management-dialog.component';
import { OverviewComponent } from './pages/overview/overview.component';
import { CustomModelComponent } from './components/custom-model/custom-model.component';
import { OverviewDialogComponent } from './pages/overview/overview-dialog/overview-dialog.component';
import { WorkFlowManagementComponent } from './pages/work-flow-management/work-flow-management.component';
import { WorkFlowDialogeComponent } from './pages/work-flow-management/work-flow-dialoge/work-flow-dialoge.component';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { OverlayModule } from '@angular/cdk/overlay';
import { DashboardDialogueComponent } from './pages/dashboard/dashboard-dialogue/dashboard-dialogue.component';
import { UserSessionComponent } from './pages/user-session/user-session.component';

import { registerLocaleData } from '@angular/common'; 
import localeEn from '@angular/common/locales/en';

import { DragDropModule } from '@angular/cdk/drag-drop';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
// import localeFr from '@angular/common/locales/ml';
import { HttpClient } from '@angular/common/http';
import { DetailComponentComponent } from './pages/detail-component/detail-component.component';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { BlogComponent } from './blog/blog.component';
import { ContactUsComponent } from './contact-us/contact-us.component';




@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    UserSessionComponent,
    SignupComponent,
    DashboardComponent,
    EditProfileComponent,
    ChangePasswordComponent,
    SidemenuComponent,
    ForgotPasswordComponent,
    CustomDialogComponent,
    DialogBoxComponent,
    IndustryManagementComponent,
    IndustryManagementDialogeComponent,
    QualificationManagementComponent,
    QualificationManagementDialogComponent,
    JobFamilyManagementComponent,
    JobFamilyManagementDialogComponent,
    JobLevelManagementComponent,
    JobLevelManagementDialogeComponent,
    JobTitleManagementComponent,
    JobTitleManagementDialogeComponent,
    SkillManagementComponent,
    SkillManagementDialogeComponent,
    InviteDialogeComponent,
    CreateJdComponent,
    ViewJdComponent,
    OrganisationManagementComponent,
    OrganisationManagementDialogComponent,
    OverviewComponent,
    CustomModelComponent,
    OverviewDialogComponent,
    WorkFlowManagementComponent,
    WorkFlowDialogeComponent,
    DashboardDialogueComponent,
    DetailComponentComponent,
    HomepageComponent,
    AboutusComponent,
    BlogComponent,
    ContactUsComponent,
  ],
  imports: [
    BrowserModule,
    MatSelectModule,
    OverlayModule,
    DragDropModule,
    MatSlideToggleModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatIconModule,
    MatSliderModule,
    MatRadioModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatTooltipModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatSnackBarModule,
    SocialLoginModule,
    MatToolbarModule,
    MatSidenavModule,
    MatButtonToggleModule,
    MatMenuModule,
    MatGridListModule,
    MatPaginatorModule,
    MatTableModule,
    NgxSliderModule,
    MatChipsModule,
    MatBadgeModule,
    MatAutocompleteModule,
    MatTabsModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  })
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'en-EN' },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.googleClientId)
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.fbClientId)
          }
        ]
      } as SocialAuthServiceConfig,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}