/* eslint-disable id-blacklist */
/* eslint-disable dot-notation */
import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

const resizeWidth = 425;
const columnNumber2 = 2;
const columnNumber1 = 1;

@Component({
  selector: 'app-custom-dialog',
  templateUrl: './custom-dialog.component.html',
  styleUrls: [ './custom-dialog.component.scss' ]
})
export class CustomDialogComponent implements OnInit {


  breakpoint: any

  dataObject: any

  imageToShow: boolean;

  picurl

  constructor(public datepipe: DatePipe, public dialogRef: MatDialogRef<CustomDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    if (data['image']) {

      this.imageToShow = true;
      this.picurl = data['image'];

    }

    this.dataObject = Object.keys(data['data']).map((key) => ({ type: key,
      value: data['data'][key] }));

  }


  ngOnInit(): void {

    this.breakpoint = innerWidth <= resizeWidth ? columnNumber1 : columnNumber2;

  }


  // Window resize for login divisions
  onResize(event): void {

    this.breakpoint = event.target.innerWidth <= resizeWidth ? columnNumber1 : columnNumber2;

  }

  public closeMe(): void {

    this.dialogRef.close();

  }

}

