/* eslint-disable dot-notation */
/* eslint-disable id-blacklist */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CommunicationService } from '@atmeservices/communication.service';
import { QualificationService } from '@atmeservices/qualification.service';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from 'src/app/helpers/snackbar.service';

const resizeWidth = 425;
const columnNumber = 2;


@Component({
  selector: 'app-qualification-management-dialog',
  templateUrl: './qualification-management-dialog.component.html',
  styleUrls: ['./qualification-management-dialog.component.scss']
})
export class QualificationManagementDialogComponent implements OnInit {

  breakpoint: any;

  regForm: FormGroup;

  industryId: string;

  isEdit = false;

  constructor(
    public dialogRef: MatDialogRef<QualificationManagementDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private route: ActivatedRoute,
    private readonly router: Router,
    private readonly qualificationService: QualificationService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private communicationService: CommunicationService,
    private _snackBar: SnackbarService


  ) {

    if (data) {

      this.industryId = data;
      this.fetchQualificationForEdit(data);

    }

  }


  langC: string = "";
  createTitle: string = "qualificattionDialoge.createTitle";
  updateTitle: string = "qualificattionDialoge.updateTitle";
  labe1: string = "qualificattionDialoge.labe1";
  labe1PlaceHolder: string = "qualificattionDialoge.labe1PlaceHolder";
  labe1Error: string = "qualificattionDialoge.labe1Error";
  cancelBtn: string = "cancelBtnTitle";
  changeBtn: string = "imageUploadBtn";
  saveBtn: string = "saveBtnTitle";
  updateBtn: string = "updateBtnTitle";
  createSucessMsg: string = "qualificattionDialoge.createSucessMsg";
  updateSucessMsg: string = "qualificattionDialoge.updateSucessMsg";
  errorMsg1: string = "qualificattionDialoge.errorMsg1";
  errorMsg2: string = "qualificattionDialoge.errorMsg2";


  ngOnInit(): void {

    this.breakpoint = innerWidth <= resizeWidth ? columnNumber : columnNumber;
    this.createForm();

    this.communicationService.getLanguage().subscribe((value) => {
      this.onLanguageChange(value)
    })

  }

  onLanguageChange(lang: string) {
    this.langC = lang;
    this.translate.use(lang);
  }


  public closeMe() {

    this.dialogRef.close();

  }

  // Window resize for login divisions
  onResize(event) {

    this.breakpoint = event.target.innerWidth <= resizeWidth ? columnNumber : columnNumber;

  }

  createForm(): void {

    this.regForm = this.formBuilder.group({
      name: ['', [Validators.required]],
    });

  }

  createQualification() {

    if (this.regForm.valid) {

      this.qualificationService.createQualification(this.regForm.value).subscribe((message) => {

        if (message['message'] === 'success') {

          this._snackBar.openSnackBar(this.translate.instant(this.createTitle), 'success');
          this.dialogRef.close(true);

        } else if (message['message'] === 'exist') {

          this._snackBar.openSnackBar(this.translate.instant(this.errorMsg1), 'danger');

        } else {

          this._snackBar.openSnackBar(this.translate.instant(this.errorMsg2), 'danger');

        }

      });

    }

  }

  fetchQualificationForEdit(qualifiId: string) {

    const data = { qualificationId: qualifiId };
    this.qualificationService.fetchQualificationById(data).subscribe((message) => {

      message['_id'] ? this.regForm.patchValue(message) : this.industryId = null;
      this.isEdit = true;

    });

  }

  updateQualification() {

    this.qualificationService.updateQualification(this.regForm.value, this.industryId).subscribe((message) => {

      if (message['message'] === 'success') {

        this._snackBar.openSnackBar(this.translate.instant(this.updateTitle), 'success');
        this.dialogRef.close(true);

      } else if (message['message'] === 'exist') {

        this._snackBar.openSnackBar(this.translate.instant(this.errorMsg1), 'danger');

      } else {

        this._snackBar.openSnackBar(this.translate.instant(this.errorMsg2), 'danger');

      }

    });

  }

}
