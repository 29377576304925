<div>
    <mat-icon class="model-close-icon" mat-dialog-close>close</mat-icon>
</div>

<div class="page-heading">
    <h1 *ngIf="!isEdit">{{createTitle | translate}}</h1>
    <h1 *ngIf="isEdit">{{updateTitle | translate}}</h1>
</div>


<!-- <div class="model-header">
    <h2 class="mat-dialog-title"> {{data['title']}}</h2>
</div> -->

<mat-dialog-content class="mat-typography">

    <mat-grid-list [cols]="breakpoint" rowHeight="2:.3" (window:resize)="onResize($event)">

        <div class="model-item-div">

            <div [formGroup]="regForm">
                <mat-label>{{labe1 | translate}}</mat-label>
                <mat-form-field floatLabel="never" appearance="fill">
                    <input type="text" matInput placeholder="{{labe1PlaceHolder | translate}}" formControlName="name"
                        required>
                    <mat-error *ngIf="regForm.touched||regForm.dirty && regForm.get('name').hasError('required')">
                        {{labe1Error | translate}} </mat-error>
                    <!-- <mat-error>{{errorEmail}}</mat-error> -->
                </mat-form-field>
            </div>

        </div>
    </mat-grid-list>


</mat-dialog-content>
<mat-dialog-actions align="center">
    <button mat-button mat-dialog-close class="button-close" data-backdrop="static" data-keyboard="false">{{cancelBtn |
        translate}}</button>
    <button *ngIf="!isEdit" mat-button class="button-close" (click)="createQualification()">{{saveBtn |
        translate}}</button>
    <button *ngIf="isEdit" mat-button class="button-close" (click)="updateQualification()">{{updateBtn |
        translate}}</button>
</mat-dialog-actions>