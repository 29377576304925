import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BASE_URI, USER_SESSION_API } from '@atme/serverAPI';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UsersessionService {

  constructor(private readonly http: HttpClient) { }

  getusersession(): Observable<any> {
    return this.http.get<any>(`${BASE_URI}${USER_SESSION_API}`,).pipe(
      catchError((err) => throwError(err))
    );

  }

  updateUserSession(): Observable<any> {
    return this.http.get<any>(`${BASE_URI}${USER_SESSION_API}/update`).pipe(
      catchError((err) => throwError(err))
    );

  }

}
