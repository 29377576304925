import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { BASE_URI, JOB_DESCRIPTION_REQUEST_API, JOB_DESCRIPTION_API } from '@atme/serverAPI';
import { JdRequestS } from '@atme/entity/jd-request';
import { JobDescriptionS } from '@atme/entity/job-description';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class JobDescriptionService {

  constructor(
    private readonly http: HttpClient
  ) { }

  createJobDescriptionRequest(jobDesc: JdRequestS): Observable<any> {

    return this.http.post<JdRequestS>(`${BASE_URI}${JOB_DESCRIPTION_REQUEST_API}`, jobDesc).pipe(
      catchError((err) => throwError(err))
    );

  }

  saveJobDescription(jobDesc: JobDescriptionS): Observable<any> {

    return this.http.post<JobDescriptionS>(`${BASE_URI}${JOB_DESCRIPTION_API}`, jobDesc).pipe(
      catchError((err) => throwError(err))
    );

  }
  updateJobDescription(jobDesc: JobDescriptionS): Observable<any> {

    return this.http.put<JobDescriptionS>(`${BASE_URI}${JOB_DESCRIPTION_API}`, jobDesc).pipe(
      catchError((err) => throwError(err))
    );

  }
  deleteJobDescription(jobDesc: JobDescriptionS): Observable<any> {

    return this.http.put<JobDescriptionS>(`${BASE_URI}${JOB_DESCRIPTION_API}/delete`, jobDesc).pipe(
      catchError((err) => throwError(err))
    );

  }

  fetchJobDescriptionPublic(code: any): Observable<any> {

    return this.http.get<JobDescriptionS>(`${BASE_URI}${JOB_DESCRIPTION_API}/publicJobDescription`, { params: code }).pipe(
      catchError((err) => throwError(err))
    );

  }
  fetchJobDescription(code: any): Observable<any> {

    return this.http.get<JobDescriptionS>(`${BASE_URI}${JOB_DESCRIPTION_API}/fetchJd`, { params: code }).pipe(
      catchError((err) => throwError(err))
    );

  }
  fetchListOfJobDescription(): Observable<any> {

    return this.http.get<JobDescriptionS>(`${BASE_URI}${JOB_DESCRIPTION_API}/fetch`).pipe(
      catchError((err) => throwError(err))
    );

  }
  workFlowManagement(data): Observable<any> {

    return this.http.post<JobDescriptionS>(`${BASE_URI}${JOB_DESCRIPTION_API}/flowManagement`, data).pipe(
      catchError((err) => throwError(err))
    );

  }

}
