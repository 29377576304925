<div [class.overlay]="loading" [class.spinner]="loading" (click)="$event.stopPropagation();"></div>
<div class="login-wrapper">
    <div class="login-div-wrapper" >
        <mat-card class="login-inner-wrapper">
          
                <mat-card-content>

                    <div class="colLeft">
    
      
                        <div class="colContent">
                          <h1 class="login-title"><b>Forgot</b><br>
                           Password</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ex consectetur libero et earum quibusdam rerum itaque
                           dolore omnis eos nemo</p>
                         
                          </div>
                    
                    </div>
                    <div class="colRight">
                    <form [formGroup]="forgotPasswordForm" class="login-form" (ngSubmit)="forgotPassword()">
                        <div>
                            <br>
                            <mat-label>{{label1 | translate}}</mat-label>
                           
                            <mat-form-field floatLabel="never" appearance="none">
                                <input type="text" matInput placeholder="{{label1PlaceHolder | translate}}"
                                    formControlName="email" required (click)="formInputChanged()">
                                <mat-error
                                    *ngIf="forgotPasswordForm.touched || forgotPasswordForm.get('email').hasError('required')">
                                    {{label1Error1 | translate}}</mat-error>
                                <mat-error *ngIf="forgotPasswordForm.get('email').hasError('email')">{{label1Error2 | translate}}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <p *ngIf="error" class="error">
                            {{ error }}
                        </p>

                        <button type="button" (click)='cancel()' class="cancel_button" mat-button>{{cancelBtn | translate}}</button>
                        <button type="submit" class="change_pass_button" mat-button>{{resetBtn | translate}}</button>
                    </form>
                    </div>
                </mat-card-content>
           
        </mat-card>
    </div>
</div>