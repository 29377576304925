/* eslint-disable id-blacklist */
/* eslint-disable dot-notation */
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CommunicationService } from '@atmeservices/communication.service';
import { IndustryService } from '@atmeservices/industry.service';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from 'src/app/helpers/snackbar.service';


const resizeWidth = 425;
const columnNumber = 2;

@Component({
  selector: 'app-industry-management-dialoge',
  templateUrl: './industry-management-dialoge.component.html',
  styleUrls: ['./industry-management-dialoge.component.scss']
})
export class IndustryManagementDialogeComponent implements OnInit {

  breakpoint: number;

  regForm: FormGroup;

  industryId: string;

  isEdit = false;

  selectedFiles: FileList;

  selectedMedia = null;

  picurl: string;


  constructor(
    public dialogRef: MatDialogRef<IndustryManagementDialogeComponent>,
    @Inject(MAT_DIALOG_DATA) public industryData: any,
    private route: ActivatedRoute,
    private readonly router: Router,
    private translate: TranslateService,
    private communicationService: CommunicationService,
    private readonly industryService: IndustryService,
    private formBuilder: FormBuilder,
    private _snackBar: SnackbarService


  ) {

    if (industryData) {

      this.industryId = industryData;
      this.fetchIndustryForEdit(industryData);

    }

  }

  ngOnInit(): void {

    this.breakpoint = innerWidth <= resizeWidth ? columnNumber : columnNumber;
    this.createForm();
    this.communicationService.getLanguage().subscribe((value) => {
      this.onLanguageChange(value)
    })

  }

  onLanguageChange(lang: string) {
    this.langC = lang;
    this.translate.use(lang);
  }

  langC: string = "";
  createTitle: string = "industryDialoge.createTitle";
  updateTitle: string = "industryDialoge.updateTitle";
  labe1: string = "industryDialoge.labe1";
  labe1PlaceHolder: string = "industryDialoge.labe1PlaceHolder";
  labe1Error: string = "industryDialoge.labe1Error";
  cancelBtn: string = "cancelBtnTitle";
  changeBtn: string = "imageUploadBtn";
  saveBtn: string = "saveBtnTitle";
  updateBtn: string = "updateBtnTitle";
  createSucessMsg: string = "industryDialoge.createSucessMsg";
  updateSucessMsg: string = "industryDialoge.updateSucessMsg";
  errorMsg1: string = "industryDialoge.errorMsg1";
  errorMsg2: string = "industryDialoge.errorMsg2";


  public closeMe(): void {

    this.dialogRef.close();

  }

  // Window resize for login divisions
  onResize(event): void {

    this.breakpoint = event.target.innerWidth <= resizeWidth ? columnNumber : columnNumber;

  }

  createForm(): void {

    this.regForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      image: [''],
    });

  }

  createIndustry(): void {

    this.regForm.value['image'] = this.picurl;
    if (this.regForm.valid) {

      this.industryService.createIndustry(this.regForm.value).subscribe((message) => {

        // eslint-disable-next-line dot-notation
        if (message['message'] === 'success') {

          this._snackBar.openSnackBar(this.translate.instant(this.createSucessMsg), 'success');
          this.dialogRef.close(true);

        } else if (message['message'] === 'exist') {

          this._snackBar.openSnackBar(this.translate.instant(this.errorMsg1), 'danger');


        } else {

          this._snackBar.openSnackBar(this.translate.instant(this.errorMsg2), 'danger');

        }

      });

    }

  }

  fetchIndustryForEdit(industryDataId: string): void {

    const data = { industryId: industryDataId };
    this.industryService.fetchIndustryById(data).subscribe((message) => {

      message['_id'] ? this.regForm.patchValue(message) : this.industryId = null;
      this.picurl = message['image'];
      this.isEdit = true;

    });

  }

  updateIndustry(): void {

    this.regForm.value['image'] = this.picurl;
    if (this.regForm.valid) {

      this.industryService.updateIndustry(this.regForm.value, this.industryId).subscribe((message) => {


        if (message['message'] === 'success') {

          this._snackBar.openSnackBar(this.translate.instant(this.updateSucessMsg), 'success');
          this.dialogRef.close(true);

        } else if (message['message'] === 'exist') {

          this._snackBar.openSnackBar(this.translate.instant(this.errorMsg1), 'danger');

        } else {

          this._snackBar.openSnackBar(this.translate.instant(this.errorMsg2), 'danger');

        }

      });

    }

  }

  selectFile = (event: Event): void => {

    const fileTag = <HTMLInputElement>event.target;
    this.selectedFiles = fileTag.files;
    this.onFileSelect(this.selectedFiles[0]);

  }

  onFileSelect(file: File): void {

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {

      this.selectedMedia = reader.result.toString();
      this.picurl = this.selectedMedia;

    };

  }

}

