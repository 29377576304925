import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CommunicationService } from '@atmeservices/communication.service';
import { JobTitleService } from '@atmeservices/job-title.service';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from 'src/app/helpers/snackbar.service';


const resizeWidth = 425;
const columnNumber1 = 1;
const columnNumber2 = 2;

@Component({
  selector: 'app-job-title-management-dialoge',
  templateUrl: './job-title-management-dialoge.component.html',
  styleUrls: ['./job-title-management-dialoge.component.scss']
})
export class JobTitleManagementDialogeComponent implements OnInit {

  breakpoint: number;

  regForm: FormGroup;

  JobTitleId: string;

  isEdit = false;


  constructor(
    public dialogRef: MatDialogRef<JobTitleManagementDialogeComponent>,
    @Inject(MAT_DIALOG_DATA) public titleData: any,
    private route: ActivatedRoute,
    private readonly router: Router,
    private readonly jobTitleService: JobTitleService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private communicationService: CommunicationService,
    private _snackBar: SnackbarService


  ) {

    if (titleData) {


      this.JobTitleId = titleData;
      this.fetchJobTitleForEdit(titleData);

    }

  }

  ngOnInit(): void {

    this.breakpoint = innerWidth <= resizeWidth ? columnNumber1 : columnNumber2;

    this.createForm();
    this.communicationService.getLanguage().subscribe((value) => {
      this.onLanguageChange(value)
    })
  }

  

  onLanguageChange(lang: string) {
    this.langC = lang;
    this.translate.use(lang);
  }

  langC: string = "";
  createTitle: string = "jobTitleDialoge.createTitle";
  updateTitle: string = "jobTitleDialoge.updateTitle";
  labe1: string = "jobTitleDialoge.labe1";
  labe1PlaceHolder: string = "jobTitleDialoge.labe1PlaceHolder";
  labe1Error: string = "jobTitleDialoge.labe1Error";
  cancelBtn: string = "cancelBtnTitle";
  changeBtn: string = "imageUploadBtn";
  saveBtn: string = "saveBtnTitle";
  updateBtn: string = "updateBtnTitle";
  createSucessMsg: string = "jobTitleDialoge.createSucessMsg";
  updateSucessMsg: string = "jobTitleDialoge.updateSucessMsg";
  errorMsg1: string = "jobTitleDialoge.errorMsg1";
  errorMsg2: string = "jobTitleDialoge.errorMsg2";

  // Window resize for login divisions
  onResize(event: { target: { innerWidth: number; }; }): void {

    this.breakpoint = event.target.innerWidth <= resizeWidth ? columnNumber1 : columnNumber2;

  }

  createForm(): void {

    this.regForm = this.formBuilder.group({
      name: ['', [Validators.required]],
    });

  }

  createJobTitle(): void {

    if (this.regForm.valid) {

      this.jobTitleService.createJobTitle(this.regForm.value).subscribe((message) => {

        if (message.message === 'success') {

          this._snackBar.openSnackBar(this.translate.instant(this.createSucessMsg), 'success');
          this.dialogRef.close(true);

        } else {

          this._snackBar.openSnackBar(this.translate.instant(this.errorMsg2), 'danger');

        }

      });

    }

  }

  fetchJobTitleForEdit(titleId: string): void {

    const tileData = { jobTitleId: titleId };
    this.jobTitleService.fetchJobTitleById(tileData).subscribe((message) => {

      message._id ? this.regForm.patchValue(message) : this.JobTitleId = null;
      this.isEdit = true;

    });

  }

  updateJobTitle(): void {

    this.jobTitleService.updateJobTitle(this.regForm.value, this.JobTitleId).subscribe((message) => {

      if (message.message === 'success') {

        this._snackBar.openSnackBar(this.translate.instant(this.updateSucessMsg), 'success');
        this.dialogRef.close(true);

      } else {

        this._snackBar.openSnackBar(this.translate.instant(this.errorMsg2), 'danger');

      }

    });

  }

}
