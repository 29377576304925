import { Component, Inject, OnInit, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommunicationService } from '@atmeservices/communication.service';
import { OrganisationManagementService } from '@atmeservices/organisation-management.service';
import { WorkFlowService } from '@atmeservices/work-flow.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { SnackbarService } from 'src/app/helpers/snackbar.service';


const resizeWidth = 425;
const columnNumber1 = 1;
const columnNumber2 = 2;

@Component({
  selector: 'app-work-flow-dialoge',
  templateUrl: './work-flow-dialoge.component.html',
  styleUrls: ['./work-flow-dialoge.component.scss']
})



export class WorkFlowDialogeComponent implements OnInit, AfterViewChecked {

  regForm: FormGroup;

  breakpoint: number;

  userDataArray = [];

  filteredArray = [];

  items: any;

  isEdit = false;

  isUpdate = false;

  workFlowId: string;

  workFlowData: any
  duplicateUserError = false;

  constructor(
    public dialogRef: MatDialogRef<WorkFlowDialogeComponent>,
    private formBuilder: FormBuilder,
    private _snackBar: SnackbarService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private translate: TranslateService,
    private communicationService: CommunicationService,
    private readonly organisationService: OrganisationManagementService,
    private readonly workFlowService: WorkFlowService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if (data?.data) {
      this.BindData(data?.data)
    }
    if (data?.type === 'view') {
      this.isEdit = true;
    }
    else if (data?.type === 'edit') {
      this.isEdit = false;
      this.isUpdate = true;
    }
  }


  langC: string = "";
  label1: string = "workFlow-dialoge.label1";
  label1PlaceHolder: string = "workFlow-dialoge.label1-placeHolder";
  label1Error: string = "workFlow-dialoge.label1-Error";
  label2: string = "workFlow-dialoge.label2";
  label2PlaceHolder: string = "workFlow-dialoge.label2-placeHolder";
  label2Error: string = "workFlow-dialoge.label2-Error";
  label3: string = "workFlow-dialoge.label3";
  label4: string = "workFlow-dialoge.label4";
  label4PlaceHolder: string = "workFlow-dialoge.label4-placeHolder";
  label4Error: string = "workFlow-dialoge.label4-Error";
  label5: string = "workFlow-dialoge.label5";
  label5PlaceHolder: string = "workFlow-dialoge.label5-placeHolder";
  label5Value1: string = "workFlow-dialoge.label5-value1";
  label5Value2: string = "workFlow-dialoge.label5-value2";
  label5Error: string = "workFlow-dialoge.label5-Error";
  label6: string = "workFlow-dialoge.label6";
  label6PlaceHolder: string = "workFlow-dialoge.label6-placeHolder";
  label6Value1: string = "workFlow-dialoge.label6-value1";
  label6Value2: string = "workFlow-dialoge.label6-value2";
  label6Value3: string = "workFlow-dialoge.label6-value3";
  label6Value4: string = "workFlow-dialoge.label6-value4";
  label6Value5: string = "workFlow-dialoge.label6-value5";
  label6Error: string = "workFlow-dialoge.label6-Error";
  label7: string = "workFlow-dialoge.label7";
  label8: string = "workFlow-dialoge.label8";
  label9: string = "workFlow-dialoge.label9";
  label10: string = "workFlow-dialoge.label10";
  label11: string = "workFlow-dialoge.label11";
  label12: string = "workFlow-dialoge.label12";
  error1: string = "workFlow-dialoge.error1";
  saveBtnTitle: string = "saveBtnTitle";
  updateBtnTitle: string = "updateBtnTitle";
  cancelBtnTitle: string = "cancelBtnTitle";


  ngOnInit(): void {
    this.createForm()
    this.breakpoint = innerWidth <= resizeWidth ? columnNumber1 : columnNumber2;
    this.fetchOrganisationMembers()
    this.communicationService.getLanguage().subscribe((value) => {
      this.onLanguageChange(value)
    })
  }

  onLanguageChange(lang: string) {
    this.langC = lang;
    this.translate.use(lang);
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  handleUserSelect = (event) => {

    const KEY_CODE_DOWN = 38;
    const KEY_CODE_UP = 40;
    if (!event || event.keyCode === KEY_CODE_DOWN || event.keyCode === KEY_CODE_UP) {

      return;

    }
    const itmQ = event.target.value;

    if (typeof itmQ !== 'string') {

      return;

    }

    this.filteredArray = this.userDataArray.filter(option => option.name.toLowerCase().includes(itmQ));

  };


  userSelect(value, i) {

    let count = 0;
    let index = 0;
    this.regForm.value.participents.forEach(data => {
      if (data?.user?._id.toString() == value?._id.toString()) {
        count++
      }
    })

    if (count > 1) {
      this.duplicateUserError = true;
    }
    else {
      this.duplicateUserError = false;
    }

  }

  displayFn(user): string {
    return user && user.name ? user.name : '';
  }

  fetchOrganisationMembers() {

    this.organisationService.fetchOrganisationMembers().subscribe(members => {
      this.userDataArray = members.users;
      this.filteredArray = members.users;
    })
  }

  saveWorkFlow() {

    this.regForm.markAsDirty();
    if (this.duplicateUserError) {
      return
    }
    if (this.regForm.valid) {

      let approver_count = 0;
      this.regForm.value.participents.map((obj) => {

        obj.user = obj?.user?._id;
        if (obj.type === "approver") {
          approver_count++;
        }
      })

      this.regForm.value['approver_count'] = approver_count;
      this.workFlowService.createWorkFlow(this.regForm.value).subscribe(message => {
        if (message['message'] === 'success') {
          this._snackBar.openSnackBar('Work Flow Created Successfully', 'success');
          this.dialogRef.close(true);
        } else if (message['message'] === 'exist') {
          this._snackBar.openSnackBar('Work Flow Name Already Exist', 'danger');
        }
        else {
          this._snackBar.openSnackBar('An error occurred while creating the workflow', 'danger');
        }
        console.log(message);

      })

    }

  }

  selectType(value, index) {

    const column = this.regForm.get('participents') as FormArray;
    if (value?.toLowerCase().trim() === 'approver') {
      column.controls[index]['controls'].level.setValidators([Validators.required])
    } else {
      column.controls[index]['controls'].level.clearValidators()
    }
    // this.regForm.get('managed_by').setValidators([Validators.required]) : this.regForm.get('managed_by').clearValidators()
  }

  createForm(): void {

    this.regForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      description: ['', [Validators.required]],
      participents: this.formBuilder.array([this.createItem()]),

    });
  }
  createFormForEdit(): void {

    this.regForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      description: ['', [Validators.required]],
      participents: this.formBuilder.array([]),

    });
  }

  BindData(data): void {

    this.workFlowId = data?._id
    this.createFormForEdit();
    this.fetchOrganisationMembers();

    const dataId = { flowId: data._id }

    this.workFlowService.fetchWorkFlowById(dataId).subscribe(result => {

      console.log(result, 'result');
      this.workFlowData = result[0];
      this.regForm.patchValue(result[0]);

      let arr = result[0]?.participents;
      let control = this.regForm.get('participents') as FormArray;
      while (control.length) {
        control.removeAt(0);
      }
      this.items = arr;
      arr.forEach((x, i) => {

        control.push(this.formBuilder.group({
          _id: x?._id,
          user: x?.user[0],
          type: x?.type,
          level: x?.level,

        }));

      });
    })

  }

  updateWorkFlow() {

    if (this.regForm.valid) {

      let approver_count = 0;
      this.regForm.value.participents.map((obj) => {

        obj.user = obj?.user?._id;
        if (obj.type === "approver") {
          approver_count++;
        }
      })

      this.regForm.value['approver_count'] = approver_count;

      this.regForm.value._id = this.workFlowId;

      this.workFlowService.updateWorkFlow(this.regForm.value).subscribe(result => {

        console.log(result);
        if (result['message'] == 'success') {
          this._snackBar.openSnackBar('Work Flow Created Successfully', 'success');
          this.dialogRef.close(true);
        } else if (result['message'] === 'exist') {
          this._snackBar.openSnackBar('Work Flow Name Already Exist', 'danger');
        }
        else {
          this._snackBar.openSnackBar('An error occurred while creating the workflow', 'danger');
        }


      })
    }
    // 
  }

  // Window resize for login divisions
  onResize(event: { target: { innerWidth: number; }; }): void {

    this.breakpoint = event.target.innerWidth <= resizeWidth ? columnNumber1 : columnNumber2;

  }

  createItem(): FormGroup {
    return this.formBuilder.group({
      user: ['', [Validators.required]],
      type: ['', [Validators.required]],
      level: [''],
    });
  }

  addNewColomn() {
    const column = this.regForm.get('participents') as FormArray;
    column.push(this.createItem());
  }
  removeColomn(index) {
    const column = this.regForm.get('participents') as FormArray;
    column.removeAt(index);
  }

}
