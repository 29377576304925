import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { CommunicationService } from '@atmeservices/communication.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-overview-dialog',
  templateUrl: './overview-dialog.component.html',
  styleUrls: ['./overview-dialog.component.scss']
})
export class OverviewDialogComponent implements OnInit {

  datarrayToShow = [];
  displayedColumns: string[] = ['name', 'noJD'];
  dataSource = new MatTableDataSource<any>();;
  constructor(
    public dialogRef: MatDialogRef<OverviewDialogComponent>,
    private translate: TranslateService,
    private communicationService: CommunicationService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data) {
      this.dataSource = data;
    }
  }

  ngOnInit(): void {
    this.communicationService.getLanguage().subscribe((value) => {
      this.onLanguageChange(value)
    })

  }
  onLanguageChange(lang: string) {
    this.langC = lang;
    this.translate.use(lang);
  }

  langC: string = "";
  label1: string = "overview-dialoge.label1";
  label2: string = "overview-dialoge.label2";
  noData: string = "noData-table-error";

}
