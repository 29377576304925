/* eslint-disable dot-notation */
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { BASE_URI, USER_API, ORGANISATION_API } from '@atme/serverAPI';
import { UserS } from '@atme/entity/User';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class OverviewService {

  constructor(
    private readonly http: HttpClient
  ) { }


  fetchIndivitualUsers(): Observable<any> {

    return this.http.get<UserS>(`${BASE_URI}${USER_API}/overview`).pipe(
      catchError((err) => throwError(err))
    );

  }
  fetchOrganisations(): Observable<any> {

    return this.http.get<UserS>(`${BASE_URI}${ORGANISATION_API}/overview`).pipe(
      catchError((err) => throwError(err))
    );

  }
  fetchOrganisationMembers(id): Observable<any> {

    return this.http.get<UserS>(`${BASE_URI}${USER_API}/organisation-members`, { params: id }).pipe(
      catchError((err) => throwError(err))
    );

  }


}
