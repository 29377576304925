<div [class.overlay]="loading" [class.spinner]="loading" (click)="$event.stopPropagation();"></div>
<div class="header-container">
  <h2 class="header">
    {{title | translate}}
  </h2>
  <!-- <div class="button">
      <button *ngIf="createPermission" routerLink="add" mat-button matTooltip="Add User"><mat-icon class="button-icon">add</mat-icon> Add User</button>
    </div> -->
</div>
<div class="main-wrap">

  <div class="sub-header-div">
    <form class="search-form">
      <mat-form-field class="search-full-width" appearance="none">
        <mat-icon matPrefix>search</mat-icon>
        <input type="text" matInput placeholder="{{search | translate}}" (keyup)="applyFilter($event)" #input>
      </mat-form-field>
    </form>
  </div>

  <mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

    <!-- First name Column -->
    <ng-container matColumnDef="full_name">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{label1 | translate}} </mat-header-cell>
      <mat-cell class="inputFieldOverflow" *matCellDef="let element"> {{element.first_name}} {{element.middle_name}}
        {{element.last_name}} </mat-cell>
    </ng-container>

    <!-- Mobile Column -->
    <ng-container matColumnDef="start_time">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{label2 | translate}} </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.start_time | date:'medium' }} </mat-cell>
    </ng-container>

    <!-- Email Column -->
    <ng-container matColumnDef="end_Time">
      <mat-header-cell *matHeaderCellDef mat-sort-header> {{label3 | translate}} </mat-header-cell>
      <mat-cell class="inputFieldOverflow" *matCellDef="let element">{{ element.end_time | date:'medium' }} </mat-cell>
    </ng-container>
    <!-- {{element.end_time}} -->
    <!-- Organization Column -->
    <ng-container matColumnDef="agent">
      <mat-header-cell *matHeaderCellDef>{{label4 | translate}} </mat-header-cell>
      <mat-cell class="inputFieldOverflow" *matCellDef="let element"> {{element.client_agent}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="login_agent">
      <mat-header-cell *matHeaderCellDef>{{label5 | translate}} </mat-header-cell>
      <mat-cell class="inputFieldOverflow" *matCellDef="let element"> {{element.login_agent}}</mat-cell>
    </ng-container>

    <!-- Role Column -->
    <ng-container matColumnDef="ip_address">
      <mat-header-cell *matHeaderCellDef> {{label6 | translate}} </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.source_ip}}</mat-cell>
    </ng-container>

    <!-- Location Column -->
    <ng-container matColumnDef="location">
      <mat-header-cell *matHeaderCellDef> {{label7 | translate}} </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.location?element.location:Null}}</mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    <!-- <mat-row *matRowDef="let row; columns: displayedColumns;"
          (click)="selection.toggle(row)">
        </mat-row> -->

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">{{tableNullData | translate}} "{{input.value}}"</td>
    </tr>
  </mat-table>

</div>
<!-- <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator> -->
<mat-paginator [length]="100" [pageSize]="5" [pageSizeOptions]="[5, 10, 25, 100]">
</mat-paginator>