/* eslint-disable dot-notation */
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { BASE_URI, SKILL_API } from '@atme/serverAPI';
import { SkillS } from '@atme/entity/skills';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SkillService {

  constructor(
    private readonly http: HttpClient
  ) { }

  createSkill(skill: SkillS): Observable<any> {

    return this.http.post<SkillS>(`${BASE_URI}${SKILL_API}`, skill).pipe(
      catchError((err) => throwError(err))
    );

  }

  updateSkill(skill: SkillS, skillId: string): Observable<any> {

    return this.http.put<SkillS>(`${BASE_URI}${SKILL_API}`, { params: skill,
      skillId }).pipe(
      catchError((err) => throwError(err))
    );

  }

  deleteSkillById(skillId: any): Observable<unknown> {

    return this.http.delete(`${BASE_URI}${SKILL_API}`, { params: skillId }).pipe(
      catchError((err) => throwError(err))
    );

  }

  fetchSkillList(): Observable<any> {

    return this.http.get(`${BASE_URI}${SKILL_API}`).pipe(
      catchError((err) => throwError(err))
    );

  }

  fetchSkillById(skillData: any): Observable<any> {

    return this.http.get(`${BASE_URI}${SKILL_API}/edit`, { params: skillData }).pipe(
      catchError((err) => throwError(err))
    );

  }

}
