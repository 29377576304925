/* eslint-disable dot-notation */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '@atmeservices/user.service';
import { Location } from '@angular/common';
import { SnackbarService } from 'src/app/helpers/snackbar.service';
import { CommunicationService } from '@atmeservices/communication.service';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {

  loading = false;

  regForm: FormGroup;

  isEmailExist: boolean

  successUpdateMessage = 'Profile updated successfully...';

  errorMessage = 'Error!';

  userId: string;

  editProfile = false;

  viewProfile = true;

  viewdata: any;

  selectedFiles: FileList;

  selectedMedia = null;

  picurl: string | null;

  constructor(
    private readonly userService: UserService,
    private route: ActivatedRoute,
    private communicationService: CommunicationService,
    private readonly router: Router,
    private formBuilder: FormBuilder,
    private _location: Location,
    private _snackBar: SnackbarService
  ) { }

  ngOnInit(): void {

    this.createForm();
    this.setprofile();

  }

  langC: string = "";
  editTitle: string = "profile.edit-profile-title";
  viewTitle: string = "profile.view-profile-title";
  uploadBtn: string = "profile.edit-profile-uploadBtn";

  text1: string = "profile.profile-tex1";
  text1Placeholder: string = "profile.profile-tex1-placeholder";
  text1Error: string = "profile.profile-tex1-placeholder";

  text2: string = "profile.profile-tex2";
  text2Placeholder: string = "profile.profile-tex2-placeholder";
  text2Error: string = "profile.profile-tex2-placeholder";

  text3: string = "profile.profile-tex3";
  text3Placeholder: string = "profile.profile-tex3-placeholder";
  text3Error: string = "profile.profile-tex3-placeholder";

  text4: string = "profile.profile-tex4";
  text4Placeholder: string = "profile.profile-tex4-placeholder";
  text4Error1: string = "profile.profile-tex4-error1";
  text4Error2: string = "profile-tex4-error2";

  text5: string = "profile.profile-tex5";
  text5Placeholder: string = "profile.profile-tex5-placeholder";
  text5Error1: string = "profile.profile-tex5-error1";
  text5Error2: string = "profile.profile-tex5-error2";
  text5Error3: string = "profile.profile-tex5-error3";

  saveBtnTitle: string = "saveBtnTitle";
  updateBtnTitle: string = "updateBtnTitle";
  cancelBtnTitle: string = "cancelBtnTitle";

  
  createForm(): void {

    this.regForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      photoUrl: [''],
      companyName: ['', [Validators.required]],
      jobTitle: ['', [Validators.required]],
      mobile: ['', [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]],
      email: ['', [Validators.required, Validators.email]],
    });

  }


  setprofile(): void {

    this.userService.fetchLoginuser().subscribe((message) => {

      this.viewdata = message;
      this.picurl = message?.photoUrl;
      message['_id'] ? this.regForm.patchValue(message) : this.userId = null;

    });

  }

  selectFile = (event: Event): void => {

    const fileTag = <HTMLInputElement>event.target;
    this.selectedFiles = fileTag.files;
    this.onFileSelect(this.selectedFiles[0]);

  }

  onFileSelect(file: File): void {

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {

      this.selectedMedia = reader.result.toString();
      this.picurl = this.selectedMedia;

    };

  }


  submit(): void {

    if (this.regForm.valid) {

      this.regForm.value['photoUrl'] = this.picurl;
      this.loading = true;
      this.userService.updateUserById(this.regForm.value).subscribe((message) => {

        this.loading = false;
        if (message['message'] === 'success') {

          this._snackBar.openSnackBar(this.successUpdateMessage, 'success');
          this.communicationService.setValueForProfile(true)
          // This.router.navigateByUrl('/dashboard');
          this._location.back();

        } else if (message['message'] === 'exist') {

          this.isEmailExist = true;
          this._snackBar.openSnackBar(this.errorMessage, 'danger');

        }

      });
      this.loading = false;

    } else {

    }

  }

  formCancel(): void {

    this.regForm.reset();
    this._location.back();

  }

  updateProfile(): void {

    this.editProfile = true;
    this.viewProfile = false;

  }

  updateProfileCancel(): void {

    this.editProfile = false;
    this.viewProfile = true;

  }


}
